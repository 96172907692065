import { NavigationContainer } from "@react-navigation/native";
import { Asset } from "expo-asset";
import * as Font from "expo-font";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { ActivityIndicator, View, ImageBackground } from "react-native";
import { Provider as PaperProvider } from "react-native-paper";

import StoreContext from "../store/Context";
import themePaper from "../Theme/ThemePaper";
import AppStack from "./AppStack";
import AuthStack from "./AuthStack";
import * as Notifications from 'expo-notifications';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
});

const CUSTOM_FONTS = {
  Roboto: require("native-base/Fonts/Roboto.ttf"),
  RobotoMedium: require("native-base/Fonts/Roboto_medium.ttf"),
  RobotoRegular: require("native-base/Fonts/Roboto.ttf"),
  RobotoBold: require("../assets/fonts/Roboto-Bold.ttf"),

  OpenSansRegular: require("../assets/fonts/OpenSans-Regular.ttf"),
  OpenSansSemiBold: require("../assets/fonts/OpenSans-Semibold.ttf"),
  MontserratLight: require("../assets/fonts/Montserrat-Light.otf"),
};

const BG_PATH = require("../assets/newDesign/background-portrait-qboy.png");
const IMAGES = [require("../assets/oiqia-logo.png")];

// Main initialisation of the application load Fonts, Pics , Async storage available before laucn App nivagation
const Navigation = () => {
  const [IsLoadingFont, setIsLoadingFont] = useState(true);
  const [IsLoadingPics, setIsLoadingPics] = useState(true);
  const [IsLoadingAsync, setIsLoadingAsync] = useState(true);

  const context = useContext(StoreContext);
  const authContext = context?.auth;

  const { isLoggedIn } = authContext.getUserStore();

  const loadImages = () => {
    IMAGES.map(async image => await Asset.loadAsync(image));
    setIsLoadingPics(false);
  };

  const loadUser = async () => {
    try {
      await authContext.getUserFromStorage();
    } catch (error) {
      setIsLoadingAsync(false);
      authContext.logout();
    } finally {
      setIsLoadingAsync(false);
    }
  };

  const loadFonts = async () => {
    try {
      await Font.loadAsync(CUSTOM_FONTS);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingFont(false);
    }
  };

  useEffect(() => {
    loadFonts();
    loadUser();
    loadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (IsLoadingAsync || IsLoadingFont || IsLoadingPics) {
    return (
      <ImageBackground source={BG_PATH} style={{
        flex: 1,
        justifyContent: "center",
      }}>
        <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
          <ActivityIndicator color="#D71D9A" />
        </View>
      </ImageBackground>
    );

  } else {

    return (
      <PaperProvider theme={themePaper}>
        <NavigationContainer>
          {isLoggedIn ? <AppStack /> : <AuthStack />}
        </NavigationContainer>
      </PaperProvider>
    );

  }

};

export default observer(Navigation);
