export  const themePaper = {
    "animation": {
      "scale": 1,
    },
    "colors": {
      "accent": "#03dac4",
      "backdrop": "rgba(0, 0, 0, 0.5)",
      "background": "#F7F7F7",
      "disabled": "rgba(0, 0, 0, 0.26)",
      "error": "#B00020",
      "notification": "#f50057",
      "onBackground": "#000000",
      "onSurface": "#000000",
      "placeholder": "rgba(0, 0, 0, 0.54)",
      "primary": "#D71D9A",
      "surface": "#ffffff",
      "text": "#000000",
    },
    "dark": false,
    "fonts": {
      "light": {
        "fontFamily": "Roboto",
/*         "fontWeight": "normal", */
      },
      "medium": {
        "fontFamily": "RobotoMedium",
/*         "fontWeight": "normal", */
      },
      "regular": {
        "fontFamily": "RobotoRegular",
/*         "fontWeight": "normal", */
      },
      "thin": {
        "fontFamily": "Roboto",
/*         "fontWeight": "normal", */
      },
    },
    "roundness": 10,
  };
  
  export default themePaper;