import { createStackNavigator } from "@react-navigation/stack";
import React, { useContext, useEffect, useState } from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { StatusBar } from "expo-status-bar";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { toJS } from "mobx";
import { Linking } from "react-native";

import { useTranslation } from "react-i18next";
import ChatIncident from "../../../components/ChatIncident/ChatIncident";
import BackButton from "../../../components/Button/backButton";
const Stack = createStackNavigator();

export interface CheckInFormStackProps {}

const ChatIncidentStack: React.FC<CheckInFormStackProps> = ({navigation}: any) => {
  const { t, i18n } = useTranslation();
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="ChatIncidentScreen"
        component={ChatIncident}
        options={{
          title: t("QBOY"),
          headerLeft: ()=>(
            <BackButton onPress={navigation.goBack} />
          ),
          headerStyle: {
            backgroundColor: "#D71D9A",
          },
          headerTintColor: "#fff",
          headerTitleStyle: {
            fontWeight: "bold",
          },
        }}
      />
    </Stack.Navigator>
  );
};

export default ChatIncidentStack;
