import React from "react";
import { View, StyleSheet, TextInput, Text } from "react-native";

export interface InputProps {
  placeHolder: string;
  onChangeText?: (txt: string) => void;
  style?: Record<string, unknown>;
  textStyle?: Record<string, unknown>;
  type: "INPUT" | "DISPLAY";
}

const styles = StyleSheet.create({
  wrapper: {
    borderColor: "#ccc",
    borderWidth: 1,
    width: "100%",
    height: 50,
    borderRadius: 10,
    justifyContent: "center",
  },
  text: {
    height: "100%",
    textAlign: "left",
    paddingLeft: 10,
    fontSize: 17,
  },
});

const Input: any = ({
  placeHolder,
  onChangeText,
  style,
  type,
  textStyle,
  keyboardType
}:any) => {
  return (
    <View style={[styles.wrapper, style]}>
      {type === "INPUT" ? (
        <TextInput
          accessibilityLabel={placeHolder}
          style={styles.text}
          placeholder={placeHolder}
          autoCapitalize="none"
          onChangeText={onChangeText}
          //keyboardType={keyboardType?keyboardType:"default"}
        />
      ) : (
        <Text style={[styles.text, textStyle]}>{placeHolder}</Text>
      )}
    </View>
  );
};

export default Input;
