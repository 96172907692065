import React, { useContext, useEffect } from "react";
import { createStackNavigator } from "@react-navigation/stack";

import Tabs from "./Tabs";
// import Chat from "../screens/Chat";

import { AppStackParamList } from "./ParamList/AppStackParamList";
import StoreContext from "../store/Context";
import * as Notifications from "expo-notifications"

const AppStack = createStackNavigator<AppStackParamList>();


const App: React.FC = () => {
  const context = useContext(StoreContext);
  const authContext = context?.auth;
  const { booking, bookingIdOiqia = "" } = authContext.getBookingStore()

  useEffect(() => {
    if (booking?.responsability === "Oiqia") {
      const subscription = Notifications.addNotificationReceivedListener(notification => "");
    }
  }, [])

  return (
    <AppStack.Navigator headerMode="none">
      <AppStack.Screen name="Tabs" component={Tabs} />
      {/* <AppStack.Screen name="Chat" component={Chat} /> */}
    </AppStack.Navigator>
  );
};

export default App;
