import React from "react";
import { ActivityIndicator, Modal, Platform, View, StyleSheet } from "react-native";

export const OiqiaLoadingModal =  ({ loading = false, opacity = 0.4 }) => {
  return (
    <Modal
      transparent
      animationType={"fade"}
      visible={loading}
      onRequestClose={() => null}
    >
      <View
        style={[
          modalStyles.modalBackground,
          { backgroundColor: `rgba(0,0,0,${opacity})` },
        ]}
      >
        <View style={modalStyles.activityIndicatorWrapper}>
          {Platform.OS != "web" &&
            <ActivityIndicator
              animating={loading}
              color="#D71D9A"
              size="small"
            />
          }
        </View>
      </View>
    </Modal>
  );
};

export const modalStyles = StyleSheet.create({
  modalBackground: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  activityIndicatorWrapper: {
    backgroundColor: "white",
    height: 100,
    width: 100,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    position: "absolute",
    paddingTop: 50,
  },
});


export default OiqiaLoadingModal;