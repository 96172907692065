import React from "react";
import { Modal, View, Text, StyleSheet } from "react-native";

import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';

import { modalStyles } from "./OiqiaLoadingModal";

import { useTranslation } from "react-i18next";
import { Button } from "react-native-paper";
import Colors from "../../Theme/Colors";

export interface OiqiaErrorModalProps {
    qlError: {
        networkError?: any;
        message: string;
        visible: boolean;
    }
    onClose: () => void;
}

export const OiqiaErrorModal = ({ qlError, onClose }: OiqiaErrorModalProps) => {

    const {
        networkError = false,
        message,
        visible
    } = qlError;

    const { t } = useTranslation();
    const [help, setHelp] = React.useState(false);

    return (
        <Modal
            transparent
            animationType={"fade"}
            visible={visible}
            onRequestClose={() => null}
        >
            <View
                style={[
                    modalStyles.modalBackground,
                    { backgroundColor: `rgba(0,0,0,0.4)` },
                ]}
            >

                <View style={styles.networkErrorWrapper}>
                    {networkError ?
                        // if network type = error
                        <>
                            <MaterialCommunityIcons name="access-point-network-off" size={40} color="#D71D9A" />
                            <Text style={[styles.title, styles.centerText]}>{t("ERROR_NETWORK_TITLE")}</Text>
                            <Text style={styles.centerText}>{message}</Text>
                            <Text style={styles.centerText}>{t("ERROR_NETWORK")}</Text>
                        </>
                        :
                        // if network type != error
                        <>
                            <MaterialIcons name="error-outline" size={40} color="#D71D9A" />
                            <Text style={[styles.title, styles.centerText]}>{t("UNKOWN_ERROR_TITLE")}</Text>
                            <Text style={styles.centerText}>{message}</Text>
                        </>
                    }
                    <View>
                        {help &&
                            // if network type = error
                            <>
                                <Text style={[styles.centerText, styles.helperText]}>{t("ERROR_NETWORK_HELPER")}</Text>
                            </>
                        }
                    </View>
                    <View style={styles.justifiedButton}>
                        <Button
                            mode="contained"
                            dark={true}
                            color={Colors.oiqia}
                            children={t("CLOSE")}
                            onPress={() => onClose()}
                            style={{ marginTop: 10 }}
                        />
                        {(networkError && !help) &&
                            <Button
                                mode="contained"
                                dark={true}
                                color={Colors.oiqia}
                                children={t("HELP")}
                                onPress={() => setHelp(true)}
                                style={{ marginTop: 10 }}
                            />
                        }
                    </View>
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    networkErrorWrapper: {
        backgroundColor: "white",
        padding: 20,
        width: 300,
        borderRadius: 10,
        alignItems: "center",
        justifyContent: "center",
    },
    errorWrapper: {
        backgroundColor: "white",
        height: 150,
        width: 300,
        borderRadius: 10,
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        fontWeight: "bold",
        fontSize: 16,
        marginBottom: 10
    },
    centerText: {
        textAlign: "center"
    },
    justifiedButton: {
        display: "flex",
        flexDirection: "row",
        gap: 10,
        justifyContent: "center"
    },
    helperText: {
        marginTop: 10,
        fontStyle: "italic",
        color: "grey",
        marginBottom: 5
    }
})

export default OiqiaErrorModal;