import { subscribe } from "graphql";
import { action, makeObservable, observable } from "mobx";

import DDP from "../../config/ddp";


export default class Notifications {
    chatSub: any;
    chatData = [];

    constructor(chatData: any) {
        makeObservable(this, {
            chatData: observable,
            setChatSub: action,
            resetChat: action
        })
        this.chatData = chatData
    }


    resetChat = async () => {

        console.log("reset chat store");

        if (this.chatSub) {
            console.log("SUB Stop");
            await this.chatSub.remove()
        };
        this.chatSub = null;
        this.chatData = [];
    }

    InitChatSub = async (oiqiaId: string) => {
        console.log("Init notifications", oiqiaId);
        try {

            if (!this.chatSub) {
                this.chatSub = DDP.subscribe(
                    "Notifications.SubChat",
                    oiqiaId
                );

                //console.log("mideal Init notifications", this.chatSub);

                await this.chatSub.ready();

                //  console.log("end Init notifications", oiqiaId);
            }
        } catch (err) {
            console.log("Error Init Notifications.SubChat ", err);
        }
    };


    setChatSub = async (oiqiaId: string) => {
        if (!this.chatSub) await this.InitChatSub(oiqiaId);

        let chatMsgCollection = DDP.collection("chat-incident-msg").reactive();
        const dataSub = chatMsgCollection.data();
        this.chatData = chatMsgCollection.data();
        //console.log("data subscribe",this.chatData)
        chatMsgCollection.onChange((newData: any) => {
            // console.log("dect subscribe naw data", newData);
            this.chatData = newData;
        });
    };
}
