import React, { useState, useRef, useEffect } from "react";
import { SafeAreaView, Text, TouchableOpacity, StyleSheet, View, Dimensions, ImageBackground, Alert } from "react-native";
import * as ImageManipulator from 'expo-image-manipulator';

import { Camera, CameraType } from "expo-camera";
import { Feather, Ionicons, AntDesign } from '@expo/vector-icons';
import { useTranslation } from "react-i18next";

const WINDOW_HEIGHT = Dimensions.get("window").height;
const WINDOW_WIDTH = Dimensions.get("window").width;
const closeButtonSize = Math.floor(WINDOW_HEIGHT * 0.032);
const captureSize = Math.floor(WINDOW_HEIGHT * 0.09);

interface previewObject {
    isPreview: boolean;
    capturedImage: {
        uri: string;
        width: number;
        height: number;
        base64: string;
    } | null;
    isPortrait?: boolean | null;
};

interface CameraScreenProps {
    closeCamera: () => void;
    pictures?: Array<any>;
    setPictures: (_: PictureObject | undefined) => Promise<void>;
    isChat?: boolean;
    sendPicture: (_: PictureObject | undefined) => Promise<void>;
    isPortrait: boolean;
}

interface PictureObject {
    base64?: string;
    uri?: string;
}

interface CameraPermissionUserResponse {
    canAskAgain: boolean;
    expires: string;
    granted: boolean;
    status: string;
}

export default function CameraScreen({ closeCamera, pictures, setPictures, isChat = false, sendPicture, isPortrait }: CameraScreenProps) {

    const cameraRef = useRef();

    const [hasPermission, setHasPermission] = useState(null);

    const { t } = useTranslation();

    const [preview, setPreview] = useState<previewObject>({
        isPreview: false,
        capturedImage: null,
        isPortrait: null,
    });

    const [flashMode, setFlashMode] = useState<string>(Camera.Constants.FlashMode.off)

    const type = CameraType.back;

    useEffect(() => {
        async function askPerm() {
            const res = await Camera.requestCameraPermissionsAsync();
            const { status } = res;
            setHasPermission(status === 'granted');
        }
        if (hasPermission === false) {
            Alert.alert(t('MSG_PERMISSION_CAMERA_TITLE'), t('MSG_PERMISSION_CAMERA'), [
                { text: 'OK', onPress: () => closeCamera() },
            ]);
        } else {
            askPerm();
        }
    }), [hasPermission];


    const addPicture = async (picture: PictureObject) => {
        if (isChat) {
            const manipResult = await ImageManipulator.manipulateAsync(
                picture.uri,
                [
                    {
                        resize: {
                            height: 1500,
                        }
                    }
                ],
                {
                    base64: true,
                    format: ImageManipulator.SaveFormat.JPEG,
                    compress: 0.5
                }
            ) as previewObject["capturedImage"]
            await sendPicture(manipResult)
        } else {
            const temp = [...pictures];
            temp.push({ uri: picture.uri, base64: picture.base64 });
            await setPictures(temp)
        }
    }

    const takePicture = async () => {

        if (cameraRef.current) {

            const options = { quality: 0.2, base64: true, skipProcessing: false };

            const data = await cameraRef.current.takePictureAsync(options);

            const portrait = (data.width < data.height);

            const manipResult = await ImageManipulator.manipulateAsync(
                data.uri,
                [
                    {
                        resize: {
                            height: 1500,
                        }
                    }
                ],
                {
                    base64: true,
                    format: ImageManipulator.SaveFormat.JPEG,
                    compress: 0.5
                }
            ) as previewObject["capturedImage"];

            setPreview({
                isPreview: true,
                capturedImage: manipResult,
                isPortrait: portrait
            });

        }

    };

    const cancelPreview = async () => {
        setPreview({
            isPreview: false,
            capturedImage: null,
            isPortrait: null,
        });
    };

    const switchFlashMode = () => {
        if (flashMode === Camera.Constants.FlashMode.off) {
            setFlashMode(Camera.Constants.FlashMode.on);
        } else {
            setFlashMode(Camera.Constants.FlashMode.off);
        }
    }

    const testAccepted = async () => {
        addPicture({
            uri: preview.capturedImage?.uri,
            base64: preview.capturedImage?.base64
        });
        closeCamera();
    }

    const styles = StyleSheet.create({
        container: {
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: "black"
        },
        cameraBlock: {
            flex: 1,
            flexDirection: isPortrait ? "column" : "row"
        },
        flex1Camera: {
            flex: 1,
            width: isPortrait ? WINDOW_WIDTH * 0.8 : WINDOW_HEIGHT * 0.8,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
        },
        flex1Flash: {
            flex: 1,
            width: isPortrait ? WINDOW_WIDTH * 0.8 : WINDOW_HEIGHT * 0.8,
            justifyContent: "space-between",
            marginBottom: 10,
            alignItems: isPortrait ? "flex-end" : "center",
            flexDirection: "row",
            zIndex: 10,
        },
        flex5: {
            flex: 5,
            height: "100%",
            width: isPortrait ? WINDOW_WIDTH * 0.8 : WINDOW_HEIGHT * 0.8
        },
        flex6: {
            flex: 6,
            height: "100%",
            width: isPortrait ? WINDOW_WIDTH * 0.8 : WINDOW_HEIGHT * 0.8
        },
        cameraSizing: {
            top: "15%",
            zIndex: 50,
            bottom: "15%"
        },
        helpers: {
            color: 'white'
        },
        previewBlock: {
            backgroundColor: 'transparent',
            flex: 1,
            width: '100%',
            height: '100%'
        },
        closeButton: {
            height: Math.floor(captureSize * 0.75),
            width: Math.floor(captureSize * 0.75),
            borderRadius: Math.floor(closeButtonSize / 2),
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "red",
            color: "white",
            opacity: 1,
            zIndex: 10,
        },
        control: {
            position: "absolute",
            bottom: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            marginBottom: "2%"
        },
        flashPannel: {
            position: "absolute",
            top: 20,
            right: 20,
        },
        capture: {
            backgroundColor: "#D71D9A",
            height: Math.floor(captureSize * 0.75),
            width: Math.floor(captureSize * 0.75),
            borderRadius: Math.floor(captureSize * 0.75),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        flash: {
            backgroundColor: "grey",
            height: Math.floor(captureSize * 0.5),
            width: Math.floor(captureSize * 0.5),
            borderRadius: Math.floor(captureSize * 0.5),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        closeCam: {
            backgroundColor: "red",
            height: Math.floor(captureSize * 0.5),
            width: Math.floor(captureSize * 0.5),
            borderRadius: Math.floor(captureSize * 0.5),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        accept: {
            backgroundColor: "green",
            height: Math.floor(captureSize * 0.75),
            width: Math.floor(captureSize * 0.75),
            borderRadius: Math.floor(closeButtonSize / 2),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        text: {
            color: "#fff",
        },
        flex2: {
            flex: 1,
            flexDirection: isPortrait ? "row" : "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            zIndex: 10,
        }
    });


    return (
        <SafeAreaView style={styles.container}>
            <View
                style={styles.cameraBlock}
                collapsable={false}
            >

                {preview.isPreview && preview.capturedImage ?
                    <>
                        <ImageBackground
                            source={{ uri: preview.capturedImage.uri }}
                            style={styles.flex6}
                            imageStyle={{
                                resizeMode: "contain",
                            }}
                        />
                        <View style={styles.flex2}>
                            <TouchableOpacity
                                onPress={() => cancelPreview()}
                            >
                                <View style={styles.closeButton}>
                                    <View>
                                        <Ionicons name="close-sharp" size={24} color="white" />
                                    </View>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => testAccepted()}
                            >
                                <View style={styles.accept}>
                                    <Feather name="check" size={24} color="white" />
                                </View>
                            </TouchableOpacity>
                        </View>
                    </>

                    :
                    <>
                        <View style={styles.flex1Flash}>
                            <TouchableOpacity
                                onPress={() => switchFlashMode()}
                                style={styles.flash}
                            >
                                {flashMode === Camera.Constants.FlashMode.off ?
                                    <Ionicons name="flash-off" size={24} color="white" />
                                    :
                                    <Ionicons name="flash" size={24} color="white" />
                                }
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={closeCamera}
                                style={styles.closeCam}
                            >
                                <AntDesign name="closecircleo" size={24} color="white" />
                            </TouchableOpacity>
                        </View>

                        {hasPermission === true &&
                            <Camera
                                ratio={"4:3"}
                                ref={cameraRef}
                                style={styles.flex5}
                                type={type}
                                flashMode={flashMode}
                            />
                        }
                        <View style={styles.flex1Camera}>
                            <TouchableOpacity
                                onPress={() => takePicture()}
                                style={styles.capture}
                            >
                                <Feather name="camera" size={24} color="white" />
                            </TouchableOpacity>
                        </View>
                    </>
                }
            </View>
        </SafeAreaView >
    );

}

