import { StyleSheet } from "react-native";

import Colors from "./Colors";


export const typo = StyleSheet.create({
  h1: {
    fontSize: 22,
    color: Colors.black,
    textTransform: "uppercase",
    fontFamily: "RobotoBold",
  },
  h2: {
    fontSize: 18,
    color: Colors.black,
    textTransform: "uppercase",
    fontFamily: "RobotoBold",
  },
  h3: {
    fontSize: 16,
    color: Colors.black,
    textTransform: "uppercase",
    fontFamily: "RobotoBold",
  },
  h4: {
    fontSize: 14,
    color: Colors.black,
    textTransform: "uppercase",
    fontFamily: "RobotoBold",
  },
  h5: {
    fontSize: 14,
    color: Colors.black,
  },
  category: {
    fontSize: 18,
    color: Colors.black,
    fontFamily: "RobotoBold",
  },
  titleInfo: {
    fontSize: 26,
    color: Colors.white,
    textAlign: "center",
    marginBottom: 20,
    fontFamily: "OpenSansSemiBold"
  },
  titleCheckout: {
    fontSize: 26,
    color: Colors.black,
    textAlign: "center",
    marginBottom: 20,
    fontFamily: "OpenSansSemiBold"
  },
  body: {
    fontSize: 16,
    color: Colors.black,
    fontFamily: "RobotoRegular",
  },

  /*   STYLING ZONE */
  background: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
  },
  mainBackground: {
    flex: 1,
    backgroundColor: Colors.background,
  },
  container: {
    /*     alignSelf: "center", */

    margin: 15
  },
  resCard: {
    backgroundColor: Colors.bgCard,
    padding: 20,
    borderRadius: 20,
    margin: 10
  },
  catCard: {
    backgroundColor: Colors.bgCatCard,
    borderRadius: 20,
    paddingTop: 20,
    paddingBottom: 20,
  },
  underlineCat: {
    alignItems: "center",
    textAlign: "center",
    flexDirection: "row",
    borderColor: Colors.black,
    borderBottomWidth: 0.5,
    paddingBottom: 5,
    marginLeft: 25,
    marginRight: 25,
    marginBottom: 10
  },
  icons: {
    marginEnd: 10
  },
  oiqiaButton: {
    backgroundColor: Colors.oiqia,
    borderRadius: 10,
    justifyContent: "center",
/*     alignItems: "center",  */
    color: "white",
    height: 44, 
    marginBottom:10 
  }
});

export default typo;