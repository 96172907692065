/* eslint-disable max-len */
import { base } from "./base";

const pt: base = {
    "QBOY": "OIQIA CAT",
    "OWNER": "PRÓPRIO",
    "TITLE_POP": "Bem-vindo ao viajante da Oiqia",
    "BUTTON_PASS": "Ir para",
    "BUTTON_LEAVE": "Sair",
    "WELCOME": "Bem-vindo ao aplicativo Oiqia Traveler!\n\nPor favor, leia atentamente as seguintes instruções para garantir que você possa usar a aplicação de forma fácil e eficiente.",
    "LOGIN_WELCOME_POP": "Para usar o Oiqia Traveler, por favor insira o número de reserva enviado pela equipe da Oiqia no campo correspondente.\n\nEscolha o seu idioma de comunicação.",
    "HOME_WELCOME": "Com Oiqia Traveler, você tem acesso a todas as informações úteis sobre seu alojamento (endereço, códigos de acesso, manuais, folheto de boas-vindas, regras da casa, código wifi, etc.).",
    "ACCESS_WELCOME": "Com o Oiqia Traveler, você pode clicar no botão \"unlock/lock\" para abrir/fechar o edifício e/ou a porta plana.\n\nVocê também pode digitar o código de entrada no teclado numérico localizado perto da porta do apartamento.",
    "FORM_WELCOME": " Ao chegar à acomodação, você deve fazer o check-in.\n\nSe tudo estiver bem, confirme que o alojamento está em boas condições.\nSe houver algum problema, por favor, diga-nos. Este passo é essencial em caso de desacordo.\n\nQuando saíres, não te esqueças de ir embora!",
    "CHAT_WELCOME": "Com a Oiqia Traveler, você pode se comunicar diretamente conosco via chat.\n\nVocê também encontrará os detalhes de contato do nosso gerente de clientes Oiqia em caso de emergência.",
    "SETTING_WELCOME": "Oiqia Traveler comunica em várias línguas: escolha a sua língua preferida para utilizar a sua aplicação.\n\nVocê também pode sair da aplicação.\n\nOu altere a sua reserva se tiver várias reservas em curso com a Oiqia.",
    "BOOKING_ID": "Identificador",
    "BOOKING_EMAIL": "Email",
    "LOGIN": "Iniciar Sessão",
    "LOGIN_WELCOME": "Por favor, digite o código enviado para a sua reserva:",
    "POPUP_CHECKIN_TITLE": "Bem-vindo à sua localização!",
    "POPUP_CHECKIN_DESCRIPTION": "Para desfrutar do seu aluguer, por favor preencha o inventário dos móveis na guia \"formulários\".",
    "POPUP_CHECKIN_BUTTON": "Fazendo o checkin",
    "HOME": "Início",
    "EVENTS": "Eventos na porta ao lado",
    "RESTAURANTS": "Restaurantes nas proximidades",
    "ACCESS": "Acesse",
    "BOOKING": "Reservas",
    "ACCESS_CODE": "Código de acesso",
    "UNLOCK_DOOR": "Abra a porta",
    "LOCK_DOOR": "Feche a porta",
    "INFO_TITLE": "Encontre o seu\ninformação de aluguer",
    "RESERVATION_REF": "O seu código de reserva :",
    "ADDRESS": "Endereço do local",
    "ARRIVAL": "Chegada a",
    "DEPARTURE": "Partida",
    "DIRECTIONS": "Calcular rota",
    "FROM": "de",
    "TO": "para",
    "COPIED": "Código Wifi copiado",
    "TEXT_COPIED": "O código wifi foi copiado e você pode se conectar com o wifi colando o código",
    "EQUIPMENT": "Equipamento",
    "BEDROOM": "Quarto",
    "KITCHEN": "Cozinha",
    "BATHROOM": "Casa de banho",
    "SALON": "Salão",
    "OUTSIDE": "Ao ar livre",
    "COMFORT": "Conforto",
    "WIFINAME": "Nome do wifi",
    "WIFICODE": "código Wifi",
    "EXTRAINFO": "Informações adicionais",
    "FORMS": "Formulários",
    "CHECKINFORM": "Ficha de inscrição",
    "COMPLAINFORM": "Formulário de Incidente",
    "CHECKOUTFORM": "Formulário de Saída",
    "INCIDENT": "Suporte",
    "ACCESS_OPEN_KEYPAD": "Para abrir com o teclado\nDigite apenas estes 6 números",
    "ACCESS_CLOSE_KEYPAD": "Para fechar com o teclado",
    "ACCESS_CLOSE_KEY": "imprensa",
    "ACCESS_MANAGE_APP": "Ou através da aplicação",
    "PLACEHOLDER_INPUT": "Faça a sua pergunta ao apoio da Oiqia",
    "INITIAL_BOT_MESSAGE": "Olá, eu sou o Qboy. Em que posso ajudá-lo?",
    "QBOY_PERTINENCE": "Olá novamente, as respostas do Qboy são relevantes?",
    "QBOY_PERTINENCE_SUCCESS": "Perfeito!",
    "QBOY_PERTINENCE_FAIL": "Está bem, nós contactamos o serviço de apoio ao cliente e eles respondem-lhe.",
    "SETTINGS": "Parâmetros",
    "INFO_SETTING": "Ajuste as suas definições",
    "CHANGE_RESERVATION": "Modificar reserva",
    "LANGUAGE": "Idioma",
    "HOW_TO_CONTACT": "Como você gostaria de ser contatado?",
    "CHOICE_LANG": "Escolha o seu idioma",
    "EMAIL": "Email",
    "NOTIF": "Notificações push",
    "SMS": "Sms",
    "RECO": "Receba recomendações do Qboy",
    "LOGOUT": "Sair",
    "WARNING": "Atenção",
    "HELP_TITLE": "Necessidade de ajuda",
    "HELP_TEXT": "Mostre-me",
    "ERROR_NETWORK": "Por favor, verifique a sua ligação à Internet.",
    "ACCESS_CODE_NOT_AVAILABLE_BEFORE_BOOKING_ARRIVAL": "O código de acesso só pode ser emitido após a reserva ter sido feita.",
    "ERROR_BOOKING_NOT_FOUND_TITLE": "Reservas não encontradas",
    "ERROR_BOOKING_NOT_FOUND_DESCRIPTION": "Os dados de reserva fornecidos não correspondem a nenhuma reserva no nosso sistema.",
    "ERROR_CHAT_BOT_TITLE": "Erro técnico",
    "ERROR_CHAT_BOT_DESCRIPTION": "Ocorreu um problema. Por favor, tente novamente mais tarde.",
    "ERROR_INCOMPLETE_FORM_TITLE": "Forma incompleta",
    "ERROR_INCOMPLETE_FORM_DESCRIPTION": "Por favor, preencha o formulário corretamente.",
    "ERROR_ACCESS_CODE_TITLE": "Surgiu um problema",
    "ERROR_ACCESS_CODE_DESCRIPTION": "Não foi possível recuperar o código de acesso à casa.",
    "UNKOWN_ERROR_TITLE": "Surgiu um problema inesperado.",
    "UNKOWN_ERROR_DESCRIPTION": "Ocorreu um problema inesperado. Por favor, tente novamente mais tarde.",
    "CHECKIN_FORM_FIRSTNAME_LABEL": "Primeiro nome",
    "CHECKIN_FORM_LASTNAME_LABEL": "Nome",
    "CHECKIN_FORM_EMAIL_LABEL": "Email",
    "CHECKIN_FORM_RESERVATION_ID_LABEL": "Número de reserva",
    "CHECKIN_FORM_PHONE_LABEL": "Telefone",
    "CHECKIN_FORM_ARRIVAL_LABEL": "Chegada a",
    "CHECKIN_FORM_DEPARTURE_LABEL": "Partida",
    "CHECKIN_FORM_ADULTS_LABEL": "Adultos",
    "CHECKIN_FORM_CHILDREN_LABEL": "Crianças",
    "CHECKIN_FORM_CHECKBOX_TITLE_LABEL": "O alojamento corresponde às suas expectativas?",
    "CHECKIN_FORM_CHECKBOX_YES_LABEL": "Sim",
    "CHECKIN_FORM_CHECKOBOX_NO_LABEL": "Não",
    "CHECKIN_FORM_SUBMIT_BUTTON": "Envie para",
    "CHECKIN_FORM": "Ficha de inscrição",
    "CHECKIN_FORM_HEADER": "ficha de inscrição",
    "CHECKIN_TITLE": "Check-in\nficha de inscrição",
    "CHECKIN_DESC_TITLE": "Obrigado por teres feito o check-in:",
    "CHECKIN_DESC": "Verifique se as informações abaixo estão correctas e confirme o estado do alojamento.\n\nEste check-in servirá como documento de referência, por favor preencha-o cuidadosamente.",
    "CHECKIN_DESC_PB_TITLE": "Por favor, indique o problema encontrado:",
    "CHECKIN_DESC_PB": "Descreva o problema, você pode incluir uma ou mais fotos.",
    "CHECKIN_POPUP_TITLE": "Deve confirmar que está presente no alojamento para validar o check-in",
    "CHECKIN_POPUP_SUBTITLE": "PS: Qualquer dano encontrado no alojamento após o check-in será de sua responsabilidade",
    "CHECKIN_POPUP_EARLY": "Você só pode validar o check-in depois de iniciada a sua reserva",
    "CHECKOUT_FORM": "Formulário de Saída",
    "CHECKOUT_TITLE": "Check-Out\nformulário de saída",
    "CHECKOUT_SUBTITLE": "OBRIGADO POR TERES FEITO O CHECK-OUT",
    "CHECKOUT_DESC": "Está na hora de sair, assinalando as caixas abaixo.\n\nAlgumas de suas respostas são de sua responsabilidade, nós lhe agradecemos antecipadamente para fazê-lo com seriedade.\n\nFinalmente, não se esqueça de deixar as instalações como gostaria de as encontrar.",
    "CHECKOUT_FORM_VERY_UNSATISFACTORY": "Muito insatisfatório",
    "CHECKOUT_FORM_UNSATISFACTORY": "insatisfatório",
    "CHECKOUT_FORM_NEUTRE": "Nem satisfatório nem insatisfatório",
    "CHECKOUT_FORM_SATISFACTORY": "Satisfatório",
    "CHECKOUT_FORM_VERY_SATISFACTORY": "Muito satisfatório",
    "CHECKOUT_FORM_RATING_APPART": "Qual é a sua avaliação geral sobre o alojamento alugado?",
    "CHECKOUT_FORM_RATING_PROPERTY": "Como você classificaria a limpeza do alojamento alugado?",
    "CHECKOUT_FORM_RATING_OIQIA": "Qual é a sua avaliação sobre a chegada autónoma através da fechadura conectada?",
    "CHECKOUT_DESCRIPTION_TITLE": "Você tem algum comentário sobre a sua estadia?",
    "CHECKOUT_DESCRIPTION": "Descrição",
    "CHECKOUT_FORM_ASK_LEAVE": "Você está prestes a deixar o alojamento alugado.\nEstá nas mesmas condições de quando você chegou?",
    "CHECKOUT_COMENTARY_TITLE": "Por favor, forneça detalhes da sua resposta no espaço de comentários abaixo.",
    "CHECKOUT_COMENTARY": "Por favor, informe qualquer problema ou dano no apartamento. Você também é livre para nos deixar um comentário sobre a sua estadia!",
    "CHECKOUT_PICTURE": "Você pode tirar fotos do(s) problema(s) encontrado(s) abaixo.",
    "CHECKOUT_QCM_ASK": "Finalmente, contamos consigo para respeitar as instalações e para participar em tornar a comunidade de aluguer a curto prazo mais agradável e fiável.",
    "CHECKOUT_QCM_HEADER": "Ao marcar as caixas abaixo, você está afirmando :",
    "CHECKOUT_QCM_EMPTY": "Nada a fazer.",
    "CHECKOUT_ENDING": "Antes de validar o check-out, toda a equipa da Oiqia\na equipa deseja-lhe uma viagem segura para casa.\nA sua satisfação é a nossa prioridade, por favor não hesite em voltar a contactar-nos com quaisquer\ncomentários que possa ter sobre a sua estadia.",
    "CHECKOUT_THANKS": "Estamos ansiosos por recebê-lo de volta à Oiqia!",
    "CHECKOUT_PROBLEM": "Você teve algum problema durante a sua estadia?",
    "CHECKOUT_TODOLIST": "Lista de verificação",
    "CHECKOUT_POPUP_TITLE": "Você está prestes a validar o check-out quando sua data de partida estiver marcada para",
    "CHECKOUT_POPUP_TIME": "à",
    "CHECKOUT_POPUP_SUBTITLE": "PS: Você será considerado responsável por qualquer dano encontrado no alojamento após a confirmação do check out",
    "COMPLAINT_FORM_HEADER": "Formulário de reclamação",
    "COMPLAINT_FORM_PROBLEM": "Digite o problema",
    "COMPLAINT_FORM_UPLOAD_BUTTON": "Adicionar uma foto",
    "COMPLAINT_FORM_IMG_BUTTON": "Galeria de imagens",
    "COMPLAINT_FORM_CAMERA_BUTTON": "Tire uma foto",
    "COMPLAINT_FORM_CANCEL_BUTTON": "Cancelar",
    "COMPLAINT_FORM_SUBMIT_BUTTON": "Salvar",
    "EDIT_COMPLAINT_LABEL": "Descrição do problema",
    "EDIT_COMPLAINT_BUTTON": "Editar",
    "DECLARE_PROBLEM": "Relatar um problema",
    "DECLARERED_PROBLEM": "Problema relatado",
    "EDIT_COMPLAINT_IMAGE": "Imagem do(s) incidente(s)",
    "EDIT_DESCRIPTION": "Você tem algum feedback sobre a chegada autônoma?",
    "OWNER_TITLE": "Em caso de problemas\nEntre em contato com o proprietário",
    "OWNER_FIRSTNAME": "Primeiro nome",
    "OWNER_LASTNAME": "Nome",
    "OWNER_EMAIL": "Correio",
    "OWNER_PHONE": "Tel",
    "OWNER_CALL": "Ligue para",
    "OWNER_MSG": "Mensagem",
    "OWNER_MAIL": "Email",
    "MSG_PERMISSION_LIBRARY": "Desculpe, mas precisamos de permissões para aceder ao seu directório.",
    "MSG_PERMISSION_CAMERA": "Desculpe, mas precisamos de permissões para aceder à sua câmara.",
    "UPDATE_COMPLAINT_FORM_HEADER": "Atualizar formulário de reclamação",
    "UPDATE_COMPLAINT_FORM_PROBLEM": "Digite o problema",
    "UPDATE_COMPLAINT_FORM_UPLOAD_BUTTON": "Adicionar uma foto",
    "UPDATE_COMPLAINT_FORM_IMG_BUTTON": "Galeria de imagens",
    "UPDATE_COMPLAINT_FORM_CAMERA_BUTTON": "Tire uma foto",
    "UPDATE_COMPLAINT_FORM_CANCEL_BUTTON": "Cancelar",
    "UPDATE_COMPLAINT_FORM_SUBMIT_BUTTON": "Salvar",
    "APARTMENT_DOOR_DESCRIPTION": "Descrição",
    "APARTMENT_DOOR_DOOR_TYPE": "Tipo de porta",
    "APARTMENT_DOOR_DEVICE_TYPE": "Tipo de dispositivo",
    "APARTMENT_DOOR_DIGIT_CODE": "Digicode",
    "APARTMENT_DOOR_MANUAL_CODE": "Código de acesso",
    "APARTMENT_DOOR_DOOR_NAME": "Nome da porta Oiqia",
    "APARTMENT_DOOR_BUTTON_UNLOCK": "Desbloquear",
    "APARTMENT_DOOR_BUTTON_LOCK": "Fechadura",
    "APARTMENT_DOOR_TITLE": "Instruções para aceder à sua casa",
    "APPARTMENT_DOOR_STATE": "Estatudo",
    "BUTTON_POPUP_TITLE_DOOR": "O que é que quer fazer ?",
    "BUTTON_POPUP_TITLE": "Quer destravar ?",
    "BUTTON_POPUP_TITLE_LOCK": "Queres trancar ?",
    "BUTTON_POPUP_YES": "Sim",
    "BUTTON_POPUP_YESS": "Sim",
    "BUTTON_POPUP_NO": "Não",
    "CHECKIN_DONE_TITLE": "Data de check-in",
    "CHECKOUT_DONE_TITLE": "Data de check-out",
    "CHECKIN_HISTORY_BUTTON": "Fazer o check-in",
    "CHECKOUT_HISTORY_BUTTON": "Confira",
    "CHECKOUT_ADD_COMPLAINT": "Adicionar uma reclamação",
    "COMPLAINTS_HISTORY_TITLE": "Descrição do problema",
    "COMPLAINTS_HISTORY_LABEL_PROBLEM": "Descrição",
    "COMPLAINTS_HISTORY_LABEL_IMAGE": "Fotos",
    "ALERT_CHECKIN_DONE": "Inscrição concluída com sucesso",
    "ALERT_PHONE_MANDATORY": "Número de telefone inválido, por exemplo, +33 06 01 02 03 04",
    "ALERT_PROBLEM": "Introduzir um problema",
    "TOAST_SUCCESS_": "Queixa acrescentada com sucesso",
    "HEADER_RESERVATION": "Início",
    "HEADER_HOME": "Acesse",
    "HEADER_SETTINGS": "Parâmetros",
    "HEADER_CHECKINFORM": "Formulário de controlo",
    "HEADER_COMPLAINTFORM": "Descrição do problema",
    "HEADER_UPDATE_COMP_FORM": "Editando o problema",
    "HEADER_HISTORY_CHECKIN": "Histórico de Check-in",
    "HEADER_HISTORY_CHECKOUT": "Histórico de check-out",
    "BUTTON_YES": "Sim",
    "BUTTON_NO": "não",
    "MODAL_TEXT": "Queres apagar...",
    "ALERT_FIRSTNAME_MANDATORY": "O primeiro nome é obrigatório.",
    "ALERT_LASTNAME_MANDATORY": "O sobrenome é obrigatório",
    "ALERT_EMAIL_MANDATORY": "O seu e-mail não é válido",
    "ALERT_ADULT_MANDATORY": "Adultos são obrigatórios",
    "SET_PHONE_MANDATORY": "Número de telefone com código de área +33 ...",
    "ATTACHMENT_PICTURES": "Fotos",
    "ATTACHMENT_RULES": "Regras",
    "ATTACHMENT_MANUALS": "Manuais",
    "ATTACHMENT_WELCOME_NOTES": "Notas de boas-vindas",
    "ERROR_NETWORK_TITLE": "Erro de rede!",
    "HELP": "Ajuda-me",
    "VALIDATE": "Confirmar",
    "CANCEL": "Cancelar",
    "MODAL_TITLE_PICTURE": "Tirar uma fotografia",
    "MODAL_BODY_PICTURE": "Pode tirar a fotografia da sua galeria ou da sua câmara.",
    "ERROR": "Erro ...",
    "MISSING_DATA_ERROR": "Forneça uma descrição do problema e, pelo menos, uma fotografia.",
    "MSG_PERMISSION_CAMERA_TITLE": "Autorização recusada",
    "SELECT_LANGAGE": "A sua língua :",
    "CLOSE": "Fechar",
    "ERROR_NETWORK_HELPER": "Feche a aplicação, desligue o seu dispositivo da rede, volte a ligá-lo e tente novamente.",
};

export default pt;
