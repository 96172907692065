import BookingStore from "./BookingStore";
import UserStore from "./UserStore";
import LoadingStore from "./LoadingStore";
import NotificationsStore from './NotificationsStore/Notifications'
import PopUp from "./PopUp/PopUp";

export interface storeTypes {
  userStore: typeof UserStore;
  bookingStore: typeof BookingStore;
  LoadingStore: typeof LoadingStore;
  notificationsStore:  typeof NotificationsStore;
}


export default class RootStore {

  private static instance = new RootStore();

  public userStore;
  public bookingStore;
  public loadingStore;
  public notificationsStore;
  public popUp;

  constructor() {
    this.userStore = new UserStore(this);
    this.bookingStore = new BookingStore(this);
    this.loadingStore = new LoadingStore(this)
    this.notificationsStore = new NotificationsStore(this)
    this.popUp = new PopUp(this)
  }

  static getInstance() {
    return this.instance;
  }
}
