import React from "react";
import { Text, StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Ionicons } from '@expo/vector-icons'; 

interface ButtonProps {
  style?: Record<string, unknown>;
  onPress: () => void;
}

const styles = StyleSheet.create({
  wrapper: {
    height: 40,
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  text: {
    color: "white",
    fontWeight: "bold",
    fontSize: 17,
  },
});

const BackButton: React.FC<ButtonProps> = ({ style, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress} style={[styles.wrapper, style]}>
        <Ionicons name="chevron-back-outline" size={30} color="white" />
    </TouchableOpacity>
  );
};

export default BackButton;