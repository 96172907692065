import React, { useEffect, useState, useContext } from "react";
import { View, Text, SafeAreaView } from "react-native";
import { AntDesign } from '@expo/vector-icons';
import { observer } from "mobx-react-lite";
import Colors from "../../Theme/Colors";
import QboyLogo from "../../assets/newDesign/qboy_carousel.svg"
import { useTranslation } from "react-i18next";
import StoreContext from "../../store/Context";
import typo from "../../Theme/Typography";
import { TouchableOpacity } from "react-native";
import OiqiaModal from "../OiqiaModal/OiqiaModal";

enum StatusCarousel {
    home = 0,
    login = 1,
    booking = 2,
    access = 3,
    forms = 4,
    chat = 5,
    setting = 6,
}

interface SlideType {
    id: StatusCarousel,
    icons: String,
    text: String,
}

const Slides: Array<SlideType> = [
    {
        id: 0,
        icons: "Qboy",
        text: "WELCOME"
    },
    {
        id: 1,
        icons: "login",
        text: "LOGIN_WELCOME_POP"
    },
    {
        id: 2,
        icons: "home",
        text: "HOME_WELCOME"
    },
    {
        id: 3,
        icons: "unlock",
        text: "ACCESS_WELCOME"
    },
    {
        id: 4,
        icons: "form",
        text: "FORM_WELCOME"
    },
    {
        id: 5,
        icons: "wechat",
        text: "CHAT_WELCOME"
    },
    {
        id: 6,
        icons: "setting",
        text: "SETTING_WELCOME"
    },
]

const CarouselTuto = () => {

    const { t, i18n } = useTranslation();
    const [status, setStatus] = useState<StatusCarousel>(0)
    const [buttonText, setButtonText] = useState<String>("Passer")

    const context = useContext(StoreContext);
    const authContext = context?.auth;

    const onFinish = async () => {
        await authContext.setCarouselStatus(false)
    }

    const incrementStatus = () => {
        setStatus(status + 1);
    }

    const decrementStatus = () => {
        setStatus(status - 1);
    }

    useEffect(() => {
        if (status == StatusCarousel.setting) {
            setButtonText(t("BUTTON_LEAVE"))
        } else {
            setButtonText(t("BUTTON_PASS"))
        }
    }, [status]);

    const bodyModal = (
        <View style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "column", height: 340, /* marginTop: 20  */ }}>
            <View style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "column", flexWrap: "wrap" }}>
                <View>
                    {Slides[status] && Slides[status].icons == "Qboy" ?
                        <QboyLogo width={100} height={80} preserveAspectRatio="none" />
                        :
                        <AntDesign name={Slides[status].icons} size={50} color={Colors.oiqia} />
                    }
                </View>
                <View style={{ marginTop: 10 }}>
                    <Text style={typo.body}>
                        {Slides[status] && t(Slides[status].text)}
                    </Text>
                </View>
            </View>
            <View style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row" }}>
                {status > 0 &&
                    <View style={{ marginRight: 100 }}>
                        <TouchableOpacity onPress={() => decrementStatus()} >
                            <AntDesign name="arrowleft" size={30} color={Colors.oiqia} />
                        </TouchableOpacity>
                    </View>
                }
                {status < StatusCarousel.setting &&
                    <View>
                        <TouchableOpacity onPress={() => incrementStatus()} >
                            <AntDesign name="arrowright" size={30} color={Colors.oiqia} />
                        </TouchableOpacity>
                    </View>
                }
            </View>
        </View>);



    return (
        <SafeAreaView style={typo.mainBackground}>
            <OiqiaModal
                titleText={t("TITLE_POP")}
                bodyText={bodyModal}
                isOpen={true}
                onClose={() => onFinish()}
                firstButtonData={{
                    callback: () => onFinish(),
                    text: buttonText
                }}
            />
        </SafeAreaView>
    )
}

export default observer(CarouselTuto);