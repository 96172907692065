/* eslint-disable prefer-const */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-trailing-spaces */
import React, { useState } from "react";
import {
  ScrollView,
  Image,
  Modal,
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  Linking,
} from "react-native";

import { AntDesign } from "@expo/vector-icons";


import typo from "../../Theme/Typography";

import { StyleSheet, Dimensions, View } from "react-native";


import IconPdf from "../../assets/newDesign/icons/icon_pdf.svg";
import Colors from "../../Theme/Colors";


export default function AttachmentPdf(props: any) {
  const [modalConfig, setModalConfig] = useState({
    isVisible: false,
    url: "",
  });

  const openModal = (url: string) => {
    setModalConfig({
      isVisible: true,
      url: url,
    });
  };

  const closeModal = () => {
    setModalConfig({
      isVisible: false,
      url: "",
    });
  };

  const openPdf = (url: string) => {
    Linking.canOpenURL(url).then(supported => {
      if (supported) {
        Linking.openURL(url);
      } else {
        console.log("Don't know how to open URI: " + url);
      }
    });
  };

  return (
    <SafeAreaView style={styles.galleryContainer}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        {props.documents?.map((ele: any, i: number) => (

          <TouchableOpacity key={i} style={styles.imageContainer} onPress={() => openPdf(`${ele.container}/${ele.name}`)}>
            <View style={{ alignItems: "center", paddingTop: 10 }}>
              {Platform.OS == "web" ?
                <AntDesign name="pdffile1" size={24} color={Colors.oiqia} />
                :
                <IconPdf width={50} height={50} ></IconPdf>
              }
            </View>


            <Text style={[typo.body, styles.title]}>{ele.title}</Text>

          </TouchableOpacity>

        ))}
      </ScrollView>
    </SafeAreaView>
  );
}

let styles = StyleSheet.create({
  galleryContainer: {
    flex: 1,
    marginBottom: 5
  },
  imageContainer: {
    backgroundColor: "white",
    borderRadius: 5,
    margin: 5,
    width: 120,

    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  image: {
    width: 500,
    height: 400,
  },
  modal: {
    flex: 1,
  },
  title: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: 10,
  },
});
