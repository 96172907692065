import React, { useState, useEffect, useContext } from "react";

import * as ImagePicker from "expo-image-picker";

import { MaterialIcons } from "@expo/vector-icons";
import {
  Alert,
  StyleSheet,
  SafeAreaView,
  View,
  ScrollView,
  TouchableOpacity,
  Image,
  Text,
  Platform,
  Dimensions,
  Modal
} from "react-native";
import { Entypo } from "@expo/vector-icons";
import { Card, Title } from "react-native-paper";
import { TextInput, Button } from "react-native-paper";
import { useTranslation } from "react-i18next";
import { gql, useMutation } from "@apollo/client";
import Mutations from "../../api/mutations";
import StoreContext from "../../store/Context";

import typo from "./../../Theme/Typography";

// NEW CAMERA SCREEN
import CameraScreen from "../CameraScreen";
import OiqiaModal from "../OiqiaModal/OiqiaModal";
import OiqiaLoadingModal from "../OiqiaModal/OiqiaLoadingModal";
import OiqiaErrorModal, { OiqiaErrorModalProps } from "../OiqiaModal/OiqiaErrorModal";

export interface UpdateComplaintFormProps { }

const WINDOW_HEIGHT = Dimensions.get("window").height;
const WINDOW_WIDTH = Dimensions.get("window").width;


const REGISTER_COMPLAINT = gql`
  mutation registerProblem($bookingId: String!, $problem: String!, $image: [String!]!) {
    registerProblem(bookingId: $bookingId, problem: $problem, image: $image)
  }
`;

const UpdateComplaintForm = ({ route, navigation, ...props }: any) => {
  const [formData, setFormData] = useState<any>({
    problem: "",
    file: [],
  });
  const [isloading, setIsloading] = useState(false);

  const { problem, onRefresh } = route.params;
  const { t, i18n } = useTranslation();
  const [addProblem, { data, loading, error }] = useMutation(REGISTER_COMPLAINT);
  const [isModalVisble, setIsModalVisible] = useState<any>(false);
  const [MediaAvailable, setMediaAvailable] = useState(true);
  const [CameraOpen, setCameraOpen] = useState(false);

  const [qlError, setQlError] = useState<OiqiaErrorModalProps['qlError']>({
    networkError: "",
    message: "",
    visible: false,
  });

  const onCloseQlError = (): void => {
    setQlError({
      networkError: "",
      message: "",
      visible: false,
    });
  }

  const [deleteModalConfig, setDeleteModalConfig] = useState({
    isVisible: false,
    index: -1,
  });

  let mutations = new Mutations();
  const context = useContext(StoreContext);
  const authContext = context?.auth;

  const { bookingIdOiqia } = authContext.getBookingStore();

  useEffect(() => {
    prefetchData();
  }, []);

  const prefetchData = async () => {
    setIsloading(true);
    try {
      setFormData({
        problem: problem.description,
        file: problem.image,
      });
    } catch (err) {
      setIsloading(false);
      const { networkError = false, message = "" } = err;
      setQlError({
        visible: true,
        networkError,
        message
      });
      console.log(err);
    }
    setIsloading(false);
  };


  // at modal opening for attachement selection picture or attachement
  useEffect(() => {
    // ask permission only at opening modal
    if (isModalVisble === true) {

      // check user library permission
      (async () => {
        const { granted } = await ImagePicker.requestMediaLibraryPermissionsAsync() // obsolete Permissions.getAsync(Permissions.CAMERA_ROLL);
        //console.log("ask permission pickImage", granted);
        if (!granted) {
          alert(t("MSG_PERMISSION_LIBRARY"));
          setMediaAvailable(false);
        } else {
          setMediaAvailable(true);
        };

      })();

      // check user camera permission



    }
  }, [isModalVisble]);

  const registerproblem = async () => {
    try {
      // console.log("clicked");
      if (!formData.problem || formData.problem == "") {
        Alert.alert(t("ALERT_PROBLEM"));
      } else {
        let payload = {
          bookingId: bookingIdOiqia,
          problem: formData.problem,
          image: formData.file,
        };
        // console.log(payload);
        await addProblem({ variables: payload });
        // ToastAndroid.show(t("TOAST_SUCCESS_"), ToastAndroid.SHORT);
        if (onRefresh) onRefresh();
        navigation.navigate("CheckInForm");
      }
    } catch (err) {
      console.log("Error =>", err);
    }
  };

  useEffect(() => {
    //   console.log(formData.file, "formdata files");
  }, [formData.file]);

  const removeMe = (index: number) => {
    setFormData((ele: any) => {
      let newFiles = ele.file;

      newFiles.splice(index, 1);
      return {
        ...ele,
        file: newFiles,
      };
    });
    // to close the popup
    setDeleteModalConfig({
      isVisible: false,
      index: -1,
    });
  };

  const editPicture = async () => {
    setIsModalVisible(true);
  };

  //state portait / landscape

  const [isPortrait, setIsPortrait] = useState<boolean>(true);

  /**
   * Returns true if the screen is in portrait mode
   */
  const checkIfPortrait = () => {
    const dim = Dimensions.get('screen');
    return dim.height >= dim.width;
  };

  Dimensions.addEventListener('change', () => {
    setIsPortrait(
      checkIfPortrait() ? true : false
    );
  });

  const pickImage = async () => {

    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      base64: true,
      quality: 0.5,
      //aspect: [4, 3],
    });

    if (!result.canceled) {
      setIsloading(true);
      try {
        const base64: string = Platform.OS == "web" ? result.assets[0].uri : `data:image/jpeg;base64,${result.assets[0].base64}`
        let link: any = await mutations.uploadComplaintImage(
          bookingIdOiqia,
          base64,
        );

        setFormData((ele: any) => ({
          ...ele,
          file: [...ele.file, link.data.uploadComplaintImage],
        }));

      } catch (err) {
        setIsModalVisible(false);
        setIsloading(false);
        const { networkError = false, message = "" } = err;
        setQlError({
          visible: true,
          networkError,
          message
        });
        console.log(err);
      }
      setIsloading(false);
      setIsModalVisible(false);
    } else {
      setIsModalVisible(false);
      setIsloading(false);
    }

  };

  const takePicture = async (picture: any) => {

    if (picture) {
      setIsloading(true);
      try {
        let link: any = await mutations.uploadComplaintImage(
          bookingIdOiqia,
          `data:image/jpeg;base64,${picture.base64}`,
        );

        setFormData((ele: any) => ({
          ...ele,
          file: [...ele.file, link.data.uploadComplaintImage],
        }));

      } catch (err) {
        setIsModalVisible(false);
        setIsloading(false);
        const { networkError = false, message = "" } = err;
        setQlError({
          visible: true,
          networkError,
          message
        });
        console.log(err);
      }
      setIsloading(false);
      setIsModalVisible(false);
    } else {
      setIsModalVisible(false);
      setIsloading(false);
    }
  };
  return (
    <SafeAreaView style={typo.mainBackground}>

      <ScrollView >
        <View style={typo.container}>
          <Modal
            visible={CameraOpen}
            onRequestClose={() => setCameraOpen(false)}
          >
            <CameraScreen closeCamera={() => setCameraOpen(false)} sendPicture={takePicture} isChat={true} isPortrait={isPortrait} />

          </Modal>
          <OiqiaErrorModal onClose={onCloseQlError} qlError={qlError} />

          <OiqiaLoadingModal loading={loading || isloading} />

          <View style={typo.resCard}>
            <Text style={typo.body}>{t("CHECKIN_DESC_PB")} </Text>
          </View>
          <View style={styles.row}>
            <View style={styles.col}>
              <TextInput
                label={t("UPDATE_COMPLAINT_FORM_PROBLEM")}
                multiline
                mode="outlined"
                value={formData.problem}
                onChangeText={e => setFormData((fd: any) => ({ ...fd, problem: e }))}
              />
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col}>
              <Button compact={true} mode="contained" onPress={editPicture} color="#D71D9A" style={Platform.OS == "web" ? { marginTop: 20 } : {}}>
                {t("UPDATE_COMPLAINT_FORM_UPLOAD_BUTTON")}
              </Button>
            </View>
          </View>
          <OiqiaModal
            titleText={t("MODAL_TITLE_PICTURE")}
            bodyText={<Text>{t("MODAL_BODY_PICTURE")}</Text>}
            isOpen={isModalVisble}
            onClose={() => setIsModalVisible(false)}
            firstButtonData={{
              text: <><Entypo style={{ right: 10 }} name="images" size={21} color="white" />{"\n"}{t("UPDATE_COMPLAINT_FORM_IMG_BUTTON")}</>,
              callback: () => { pickImage(); },
            }}
            secondButtonData={{
              text: <><Entypo
                style={{ marginRight: 50 }}
                name="camera"
                size={21}
                color="white"
              />
                {"\n"}
                {t("UPDATE_COMPLAINT_FORM_CAMERA_BUTTON")}</>,
              callback: () => { setIsModalVisible(false); setCameraOpen(true) },
            }}
          />

          <View style={styles.row}>
            <View style={Platform.OS == "web" ? { ...styles.gridRow, marginTop: -30, marginBottom: 140 } : styles.gridRow}>
              {formData.file.map((ele: string, i: number) => {
                return (
                  <View key={i} style={styles.column}>
                    <Card style={styles.row}>
                      <View style={styles.col}>
                        <Image
                          style={{
                            width: 120,
                            height: 70,
                          }}
                          source={{ uri: ele }}
                        />
                      </View>

                      <View style={styles.colIcon}>
                        <MaterialIcons
                          onPress={() =>
                            setDeleteModalConfig({ isVisible: true, index: i })
                          }
                          name="delete"
                          size={30}
                          color="red"
                        />
                      </View>
                    </Card>
                  </View>
                );
              })}
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col}>
              <Button
                compact={true}
                mode="contained"
                onPress={registerproblem}
                color="#D71D9A"
              >
                {t("UPDATE_COMPLAINT_FORM_SUBMIT_BUTTON")}
              </Button>
            </View>
          </View>


          <OiqiaModal
            titleText={t("MODAL_TEXT")}
            isOpen={deleteModalConfig.isVisible}
            onClose={() => setDeleteModalConfig({ isVisible: false, index: -1 })}
            firstButtonData={{
              text: t("BUTTON_YES"),
              callback: () => removeMe(deleteModalConfig.index),
            }}
            secondButtonData={{
              text: t("BUTTON_NO"),
              callback: () => setDeleteModalConfig({ isVisible: false, index: -1 }),
            }}
          />
        </View>
      </ScrollView>

    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 50,
  },
  formWrappe: {
    flex: 1,
    padding: 10,
  },

  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    height: "auto",
    alignItems: "flex-start",
  },
  col: {
    flex: 1,
    padding: 10,
  },
  uploadButton: {
    elevation: 8,
    backgroundColor: "#009688",
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 12,
  },
  modalWrapper: {
    marginBottom: 10,
  },
  adButtonText: {
    fontSize: 18,
    flex: 1,
    flexDirection: "column",
    color: "#fff",
    fontWeight: "bold",
    alignSelf: "center",
    textTransform: "uppercase",
  },
  gridRow: {
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    justifyContent: "center",
    textAlign: "center",
  },
  column: {
    width: "auto",
    padding: 5,
  },
  rowIcon: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    height: "auto",
    alignItems: "center",
  },
  colIcon: {
    flex: 1,
    padding: 5,
    alignItems: "center",
  },
  modal: {},
});

export default UpdateComplaintForm;
