import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
//import DoorController from "./DoorController.tsx.test";
import LockListController from "./LockListController";
import { useTranslation } from "react-i18next";
const Stack = createStackNavigator();

export interface CheckInFormStackProps {}

const DoorControllerStack: React.FC<CheckInFormStackProps> = () => {
  const { t, i18n } = useTranslation();
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Security Entrance"
        component={LockListController}
        options={{
          title: t("HEADER_HOME"),
          headerStyle: {
            backgroundColor: "#D71D9A",
          },
          headerTintColor: "#fff",
          headerTitleStyle: {
            fontWeight: "bold",
          },
        }}
      />
    </Stack.Navigator>
  );
};

export default DoorControllerStack;
