/* eslint-disable max-len */
import { base } from "./base";

const es: base = {
  "QBOY": "OIQIA CAT",
  "OWNER": "PROPIETARIO",
  "TITLE_POP": "Bienvenido a Oiqia traveller",
  "BUTTON_PASS": "Ir a",
  "BUTTON_LEAVE": "Dejar",
  "WELCOME": "¡Bienvenido a la aplicación Oiqia Traveler!\n\nPor favor, lea atentamente las siguientes instrucciones para asegurarse de que puede utilizar la aplicación con facilidad y eficacia.",
  "LOGIN_WELCOME_POP": "Para utilizar Oiqia Traveler, introduzca el número de reserva enviado por el equipo de Oiqia en el campo correspondiente.\n\nElija su idioma de comunicación.",
  "HOME_WELCOME": "Con Oiqia Traveler, tienes acceso a toda la información útil sobre tu alojamiento (dirección, códigos de acceso, manuales, folleto de bienvenida, normas de la casa, código wifi, etc.).",
  "ACCESS_WELCOME": "Con Oiqia Traveler, puedes hacer clic en el botón \" abrir/cerrar \" para abrir/cerrar la puerta del edificio y/o del piso.\n\nTambién puede escribir el código de entrada en el teclado numérico situado cerca de la puerta del piso.",
  "FORM_WELCOME": " Cuando llegue al alojamiento, deberá registrarse.\n\nSi todo está bien, confirme que el alojamiento está en buenas condiciones.\nSi hay algún problema, comuníquenoslo. Este paso es esencial en caso de desacuerdo.\n\nCuando te vayas, no te olvides de comprobarlo.",
  "CHAT_WELCOME": "Con Oiqia Traveler, puedes comunicarte directamente con nosotros a través del chat.\n\nTambién encontrará los datos de contacto de nuestro gestor de clientes de Oiqia en caso de emergencia.",
  "SETTING_WELCOME": "Oiqia Traveler se comunica en varios idiomas: elige tu idioma preferido para utilizar la aplicación.\n\nTambién puedes salir de la aplicación.\n\nO cambiar su reserva si tiene varias reservas en curso con Oiqia.",
  "BOOKING_ID": "Identificador",
  "BOOKING_EMAIL": "Envíe un correo electrónico a",
  "LOGIN": "Entrar en el sistema",
  "LOGIN_WELCOME": "Por favor, introduzca el código enviado para su reserva:",
  "POPUP_CHECKIN_TITLE": "¡Bienvenido a su ubicación!",
  "POPUP_CHECKIN_DESCRIPTION": "Para poder disfrutar de su alquiler, le rogamos que rellene el inventario de instalaciones en la pestaña \"formularios\".",
  "POPUP_CHECKIN_BUTTON": "Haciendo el checkin",
  "HOME": "Inicio",
  "EVENTS": "Eventos al lado",
  "RESTAURANTS": "Restaurantes en los alrededores",
  "ACCESS": "Acceda a",
  "BOOKING": "Reserva",
  "ACCESS_CODE": "Código de acceso",
  "UNLOCK_DOOR": "Abrir la puerta",
  "LOCK_DOOR": "Cierre la puerta",
  "INFO_TITLE": "Encuentre su\ninformación sobre el alquiler",
  "RESERVATION_REF": "Su código de reserva :",
  "ADDRESS": "Dirección del lugar",
  "ARRIVAL": "Llegada",
  "DEPARTURE": "Salida",
  "DIRECTIONS": "Calcular la ruta",
  "FROM": "de",
  "TO": "a",
  "COPIED": "Código Wifi copiado",
  "TEXT_COPIED": "El código wifi ha sido copiado y puedes conectarte al wifi pegando el código",
  "EQUIPMENT": "Equipo",
  "BEDROOM": "Habitación",
  "KITCHEN": "Cocina",
  "BATHROOM": "Baño",
  "SALON": "Salón",
  "OUTSIDE": "Al aire libre",
  "COMFORT": "Confort",
  "WIFINAME": "Nombre del wifi",
  "WIFICODE": "Código Wifi",
  "EXTRAINFO": "Información adicional",
  "FORMS": "Formularios",
  "CHECKINFORM": "Formulario de inscripción",
  "COMPLAINFORM": "Formulario de incidencias",
  "CHECKOUTFORM": "Formulario de salida",
  "INCIDENT": "Soporte",
  "ACCESS_OPEN_KEYPAD": "Para abrir con el teclado\nIntroduzca sólo estos 6 números",
  "ACCESS_CLOSE_KEYPAD": "Para cerrar con el teclado",
  "ACCESS_CLOSE_KEY": "pulse",
  "ACCESS_MANAGE_APP": "O a través de la aplicación",
  "PLACEHOLDER_INPUT": "Haz tu pregunta al soporte de Oiqia",
  "INITIAL_BOT_MESSAGE": "Hola, soy Qboy. ¿En qué puedo ayudarle?",
  "QBOY_PERTINENCE": "Hola de nuevo, ¿son relevantes las respuestas de Qboy?",
  "QBOY_PERTINENCE_SUCCESS": "¡Perfecto!",
  "QBOY_PERTINENCE_FAIL": "Bien, nos pondremos en contacto con el servicio de atención al cliente y se pondrán en contacto con usted.",
  "SETTINGS": "Parámetros",
  "INFO_SETTING": "Ajusta tu configuración",
  "CHANGE_RESERVATION": "Cambiar la reserva",
  "LANGUAGE": "Idioma",
  "HOW_TO_CONTACT": "¿Cómo le gustaría que nos pusiéramos en contacto con usted?",
  "CHOICE_LANG": "Elija su idioma",
  "EMAIL": "Envíe un correo electrónico a",
  "NOTIF": "Notificaciones push",
  "SMS": "Sms",
  "RECO": "Recibir recomendaciones de Qboy",
  "LOGOUT": "Cerrar la sesión",
  "WARNING": "Atención",
  "HELP_TITLE": "Necesidad de ayuda",
  "HELP_TEXT": "Muéstrame",
  "ERROR_NETWORK": "Compruebe su conexión a Internet.",
  "ACCESS_CODE_NOT_AVAILABLE_BEFORE_BOOKING_ARRIVAL": "El código de acceso sólo puede ser emitido una vez que se ha hecho la reserva.",
  "ERROR_BOOKING_NOT_FOUND_TITLE": "Reserva no encontrada",
  "ERROR_BOOKING_NOT_FOUND_DESCRIPTION": "Los datos de la reserva que ha proporcionado no coinciden con ninguna reserva en nuestro sistema",
  "ERROR_CHAT_BOT_TITLE": "Error técnico",
  "ERROR_CHAT_BOT_DESCRIPTION": "Se ha producido un problema. Por favor, inténtelo más tarde.",
  "ERROR_INCOMPLETE_FORM_TITLE": "Formulario incompleto",
  "ERROR_INCOMPLETE_FORM_DESCRIPTION": "Por favor, rellene el formulario correctamente.",
  "ERROR_ACCESS_CODE_TITLE": "Ha surgido un problema",
  "ERROR_ACCESS_CODE_DESCRIPTION": "No se puede recuperar el código de acceso a la casa.",
  "UNKOWN_ERROR_TITLE": "Ha surgido un problema inesperado",
  "UNKOWN_ERROR_DESCRIPTION": "Se ha producido un problema inesperado. Por favor, inténtelo más tarde.",
  "CHECKIN_FORM_FIRSTNAME_LABEL": "Nombre",
  "CHECKIN_FORM_LASTNAME_LABEL": "Nombre",
  "CHECKIN_FORM_EMAIL_LABEL": "Envíe un correo electrónico a",
  "CHECKIN_FORM_RESERVATION_ID_LABEL": "Número de reserva",
  "CHECKIN_FORM_PHONE_LABEL": "Teléfono",
  "CHECKIN_FORM_ARRIVAL_LABEL": "Llegada",
  "CHECKIN_FORM_DEPARTURE_LABEL": "Salida",
  "CHECKIN_FORM_ADULTS_LABEL": "Adultos",
  "CHECKIN_FORM_CHILDREN_LABEL": "Niños",
  "CHECKIN_FORM_CHECKBOX_TITLE_LABEL": "¿Cumple el alojamiento sus expectativas?",
  "CHECKIN_FORM_CHECKBOX_YES_LABEL": "Sí",
  "CHECKIN_FORM_CHECKOBOX_NO_LABEL": "No",
  "CHECKIN_FORM_SUBMIT_BUTTON": "Enviar",
  "CHECKIN_FORM": "Formulario de inscripción",
  "CHECKIN_FORM_HEADER": "formulario de inscripción",
  "CHECKIN_TITLE": "Registro de entrada\nformulario de inscripción",
  "CHECKIN_DESC_TITLE": "Gracias por registrarse:",
  "CHECKIN_DESC": "Compruebe que la información que aparece a continuación es correcta y confirme el estado del alojamiento.\n\nEste registro servirá como documento de referencia, por favor, rellénelo cuidadosamente.",
  "CHECKIN_DESC_PB_TITLE": "Por favor, indique el problema encontrado:",
  "CHECKIN_DESC_PB": "Describe el problema, puedes incluir una o varias fotos.",
  "CHECKIN_POPUP_TITLE": "Debe confirmar que está presente en el alojamiento para validar el registro",
  "CHECKIN_POPUP_SUBTITLE": "P.D.: Cualquier daño encontrado en el alojamiento después del check-in será responsabilidad suya.",
  "CHECKIN_POPUP_EARLY": "Sólo se puede validar el check-in una vez que se ha iniciado la reserva",
  "CHECKOUT_FORM": "Formulario de salida",
  "CHECKOUT_TITLE": "Check-Out\nformulario de salida",
  "CHECKOUT_SUBTITLE": "GRACIAS POR COMPROBARLO",
  "CHECKOUT_DESC": "Es el momento de comprobarlo marcando las casillas de abajo.\n\nAlgunas de sus respuestas son su responsabilidad, le agradecemos de antemano que lo haga con seriedad.\n\nPor último, no olvides dejar las\ninstalaciones como te gustaría encontrarlas.",
  "CHECKOUT_FORM_VERY_UNSATISFACTORY": "Muy insatisfactorio",
  "CHECKOUT_FORM_UNSATISFACTORY": "insatisfactorio",
  "CHECKOUT_FORM_NEUTRE": "Ni satisfactorio ni insatisfactorio",
  "CHECKOUT_FORM_SATISFACTORY": "Satisfactorio",
  "CHECKOUT_FORM_VERY_SATISFACTORY": "Muy satisfactorio",
  "CHECKOUT_FORM_RATING_APPART": "¿Cuál es su valoración general de la vivienda alquilada?",
  "CHECKOUT_FORM_RATING_PROPERTY": "¿Cómo calificaría la limpieza de la vivienda alquilada?",
  "CHECKOUT_FORM_RATING_OIQIA": "¿Cómo valora la llegada autónoma a través de la cerradura conectada?",
  "CHECKOUT_DESCRIPTION_TITLE": "¿Tiene algún comentario sobre su estancia?",
  "CHECKOUT_DESCRIPTION": "Descripción",
  "CHECKOUT_FORM_ASK_LEAVE": "Está a punto de dejar la vivienda alquilada.\n¿Está en las mismas condiciones que cuando llegó?",
  "CHECKOUT_COMENTARY_TITLE": "Por favor, facilite los detalles de su respuesta en el espacio para comentarios que aparece a continuación.",
  "CHECKOUT_COMENTARY": "Por favor, informe de cualquier problema o daño en el piso. También puede dejarnos un comentario sobre su estancia.",
  "CHECKOUT_PICTURE": "A continuación, puede hacer fotos del problema o los problemas encontrados.",
  "CHECKOUT_QCM_ASK": "Por último, contamos con usted para que respete las instalaciones y participe en hacer que la comunidad de alquileres a corto plazo sea más agradable y fiable.",
  "CHECKOUT_QCM_HEADER": "Al marcar las casillas que aparecen a continuación, está afirmando :",
  "CHECKOUT_QCM_EMPTY": "Nada que hacer",
  "CHECKOUT_ENDING": "Antes de validar la salida, todo el equipo de Oiqia\nEl equipo le desea un buen viaje a casa.\nSu satisfacción es nuestra prioridad, por favor no dude en volver a nosotros con cualquier comentario que pueda tener sobre su estancia.",
  "CHECKOUT_THANKS": "Esperamos volver a darle la bienvenida a Oiqia.",
  "CHECKOUT_PROBLEM": "¿Tuvo algún problema durante su estancia?",
  "CHECKOUT_TODOLIST": "Lista de control",
  "CHECKOUT_POPUP_TITLE": "Está a punto de validar el check out cuando su fecha de salida está prevista para",
  "CHECKOUT_POPUP_TIME": "à",
  "CHECKOUT_POPUP_SUBTITLE": "PS: Usted será responsable de cualquier daño encontrado en el alojamiento después de la confirmación de la salida",
  "COMPLAINT_FORM_HEADER": "Formulario de reclamación",
  "COMPLAINT_FORM_PROBLEM": "Introduzca el problema",
  "COMPLAINT_FORM_UPLOAD_BUTTON": "Añadir una foto",
  "COMPLAINT_FORM_IMG_BUTTON": "Imagen de la galería",
  "COMPLAINT_FORM_CAMERA_BUTTON": "Haz una foto",
  "COMPLAINT_FORM_CANCEL_BUTTON": "Cancelar",
  "COMPLAINT_FORM_SUBMIT_BUTTON": "Guardar",
  "EDIT_COMPLAINT_LABEL": "Descripción del problema",
  "EDIT_COMPLAINT_BUTTON": "Editar",
  "DECLARE_PROBLEM": "Informar de un problema",
  "DECLARERED_PROBLEM": "Problema notificado",
  "EDIT_COMPLAINT_IMAGE": "Imagen del incidente(s)",
  "EDIT_DESCRIPTION": "¿Tiene alguna opinión sobre la llegada autónoma?",
  "OWNER_TITLE": "En caso de problemas\nContactar con el propietario",
  "OWNER_FIRSTNAME": "Nombre",
  "OWNER_LASTNAME": "Nombre",
  "OWNER_EMAIL": "Correo",
  "OWNER_PHONE": "Tel.",
  "OWNER_CALL": "Llame a",
  "OWNER_MSG": "Mensaje",
  "OWNER_MAIL": "Envíe un correo electrónico a",
  "MSG_PERMISSION_LIBRARY": "Lo sentimos, pero necesitamos permisos para acceder a su directorio.",
  "MSG_PERMISSION_CAMERA": "Lo sentimos, pero necesitamos permisos para acceder a tu cámara.",
  "UPDATE_COMPLAINT_FORM_HEADER": "Actualizar el formulario de reclamación",
  "UPDATE_COMPLAINT_FORM_PROBLEM": "Introduzca el problema",
  "UPDATE_COMPLAINT_FORM_UPLOAD_BUTTON": "Añadir una foto",
  "UPDATE_COMPLAINT_FORM_IMG_BUTTON": "Imagen de la galería",
  "UPDATE_COMPLAINT_FORM_CAMERA_BUTTON": "Haz una foto",
  "UPDATE_COMPLAINT_FORM_CANCEL_BUTTON": "Cancelar",
  "UPDATE_COMPLAINT_FORM_SUBMIT_BUTTON": "Guardar",
  "APARTMENT_DOOR_DESCRIPTION": "Descripción",
  "APARTMENT_DOOR_DOOR_TYPE": "Tipo de puerta",
  "APARTMENT_DOOR_DEVICE_TYPE": "Tipo de dispositivo",
  "APARTMENT_DOOR_DIGIT_CODE": "Digicode",
  "APARTMENT_DOOR_MANUAL_CODE": "Código de acceso",
  "APARTMENT_DOOR_DOOR_NAME": "Nombre de la puerta Oiqia",
  "APARTMENT_DOOR_BUTTON_UNLOCK": "Desbloquear",
  "APARTMENT_DOOR_BUTTON_LOCK": "Bloqueo",
  "APARTMENT_DOOR_TITLE": "Instrucciones para acceder a su vivienda",
  "APPARTMENT_DOOR_STATE": "Estado",
  "BUTTON_POPUP_TITLE_DOOR": "¿Qué quieres hacer?",
  "BUTTON_POPUP_TITLE": "¿Quieres desbloquearlo?",
  "BUTTON_POPUP_TITLE_LOCK": "¿Quieres cerrar?",
  "BUTTON_POPUP_YES": "Sí",
  "BUTTON_POPUP_YESS": "Sí",
  "BUTTON_POPUP_NO": "No",
  "CHECKIN_DONE_TITLE": "Fecha de registro",
  "CHECKOUT_DONE_TITLE": "Fecha de salida",
  "CHECKIN_HISTORY_BUTTON": "Registro de entrada",
  "CHECKOUT_HISTORY_BUTTON": "Comprueba",
  "CHECKOUT_ADD_COMPLAINT": "Añadir una queja",
  "COMPLAINTS_HISTORY_TITLE": "Descripción del problema",
  "COMPLAINTS_HISTORY_LABEL_PROBLEM": "Descripción",
  "COMPLAINTS_HISTORY_LABEL_IMAGE": "Fotos",
  "ALERT_CHECKIN_DONE": "Registro completado con éxito",
  "ALERT_PHONE_MANDATORY": "Número de teléfono no válido, por ejemplo +33 06 01 02 03 04",
  "ALERT_PROBLEM": "Introduzca un problema",
  "TOAST_SUCCESS_": "Reclamación añadida con éxito",
  "HEADER_RESERVATION": "Inicio",
  "HEADER_HOME": "Acceda a",
  "HEADER_SETTINGS": "Parámetros",
  "HEADER_CHECKINFORM": "Formulario de control",
  "HEADER_COMPLAINTFORM": "Planteamiento del problema",
  "HEADER_UPDATE_COMP_FORM": "Editar el problema",
  "HEADER_HISTORY_CHECKIN": "Historial de facturación",
  "HEADER_HISTORY_CHECKOUT": "Historial de salidas",
  "BUTTON_YES": "Sí",
  "BUTTON_NO": "no",
  "MODAL_TEXT": "¿Quieres borrar...",
  "ALERT_FIRSTNAME_MANDATORY": "El nombre es obligatorio",
  "ALERT_LASTNAME_MANDATORY": "El apellido es obligatorio",
  "ALERT_EMAIL_MANDATORY": "Su correo electrónico no es válido",
  "ALERT_ADULT_MANDATORY": "Los adultos son obligatorios",
  "SET_PHONE_MANDATORY": "Número de teléfono con código de área +33 ...",
  "ATTACHMENT_PICTURES": "Fotos",
  "ATTACHMENT_RULES": "Reglas",
  "ATTACHMENT_MANUALS": "Manuales",
  "ATTACHMENT_WELCOME_NOTES": "Notas de bienvenida",
  "ERROR_NETWORK_TITLE": "Error de red.",
  "HELP": "Ayúdame",
  "VALIDATE": "Confirme",
  "CANCEL": "Cancelar",
  "MODAL_TITLE_PICTURE": "Hacer una foto",
  "MODAL_BODY_PICTURE": "Puedes tomar la foto desde tu galería o desde tu cámara.",
  "ERROR": "Error ...",
  "MISSING_DATA_ERROR": "Por favor, facilite una descripción del problema y al menos una foto.",
  "MSG_PERMISSION_CAMERA_TITLE": "Permiso denegado",
  "SELECT_LANGAGE": "Su idioma :",
  "CLOSE": "Cerrar",
  "ERROR_NETWORK_HELPER": "Cierra la aplicación, desconecta tu dispositivo de la red, vuelve a conectarlo e inténtalo de nuevo.",
};

export default es;
