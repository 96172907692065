import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import SettingController from "./Setting";
import { useTranslation } from "react-i18next";

const Stack = createStackNavigator();

export interface CheckInFormStackProps {}

const SettingStack: React.FC<CheckInFormStackProps> = () => {
  const { t, i18n } = useTranslation();
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Settings"
        component={SettingController}
        options={{
          title: t("HEADER_SETTINGS"),
          headerStyle: {
            backgroundColor: "#D71D9A",
          },
          headerTintColor: "#fff",
          headerTitleStyle: {
            fontWeight: "bold",
          },
        }}
      />
    </Stack.Navigator>
  );
};

export default SettingStack;
