/* eslint-disable @typescript-eslint/no-var-requires */
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useRef, useState } from "react";

import {
    StyleSheet,
    AppState,
    Text
} from "react-native";

//import Button from "../Button";
import { storeTypes } from "../../store";
import StoreContext from "../../store/Context";


import Colors from "../../Theme/Colors";
import Queries from "../../api/queries";
import OiqiaModal from "../OiqiaModal/OiqiaModal";
import OiqiaErrorModal, { OiqiaErrorModalProps } from "../OiqiaModal/OiqiaErrorModal";



interface BookingsScreenProps extends storeTypes {
    navigation: any;
}

const WelcomePopUp: React.FC<BookingsScreenProps> = ({ navigation }) => {

    const [isVisible, setVisible] = useState(false)
    const appState = useRef(AppState.currentState);

    const { t } = useTranslation();

    const context = useContext(StoreContext);
    const authContext = context?.auth;

    const { bookingIdOiqia } = authContext.getBookingStore();

    const [qlError, setQlError] = useState<OiqiaErrorModalProps['qlError']>({
        networkError: "",
        message: "",
        visible: false,
    });

    const onCloseQlError = (): void => {
        setQlError({
            networkError: "",
            message: "",
            visible: false,
        });
    }

    let queries = new Queries()

    const getStatusBooking = async () => {
        try {
            const response = await queries.getBookingStatus(bookingIdOiqia)
            const bookingStatus = response.data.getBookingStatus.bookingStep;
            const allStatus = bookingStatus.map(element => element.status);
            if (allStatus.indexOf(10) != -1 && allStatus.indexOf(20) == -1 && context.popUp.getCheckInStatePop()) {
                setVisible(true)
            } else {
                setVisible(false)
            }
        } catch (err) {
            const { networkError = false, message = "" } = err;
            setQlError({
                visible: true,
                networkError,
                message
            });
            console.log(err);
        }
    }

    useEffect(() => {
        getStatusBooking();
    }, [])

    useEffect(() => {
        AppState.addEventListener("change", (nextAppState) => {
            if (nextAppState === 'active') {
                getStatusBooking()
            }
            appState.current = nextAppState;
        })
    }, [AppState.currentState]);

    return (
        <>
            <OiqiaErrorModal onClose={onCloseQlError} qlError={qlError} />
            <OiqiaModal
                titleText={t("POPUP_CHECKIN_TITLE")}
                bodyText={<Text>{t("POPUP_CHECKIN_DESCRIPTION")}</Text>}
                isOpen={isVisible}
                onClose={() => setVisible(false)}
                firstButtonData={{
                    text: t("POPUP_CHECKIN_BUTTON"),
                    callback: () => {
                        setTimeout(() => {
                            navigation.navigate('Forms', { screen: "CheckInForm" });
                        }, 500);
                        setVisible(false)
                    },
                }}
            />
        </>
    );
};

const style = StyleSheet.create({
    Button: {
        backgroundColor: Colors.oiqia,
        borderRadius: 10,
        justifyContent: "center",
        color: "white",
        height: 44,
        width: '130%'
    },
    text: {
        marginTop: -10,
        marginBottom: 15,
        width: '98%',
        alignItems: "center"
    }
})

export default WelcomePopUp;
