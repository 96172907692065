import I18n from "i18next";
import App from "./App";
//import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { Platform } from "react-native";
import Mutations from "../api/mutations";

async function registerForPushNotificationsAsync(bookingId: string, lang: string) {
  const mutate = new Mutations();
  let token;

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      alert('Failed to get push token for push notification!');
      return;
    }
    //token = (await Notifications.getExpoPushTokenAsync(Constants.expoConfig?.extra?.eas?.projectId)).data; //OBSOLET?
    token = (await Notifications.getExpoPushTokenAsync()).data;
    //console.log("Token =>", token);
    try {
      await mutate.pushTokenNotif(token, bookingId, lang)
    } catch (err) {
      console.log(JSON.stringify(err, null, 2))
    }
  } else {
    alert('Must use physical device for Push Notifications');
  } 

  return token;
}

export default class Auth extends App {

  // async login(email: string, bookingId: string) {
  async login(bookingId: string, lang: string) {

    if (
      !this.validateInputString(bookingId)
    )
      throw new Error(
        I18n.t("ERROR_INCOMPLETE_FORM_TITLE"),
        I18n.t("ERROR_INCOMPLETE_FORM_DESCRIPTION")
      );
    const validBookingId = bookingId.trim();

    try {
      const response = await this.quries.getBookingByExtId(validBookingId);

      if (!response?.data?.getBookingByExtId) { // reservation inconnu retour vide

        throw new Error(I18n.t("ERROR_BOOKING_NOT_FOUND_TITLE"), I18n.t("ERROR_BOOKING_NOT_FOUND_DESCRIPTION"));
        /* this.alertMessage(
          I18n.t("ERROR_BOOKING_NOT_FOUND_TITLE"),
          I18n.t("ERROR_BOOKING_NOT_FOUND_DESCRIPTION"),
          "danger"
        ); */

      } else {

        const booking = response?.data?.getBookingByExtId;
        await this.rootStore.bookingStore.updateBooking(booking);
        if (Platform.OS != "web") {
          registerForPushNotificationsAsync(validBookingId, lang)
        }

        this.rootStore.userStore.login(validBookingId);

        this.setUserBookingInfo({ bookingId });
      }

    } catch (err) {
      throw new Error(err);
    }
  }

  private validateEmail(email: string) {
    const isValidInput = this.validateInputString(email);
    // eslint-disable-next-line max-len
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email.toLowerCase()) && isValidInput;
  }

  private validateInputString(input: string) {
    return input.trim().length > 0;
  }

  async logout() {
    this.rootStore.notificationsStore.resetChat();
    this.getUserStore().logout();
    await this.removeUserBooking();
  }

  async setCarouselStatus(value: boolean) {
    this.rootStore.userStore.setCarouselStatus(value)
  }

  async getUserFromStorage() {
    try {
      const user = await this.getUserBooking();

      if (user) {
        const { bookingId } = user;

        const { data } = await this.quries.getBookingByExtId(bookingId);
        const booking = data.getBookingByExtId;

        await this.rootStore.bookingStore.updateBooking(booking);
        this.rootStore.userStore.login(bookingId);
        return Promise.resolve(booking);
      }
    } catch (error) {
      console.log(error);
    }
    return Promise.reject();
  }
}
