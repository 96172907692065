import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from "./src/i18n/en";
import es from './src/i18n/es';
import fr from "./src/i18n/fr";
import it from './src/i18n/it';
import pt from './src/i18n/pt';
import ru from './src/i18n/ru';

i18n.use(initReactI18next).init({
    lng: "en",
    fallbackLng: 'en',
    resources: {
        en: {
            translation: en
        },
        fr: {
            translation: fr
        },
        es: {
            translation: es
        },
        ru: {
            translation: ru
        },
        pt: {
            translation: pt
        },
        it: {
            translation: it
        }
    },

});
export default i18n;