/* eslint-disable no-return-assign */
import { action, makeObservable, observable } from "mobx";

import RootStore from "../index";


export default class UserStore {
  isLoggedIn = null;
  bookingId = "";
  carouselStatus = false;
  //email: string | null = null;
  rootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isLoggedIn: observable,
      bookingId: observable,
      carouselStatus: observable,
      //email: observable,

      login: action,
      logout: action,
      updateBookingId: action,
      setCarouselStatus: action,
      //updateEmail: action,
      updateIsLoggedIn: action,
      getBookingIdAuth:action
    });
    this.rootStore = rootStore;
  }


  // login = (bookingId: string, email: string) => {
  login = (bookingId: string) => {
    this.bookingId = bookingId;
    //this.email = email;
    this.isLoggedIn = true;
  }

  logout = async () => {
    this.isLoggedIn = false;
  }

  setCarouselStatus = (status: boolean) => {
    this.carouselStatus = status;
  }

  updateBookingId = (id: string) => this.bookingId = id;

  //updateEmail = (email: string) => this.email = email;

  updateIsLoggedIn = (loginStatus: boolean) => this.isLoggedIn = loginStatus;

  getBookingIdAuth = ()=>(this.bookingId);

}
