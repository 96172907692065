import { StyleSheet } from "react-native";
import { black } from "react-native-paper/lib/typescript/styles/colors";

const OIQIA_COLOR = "#D71D9A";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  center: {
    flex: 1,
    justifyContent: "flex-end",
  },
  languageSelector:{
    position:"absolute",
    padding:30
  },
  imageWrapper: {
    alignItems: "center",
    marginBottom: 20,
  },
  image: {
    height: 70,
    width: 160,
  },
  logo:{
    height: 150,
    width: 300,
  },
  titleWelcome:{
    fontSize: 18,
    color: "black",
    textAlign:"center",
    alignItems: "center",
    justifyContent: "center",
    fontFamily:"RobotoMedium"
  },

  titleWrapper: {
    alignItems: "center",
    margin: 20,
  },
  title: {
    fontSize: 16,
    color: "white",
    fontFamily:"MontserratLight"
  },
  inputsWrapper: {
    width: "80%",
    alignSelf: "center",
    alignItems: "center",
    marginHorizontal: 20,
    marginBottom: 15,
  },
  languageChoice:{
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: "purple",
    borderRadius: 8,
    color: "black",
    paddingRight: 30,
  },
  butonWrapper: {
    width: "80%",
    alignSelf: "center",
    marginBottom:15
  },
  button: {
    alignSelf: "center",
    backgroundColor: OIQIA_COLOR,
  },
});

export default styles;
