/*eslint max-len: ["error", { "code": 300 }]*/
import { gql } from "apollo-boost";

export const GET_BOOKINGS = gql`
  query getBookings {
    getBookings {
      accessCode

      id
      apartment {
        id
        name
        locks {
          smartLockId
          name
        }
      }
      price
      adults
      children
    }
  }
`;

export const GET_BOOKING_BY_ID_AND_EMAIL = gql`
  query GetBookingByIdAndEmail($bookingId: String!, $email: String!) {
    getBookingByIdAndEmail(bookingId: $bookingId, email: $email) {
      id
      adults
      children
      type
      checkIn
      checkOut
      arrival
      departure
      apartment {
        id
        name
        location {
          street
          zip
          city
          longitude
          latitude
        }
        locks {
          smartLockId
          state
          name
        }
      }
      email
      price
      guestName
      pricePaid
      accessCode
    }
  }
`;

export const GET_BOOKING_STEP = gql`
  query getBookingStatus($bookingId: String!) {
    getBookingStatus(bookingId: $bookingId) {
      bookingStep{
        status
        createdAt
      }
    }
  }
`

export const GET_BOOKING_BY_EXT_ID = gql`
  query getBookingByExtId($bookingExtId: String!) {
    getBookingByExtId(bookingExtId: $bookingExtId) {
      bookingIdOiqia
      firstName
      lastName
      arrival
      departure
      checkIn
      checkOut
      formatedAddress
      address{
        extra
        streetNumber
        street
        city
        zipCode
        country
      }
      lat
      lng
      timeZone
      bookingStep {
        status
        createdAt
      }
      responsability
  }
}
`;

export const GET_OWNER_DATA = gql`
  query getOwnerData($bookingId: String!) {
    getOwnerData(bookingId: $bookingId) {
      users {
        email
        firstName
        lastName
        phoneNumber
      }
    }
  }
`

export const GET_BOOKING_BY_ID = gql`
  query getBookingById($bookingId: String!) {
    getBookingById(bookingId: $bookingId) {
      id
      adults
      children
      type
      checkIn
      checkOut
      arrival
      departure
      apartment {
        id
        name
        location {
          street
          zip
          city
          longitude
          latitude
        }
        locks {
          smartLockId
          state
          name
        }
      }
      email
      price
      guestName
      pricePaid
      accessCode
    }
  }
`;


export const GET_CHECKINDATA = gql`
  query getCheckIn($bookingId: String!) {
    getCheckIn(bookingId: $bookingId) {
      email
      phone
      adults
      children
      arrival
      departure
      firstName
      lastName
      isCheckinDone
      isSatisfied
      problem {
        description
        image
      }
      
    }
  }
`;

export const GET_CHECKOUTDATA = gql`
  query getCheckOut($bookingId: String!, $language: String) {
    getCheckOut(bookingId: $bookingId, language: $language) {
      ratingAppart
      ratingCleanness
      ratingOiqia
      feedBack
      isCheckoutDone
      isSatisfied
      problem {
        description
        image
      }
      checkoutQuestion {
        text
        checked
      }
      createdAt
    }
  }
`;

export const GET_APPART_CARACT = gql`
  query getAppartmentCaract($bookingId: String!, $language: String) {
    getAppartmentCaract(bookingId: $bookingId, language: $language) {
      kitchen {
        type
        text
      }
      bathroom {
        type
        text
      }
      salon {
        type
        text
      }
      bedroom {
        name
        value {
          text
        }
      }
      outside {
        type
        text
      }
      access {
        type
        text
      }
      comfort {
        type
        text
      }
      description
      wifiName
      wifiCode
      extraInfo
    }
  }
`

export const GET_NUKI_INFO = gql`
query getNukiSmartLockInfo($smartLockId:Float!){
  getNukiSmartLockInfo(smartLockId:$smartLockId){
    state
    name
  }
}
`;

export const GET_PREVIOUS_COMPLAINTS = gql`
  query getPreviousComplaints($bookingId:String!){
      getPreviousComplaints(bookingId:$bookingId){
        bookingId
        problem
      }
    }
`;

//get apartment doors
export const GET_APARTMENT_DOOR_LOCKS = gql`
 query getAppartementDoors($bookingId:String!){
  getAppartementDoors(bookingId:$bookingId){
    _id
    description
    doorType
    deviceType
    digitCode 
    manualCode
    oiqiaDoorName
    doorDeviceId
    textMultiDesc {
      fr
      en
      es
      pt
      ru
      it
    }
    textMultiName {
      fr
      en
      es
      pt
      ru
      it
    }
  }
}
 `;

export const GET_OIQIA_SMARTLOCKINFO = gql`
  query getOiqiaSmartLockInfo($bookingId:String! ,$doorDeviceId:String!){
    getOiqiaSmartLockInfo(bookingId:$bookingId,doorDeviceId:$doorDeviceId){
    name
    state
    stateValue
    doorStateValue
    doorState
  }
}
 `;




