import React, { useContext, useEffect, useState } from "react";
import * as ImagePicker from 'expo-image-picker';
import { StatusBar } from "expo-status-bar";
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet, TouchableOpacity, ImageBackground, SafeAreaView, Linking, ScrollView, Dimensions, Modal } from "react-native";
import { toJS } from "mobx";

import { GiftedChat, ActionsProps, Actions } from "react-native-gifted-chat";
import { Feather } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import SendButton from "../SendButton";
import { Message } from "../../controllers/Chat";
import StoreContext from "../../store/Context";

import Mutations from "../../api/mutations";
import Queries from "../../api/queries";

import typo from "../../Theme/Typography";
import Colors from "../../Theme/Colors";
import { observer } from "mobx-react-lite";

// NEW CAMERA SCREEN
import CameraScreen from "../CameraScreen";

export interface CheckInProps {
  navigation: any;
}

const USER = {
  "_id": "traveler",
  "name": "Locataire",
  "avatar": ""
}

const BG_PATH = require("../../assets/newDesign/background-portrait.png");

const WINDOW_HEIGHT = Dimensions.get("window").height;
const WINDOW_WIDTH = Dimensions.get("window").width;

const ChatIncident: React.FC<CheckInProps> = () => {
  const { t, i18n } = useTranslation();
  const context = useContext(StoreContext);
  const authContext = context?.auth;
  const { booking, bookingIdOiqia = "" } = authContext.getBookingStore()
  const notificationsContext = context?.notifications;
  const chatData = notificationsContext.getChatMsgSub();

  // State declaration
  const [isAllowed, setAllowed] = useState(true)
  const [ownerData, setOwnerData] = useState([])
  const [Conversation, setConversation] = useState([]);

  const [CameraOpen, setCameraOpen] = useState(false);


  let queries = new Queries();
  let mutations = new Mutations();

  // at the staert call subscirption
  useEffect(() => {
    if (booking?.responsability === "Oiqia") {
      setAllowed(true);
      //console.log("initialisatisation sub");
      notificationsContext.startMsgSub();
    } else {
      setAllowed(false)
      getOwner()
    }

  }, []);

  const getOwner = async () => {
    try {
      const response = await queries.getOwnerData(bookingIdOiqia)
      const dataUser = response?.data?.getOwnerData?.users;
      const users = dataUser?.map(element => {
        return {
          firstName: element.firstName,
          lastName: element.lastName,
          email: element.email,
          phoneNumber: element.phoneNumber,
        }
      })
      setOwnerData(users)
    } catch (err) {

    }
  }


  useEffect(() => {
    const chatDataJs = toJS(chatData);

    if (chatDataJs && chatDataJs.length > 0) {
      const { conversation = [] } = chatDataJs[0];
      const newConversation = conversation.map(element => {
        let text = element.text;
        if (element.textMulti && element.textMulti[i18n.language]) {
          text = element.textMulti[i18n.language];
        }
        return {
          ...element,
          text,
        }
      })
      setConversation(newConversation);
      if (0) {
        //pushNotification("Vous avez un nouveau message", "Un admin vous a envoyé un message !")
      }
    } else {
      setConversation([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatData, i18n.language]);

  const sendText = async (newMessages: Message[]) => {
    await notificationsContext.sendChatIncidentMsg(newMessages)
  }

  const sendPicture = async (picture) => {
    try {
      const link: any = await mutations.uploadComplaintImage(
        bookingIdOiqia,
        `data:image/jpeg;base64,${picture.base64}`,
      );
      //console.log("link =>", link, "Picture Object =>", picture)
      const msg = {
        _id: bookingIdOiqia,
        text: "Image",
        createdAt: new Date(),
        image: link.data.uploadComplaintImage,
        user: USER
      }
      const Messages = [];
      Messages.push(msg);
      await notificationsContext.sendChatIncidentMsg(Messages)
    } catch (err) {
      alert(t("ERROR_CHAT_BOT_TITLE") + " " + t("ERROR_CHAT_BOT_DESCRIPTION"))
    }
  };

  const takePicture = () => {
    setCameraOpen(true);
  }

  const pickImage = async () => {
    const { granted } = await ImagePicker.requestMediaLibraryPermissionsAsync()
    if (!granted) {
      alert(t("MSG_PERMISSION_LIBRARY"));
      return
    }
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      base64: true,
      quality: 0.5,
    });
    if (!result.canceled) {
      sendPicture(result)
    }
  }

  //state portait / landscape

  const [isPortrait, setIsPortrait] = useState<boolean>(true);

  /**
   * Returns true if the screen is in portrait mode
   */
  const checkIfPortrait = () => {
    const dim = Dimensions.get('screen');
    return dim.height >= dim.width;
  };

  Dimensions.addEventListener('change', () => {
    setIsPortrait(
      checkIfPortrait() ? true : false
    );
  });

  const HandlerCom = ({ element }) => {

    const openMail = async (mail: string) => {
      try {
        const suppoted = await Linking.canOpenURL(`mailto:${mail}`)
        if (suppoted) {
          await Linking.openURL(`mailto:${mail}`)
        }
      } catch (err) {
        alert(`Can't open mail app`)
      }
    }

    const openTel = async (phoneNumber: string) => {
      try {
        const suppoted = await Linking.canOpenURL(`tel:${phoneNumber}`)
        if (suppoted) {
          await Linking.openURL(`tel:${phoneNumber}`)
        }
      } catch (err) {
        alert(`Can't open mail app`)
      }
    }

    const openSms = async (phoneNumber: string) => {
      try {
        const suppoted = await Linking.canOpenURL(`sms:${phoneNumber}`)
        if (suppoted) {
          await Linking.openURL(`sms:${phoneNumber}`)
        }
      } catch (err) {
        alert(`Can't open mail app`)
      }
    }

    return (
      <View style={styles.header}>
        <TouchableOpacity onPress={() => openMail(element.email)}>
          <View style={{ alignItems: "center" }}>
            <Feather name="mail" size={26} color={Colors.oiqia} />
            <Text>{t("OWNER_MAIL")}</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => openSms(element?.phoneNumber)}>
          <View style={{ alignItems: "center" }}>
            <MaterialCommunityIcons name="message-processing-outline" size={26} color={Colors.oiqia} />
            <Text>{t("OWNER_MSG")}</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => openTel(element.phoneNumber)}>
          <View style={{ alignItems: "center" }}>
            <Feather name="phone" size={26} color={Colors.oiqia} />
            <Text>{t("OWNER_CALL")}</Text>
          </View>
        </TouchableOpacity>
      </View>
    )
  }

  function renderActions(props: Readonly<ActionsProps>) {
    return (
      <Actions
        {...props}
        options={{
          ['Camera']: takePicture,
          ['Gallery']: pickImage,
          ['Cancel']: () => { },
        }}
      //onSend={args => console.log("args", args)}
      />
    )
  }

  if (isAllowed) {
    return (
      <SafeAreaView style={typo.mainBackground}>

        <ImageBackground source={BG_PATH} style={typo.background}>
          <StatusBar style="dark" />
          <GiftedChat
            showUserAvatar={true}
            renderSend={props => <SendButton {...props} />}
            scrollToBottom
            placeholder={t("PLACEHOLDER_INPUT")}
            messages={Conversation}
            onSend={sendText}
            user={USER}
            renderActions={renderActions}
            messagesContainerStyle={{ backgroundColor: Colors.bgCard }}
          />
          <Modal
            visible={CameraOpen}
            onRequestClose={() => setCameraOpen(false)}
          >
            <CameraScreen closeCamera={() => setCameraOpen(false)} isChat={true} sendPicture={sendPicture} isPortrait={isPortrait} />
          </Modal>
        </ImageBackground>
      </SafeAreaView>
    );
  } else {
    return (
      <SafeAreaView style={typo.mainBackground}>
        <ImageBackground source={BG_PATH} style={typo.background}>
          <StatusBar style="dark" />
          <ScrollView>
            <View style={typo.container}>
              <Text style={typo.titleInfo}>{t("OWNER_TITLE")}</Text>
              {ownerData.map((element, index) => (
                <View style={styles.box} key={index}>
                  <Text style={{ fontWeight: 'bold' }}>{t("OWNER_FIRSTNAME")}: <Text style={typo.body}>{element?.firstName}</Text></Text>
                  <Text style={{ fontWeight: 'bold' }}>{t("OWNER_LASTNAME")}: <Text style={typo.body}>{element?.lastName}</Text></Text>
                  <Text style={{ fontWeight: 'bold' }}>{t("OWNER_EMAIL")}: <Text style={typo.body}>{element?.email}</Text></Text>
                  <Text style={{ fontWeight: 'bold' }}>{t("OWNER_PHONE")}: <Text style={typo.body}>{element?.phoneNumber}</Text></Text>
                  <HandlerCom element={element} />
                </View>
              ))}
            </View>
          </ScrollView>
        </ImageBackground>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  box: {
    marginBottom: 15,
    backgroundColor: "#ffffffe6",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.36,
    shadowRadius: 6.68,
    elevation: 11,
    padding: 10,
  },
  "center-space": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  header: {
    marginTop: 10,
    display: "flex",
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  container: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "black"
  },
})


export default observer(ChatIncident);