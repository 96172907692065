/* eslint-disable @typescript-eslint/no-var-requires */
import { StackNavigationProp } from "@react-navigation/stack";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import * as Clipboard from "expo-clipboard";

import { MaterialIcons } from '@expo/vector-icons';

import {
  View,
  Text,
  ImageBackground,
  SafeAreaView,
  ScrollView,
  RefreshControl,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from "react-native";
import { Title, Button } from "react-native-paper";

import { MaterialCommunityIcons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';

//import Button from "../Button";
import { AppStackParamList } from "../../navigation/ParamList/AppStackParamList";
import { TabsParamList } from "../../navigation/ParamList/TabsParamList";
import { storeTypes } from "../../store";
import StoreContext from "../../store/Context";
import { alertMessage } from "../../utils/Alert";

import styles from "./styles";
import typo from "../../Theme/Typography";
import Gallery from "./Gallery";
import AttachmentPdf from "./AttachmentPdf";
import { gql, useLazyQuery } from "@apollo/client";

import IconDocs from "../../assets/newDesign/icons/icon_docs.svg";
import Colors from "../../Theme/Colors";
import Queries from "../../api/queries";
import WelcomePopUp from "../WelcomePopUp";
import OiqiaModal from "../OiqiaModal/OiqiaModal";
import { useIsFocused } from "@react-navigation/native";


type BookingsScreenNavigationProp = StackNavigationProp<
  TabsParamList & AppStackParamList,
  "Door"
>;

interface BookingsScreenProps extends storeTypes {
  navigation: BookingsScreenNavigationProp;
}

const BG_PATH = require("../../assets/newDesign/background-portrait.png");

//attachment query
const GET_ATTACHMENT_INFO = gql`
  query getAttachmentInfo($bookingId: String!) {
    getAttachmentInfo(bookingId: $bookingId) {
      appartId
      pictures {
        title
        container
        name
        type
      }
      welcomeNotes {
        title
        container
        name
        type
      }
      manuals {
        title
        container
        name
        type
      }
      rules {
        title
        container
        name
        type
      }
    }
  }
`;

interface ValueType {
  type: String,
  text: String
}

interface BedroomType {
  name: String,
  value: Array<ValueType>
}

interface AppartmentCaractType {
  access: Array<ValueType>,
  kitchen: Array<ValueType>,
  bathroom: Array<ValueType>,
  salon: Array<ValueType>,
  outside: Array<ValueType>,
  comfort: Array<ValueType>,
  bedroom: Array<BedroomType>,
  description: String,
  wifiName: String,
  wifiCode: String,
  extraInfo: String
}

interface DropDownType {
  list: ValueType[],
  title: String,
  icons: String
}

const Booking: React.FC<BookingsScreenProps> = ({ navigation }) => {

  const [isRefreshingBooking, setIsRefreshingBooking] = useState(false);

  const isfocused = useIsFocused()

  useEffect(() => {
    if (isfocused) {
      context.booking.refreshBookingInfos()
    }
  }, [isfocused]);

  const [lockStateLoading, setLockStateLoading] = useState(false);
  const [isCopied, setCopied] = useState(false)

  const { t, i18n } = useTranslation();

  const context = useContext(StoreContext);
  const authContext = context?.auth;
  const booking = context?.booking?.getBooking();
  const { bookingId = "" } = authContext.getUserStore();

  const { bookingIdOiqia } = authContext.getBookingStore();

  const queries = new Queries()

  const [nbOfCaract, setNbofCaract] = useState(0)
  const [appartmentCaract, setAppartmentCaract] = useState({
    access: [],
    kitchen: [],
    bathroom: [],
    salon: [],
    outside: [],
    comfort: [],
    bedroom: [],
    description: "",
    wifiCode: "",
    extraInfo: ""
  })

  const [loadAttachments, attachmentObject] = useLazyQuery(GET_ATTACHMENT_INFO, {
    variables: { bookingId: bookingIdOiqia },
  });

  const goToMaps = () => context?.booking?.generateIntenaryToApt();

  const displayErr = (_err?: Error) => {
    const title = t("UNKOWN_ERROR_TITLE");
    const message = t("UNKOWN_ERROR_DESCRIPTION");
    const color = "danger";

    alertMessage(title, message, color);
  };

  const getNbOfElement = (elements: AppartmentCaractType) => {
    let count = 0;
    for (const [key, value] of Object.entries(elements)) {
      if (key != "description" && key != "wifiCode" && key != "extraInfo" && key != "__typename") {
        if (key != "bedroom") {
          count += value.length;
        } else {
          value.map((element: BedroomType) => {
            count += element.value.length;
          })
        }
      }
    }
    setNbofCaract(count)
    return (count)
  }

  const getAppartCaract = async () => {
    try {
      const appartCaract = await queries.getAppartmentCaract(bookingIdOiqia, i18n.language)
      //console.log(appartCaract.data.getAppartmentCaract)
      getNbOfElement(appartCaract.data.getAppartmentCaract)
      setAppartmentCaract(appartCaract.data.getAppartmentCaract)
    } catch (err) {
      console.log("graphql Error")//JSON.stringify(err, null, 2)
    }
  }

  const copyWifiCode = (text: string) => {
    Clipboard.setString(text);
    setCopied(true)
  }

  //Attachment Info
  useEffect(() => {
    //console.log("bookingId...", bookingIdOiqia);
    getAppartCaract();
    loadAttachments();
  }, [i18n.language]);

  const { extra = "", street = "", streetNumber = "", zipCode = "", city = "", country = "" } = booking.address;


  const DropDown = ({ list, title, icons }: DropDownType) => {

    const [isOpen, setOpen] = useState(false)

    return (
      <View>
        <TouchableOpacity
          onPress={() => setOpen(!isOpen)}
        >
          <View style={style.spaceBet}>
            <View style={style.container}>
              <MaterialIcons name={icons} size={27} color={Colors.oiqia} style={{ marginRight: 7 }} />
              <Title style={typo.category}>{title}</Title>
            </View>
            {!isOpen ?
              <MaterialIcons name="keyboard-arrow-down" size={27} color={Colors.oiqia} />
              :
              <MaterialIcons name="keyboard-arrow-up" size={27} color={Colors.oiqia} onPress={() => setOpen(false)} />
            }
          </View>
        </TouchableOpacity>
        {isOpen &&
          <View style={style.containerDrop}>
            {list.map(element =>
              <View key={element.type + element.text} style={style.box}>
                <Text style={typo.body}>{element.text}</Text>
              </View>
            )}
          </View>
        }
      </View>
    )
  }

  return (
    <SafeAreaView style={typo.mainBackground}>
      <ImageBackground source={BG_PATH} style={typo.background}>
        {/* <OiqiaModalInfo titleText={t("COPIED")} bodyText={t("TEXT_COPIED")} isOpen={isCopied} onClose={() => setCopied(false)}/> */}
        <OiqiaModal
          titleText={t("COPIED")}
          bodyText={<Text>{t("TEXT_COPIED")}</Text>}
          isOpen={isCopied}
          onClose={() => setCopied(false)}
          firstButtonData={{
            text: t('VALIDATE'),
            callback: () => setCopied(false),
          }}
        />
        {/* <View style={modalStyle.centeredView}>
          <Modal
            animationType="slide"
            transparent={true}
            visible={isCopied}
            onRequestClose={() => {
              setCopied(false);
            }}>
            <View style={modalStyle.centeredView}>
              <View style={modalStyle.modalView}>
                <Text style={modalStyle.modalTitle}>{t("COPIED")}</Text>
                <Text style={modalStyle.modalText}>{t("TEXT_COPIED")}</Text>
                <Pressable
                  style={[modalStyle.button, modalStyle.buttonClose]}
                  onPress={() => setCopied(false)}>
                  <Text style={modalStyle.textStyle}>{t("VALIDATE")}</Text>
                </Pressable>
              </View>
            </View>
          </Modal>
        </View> */}
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={isRefreshingBooking}
              onRefresh={() => {
                context.booking
                  .refreshBookingInfos()
                  .finally(() => setIsRefreshingBooking(false));
              }}
            />
          }
        >
          <WelcomePopUp navigation={navigation} />
          <View style={typo.container}>

            <Text style={typo.titleInfo}>{t("INFO_TITLE")}</Text>

            <View style={typo.resCard}>

              <Text style={typo.h2}>{t("RESERVATION_REF")} </Text>

              <View style={styles.codeWrap}>
                <Text style={styles.code}> {bookingId} </Text>
              </View>

              <View style={styles.column}>
                <View style={styles.dateInfo}>
                  <View style={styles.underline}>
                    <Text style={typo.h3}>{t("ARRIVAL")} :</Text>
                  </View>

                  <Text style={typo.body}>{moment(booking?.arrival).format("DD/MM/YYYY")}</Text>
                  <Text style={typo.body}>{booking?.checkIn}</Text>

                </View>
                <View style={styles.dateInfo}>
                  <View style={styles.underline}>
                    <Text style={typo.h3}>{t("DEPARTURE")} :</Text>
                  </View>

                  <Text style={typo.body}>{moment(booking?.departure).format("DD/MM/YYYY")}</Text>
                  <Text style={typo.body}>{booking?.checkOut}</Text>

                </View>

              </View>
              {(appartmentCaract.wifiCode.length > 0 && appartmentCaract.wifiName.length > 0) &&
                <View style={styles.column}>
                  <View style={styles.dateInfo}>
                    <View style={styles.underline}>
                      <Text style={typo.h3}>{t("WIFINAME")} :</Text>
                    </View>
                    <Text style={typo.body}>{appartmentCaract.wifiName}</Text>
                  </View>
                  <View style={styles.dateInfo}>
                    <View style={styles.underline}>
                      <Text style={typo.h3}>{t("WIFICODE")} :</Text>
                    </View>
                    <TouchableOpacity
                      onPress={() => copyWifiCode(appartmentCaract.wifiCode)}
                    >
                      <Text style={typo.body}>{appartmentCaract.wifiCode}</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              }
              {appartmentCaract.description.length > 0 &&
                <View>
                  <View style={styles.underline}>
                    <Text style={typo.h3}>{t("CHECKOUT_DESCRIPTION")}</Text>
                  </View>
                  <Text style={typo.body}>{appartmentCaract.description}</Text>
                </View>
              }
              {appartmentCaract.extraInfo.length > 0 &&
                <View style={style.marginTop}>
                  <View style={styles.underline}>
                    <Text style={typo.h3}>{t("EXTRAINFO")}</Text>
                  </View>
                  <Text style={typo.body}>{appartmentCaract.extraInfo}</Text>
                </View>
              }

              {/* END of date infor */}
              {Platform.OS !== "ios" &&
                <View style={styles.underlineDot}></View>
              }

              <Text style={typo.h2}>{t("ADDRESS")} :</Text>
              <View style={styles.wrap10}>
                <Text style={typo.body}>{streetNumber}, {street}</Text>
                {extra != "" && <Text style={typo.body}>{extra}</Text>}
                <Text style={typo.body}>{`${zipCode} ${city}`}</Text>
                <Text style={typo.body}>{`${country}`}</Text>
              </View>


              <View>
                <Button
                  compact={true}
                  mode="contained"
                  color="#D71D9A"
                  onPress={goToMaps}
                >{t("DIRECTIONS")}</Button>
              </View>
            </View>

          </View>

          {/*         CATEGORIES PART */}
          <View style={typo.catCard}>
            {/* PHOTOS */}
            {attachmentObject?.data?.getAttachmentInfo?.pictures?.length > 0 && (
              <View >
                <View style={typo.underlineCat}>
                  {Platform.OS == "web" ?
                    <FontAwesome name="picture-o" size={24} color={Colors.oiqia} />
                    :
                    <IconDocs width={25} height={25} style={typo.icons}></IconDocs>
                  }
                  <Title style={typo.category}>{t("ATTACHMENT_PICTURES")}</Title>
                </View>
                <Gallery pictures={attachmentObject?.data?.getAttachmentInfo?.pictures} />
              </View>
            )}
            {/* MANUELS */}
            {attachmentObject?.data?.getAttachmentInfo?.manuals?.length > 0 && (
              <View >
                <View style={typo.underlineCat}>
                  {Platform.OS == "web" ?
                    <MaterialCommunityIcons name="file-document-outline" size={24} color={Colors.oiqia} />
                    :
                    <IconDocs width={25} height={25} style={typo.icons}></IconDocs>
                  }
                  <Title style={typo.category}>{t("ATTACHMENT_MANUALS")}</Title>
                </View>
                <AttachmentPdf
                  documents={attachmentObject?.data?.getAttachmentInfo?.manuals}
                />
              </View>
            )}

            {/* RULES */}
            {attachmentObject?.data?.getAttachmentInfo?.rules?.length > 0 && (
              <View >
                <View style={typo.underlineCat}>
                  {Platform.OS == "web" ?
                    <MaterialCommunityIcons name="file-document-outline" size={24} color={Colors.oiqia} />
                    :
                    <IconDocs width={25} height={25} style={typo.icons}></IconDocs>
                  }
                  <Title style={typo.category}>{t("ATTACHMENT_RULES")}</Title>
                </View>
                <AttachmentPdf
                  documents={attachmentObject?.data?.getAttachmentInfo?.rules}
                />
              </View>
            )}

            {/* WELOCOME NOTE */}
            {attachmentObject?.data?.getAttachmentInfo?.welcomeNotes?.length > 0 && (
              <View >
                <View style={typo.underlineCat}>
                  {Platform.OS == "web" ?
                    <MaterialCommunityIcons name="file-document-outline" size={24} color={Colors.oiqia} />
                    :
                    <IconDocs width={25} height={25} style={typo.icons}></IconDocs>
                  }
                  <Title style={typo.category}>{t("ATTACHMENT_WELCOME_NOTES")}</Title>
                </View>
                <AttachmentPdf
                  documents={attachmentObject?.data?.getAttachmentInfo?.welcomeNotes}
                />
              </View>
            )}
            {nbOfCaract > 0 &&
              <View style={typo.underlineCat}>
                {/* <IconDocs width={25} height={25} style={typo.icons}></IconDocs> */}
                <Title style={typo.category}>{t("EQUIPMENT")}</Title>
              </View>
            }
            {appartmentCaract.kitchen.length > 0 &&
              <DropDown list={appartmentCaract.kitchen} key="kitchen" title={t("KITCHEN")} icons="kitchen" />
            }
            {/* bedroom */}
            {appartmentCaract.bedroom.map((bedroom: BedroomType, index) =>
              bedroom.value.length > 0 &&
              <DropDown list={bedroom.value} key={"bedroom" + index} title={`${t("BEDROOM")} ${(index + 1)}`} icons="king-bed" />

            )}
            {appartmentCaract.bathroom.length > 0 &&
              <DropDown list={appartmentCaract.bathroom} key="bathroom" title={t("BATHROOM")} icons="bathtub" />
            }
            {/* salon */}
            {appartmentCaract.salon.length > 0 &&
              <DropDown list={appartmentCaract.salon} key="salon" title={t("SALON")} icons="tv" />
            }
            {/* comfort */}
            {appartmentCaract.comfort.length > 0 &&
              <DropDown list={appartmentCaract.comfort} key="comfort" title={t("COMFORT")} icons="event-seat" />
            }
            {/* access */}
            {appartmentCaract.access.length > 0 &&
              <DropDown list={appartmentCaract.access} key="access" title={t("ACCESS")} icons="directions-car" />
            }
            {/* exterieur */}
            {appartmentCaract.outside.length > 0 &&
              <DropDown list={appartmentCaract.outside} key="outside" title={t("OUTSIDE")} icons="outdoor-grill" />
            }
          </View>
        </ScrollView>
      </ImageBackground>
    </SafeAreaView >
  );
};

const style = StyleSheet.create({
  Button: {
    backgroundColor: Colors.oiqia,
    borderRadius: 10,
    justifyContent: "center",
    color: "white",
    height: 44,
    width: '130%'
  },
  Buttonbox: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
  },
  text: {
    width: '90%',
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  box: {
    backgroundColor: "#ffffffe6",
    borderRadius: 10,
    margin: 2,
    padding: 6,
  },
  containerDrop: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 10
  },
  spaceBet: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderColor: Colors.black,
    borderBottomWidth: 0.5,
    marginLeft: 30,
    marginRight: 30,
    marginTop: 15
  },
  marginTop: {
    marginTop: 10
  }
});

export default observer(Booking);
