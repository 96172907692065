/* eslint-disable max-len */
import { base } from "./base";

const ru: base = {
    "QBOY": "OIQIA CAT",
    "OWNER": "ВЛАДЕЛЕЦ",
    "TITLE_POP": "Добро пожаловать в Oiqia traveler",
    "BUTTON_PASS": "перейти к",
    "BUTTON_LEAVE": "оставить",
    "WELCOME": "Добро пожаловать в приложение Oiqia Traveler!\n\nПожалуйста, внимательно прочитайте следующие инструкции для того, чтобы вы могли легко и эффективно использовать приложение.",
    "LOGIN_WELCOME_POP": "Чтобы воспользоваться Oiqia Traveler, пожалуйста, вставьте номер бронирования, присланный командой Oiqia, в соответствующее поле.\n\nВыберите свой язык общения.",
    "HOME_WELCOME": "С помощью Oiqia Traveler у вас есть доступ ко всей полезной информации о вашем жилье (адрес, коды доступа, руководства, приветственный буклет, правила проживания, код wifi и т.д.).",
    "ACCESS_WELCOME": "В Oiqia Traveler вы можете нажать на кнопку \"отпереть/запереть\", чтобы открыть/закрыть дверь здания и/или квартиры.\n\nВы также можете набрать код входа на цифровой клавиатуре, расположенной рядом с дверью квартиры.",
    "FORM_WELCOME": " Когда вы прибудете в место проживания, вы должны зарегистрироваться.\n\nЕсли все в порядке, убедитесь, что жилье находится в хорошем состоянии.\nЕсли возникнут какие-либо проблемы, пожалуйста, сообщите нам об этом. Этот шаг необходим в случае разногласий.\n\nКогда будете уходить, не забудьте выписаться!",
    "CHAT_WELCOME": "С помощью Oiqia Traveler вы можете общаться с нами напрямую через чат.\n\nВы также найдете контактные данные нашего менеджера по работе с клиентами Oiqia на случай непредвиденных обстоятельств.",
    "SETTING_WELCOME": "Oiqia Traveler общается на нескольких языках: выберите предпочтительный язык для использования приложения.\n\nВы также можете выйти из приложения.\n\nИли измените свое бронирование, если у вас есть\nнесколько бронирований в Oiqia.",
    "BOOKING_ID": "Идентификатор",
    "BOOKING_EMAIL": "Электронная почта",
    "LOGIN": "Войти",
    "LOGIN_WELCOME": "Пожалуйста, введите код, присланный для бронирования:",
    "POPUP_CHECKIN_TITLE": "Добро пожаловать в ваше расположение!",
    "POPUP_CHECKIN_DESCRIPTION": "Для того чтобы воспользоваться арендой, пожалуйста, заполните опись приспособлений во вкладке \"формы\".",
    "POPUP_CHECKIN_BUTTON": "Проведение проверки",
    "HOME": "Главная",
    "EVENTS": "События по соседству",
    "RESTAURANTS": "Рестораны поблизости",
    "ACCESS": "Доступ",
    "BOOKING": "Бронирование",
    "ACCESS_CODE": "Код доступа",
    "UNLOCK_DOOR": "Откройте дверь",
    "LOCK_DOOR": "Закройте дверь",
    "INFO_TITLE": "Найдите свой\nинформация об аренде",
    "RESERVATION_REF": "Ваш код бронирования :",
    "ADDRESS": "Адрес местонахождения",
    "ARRIVAL": "Прибытие",
    "DEPARTURE": "Отправление",
    "DIRECTIONS": "Рассчитать маршрут",
    "FROM": "с сайта",
    "TO": "на",
    "COPIED": "Код Wifi скопирован",
    "TEXT_COPIED": "Код wifi скопирован, и вы можете подключиться к wifi, вставив код",
    "EQUIPMENT": "Оборудование",
    "BEDROOM": "Номер",
    "KITCHEN": "Кухня",
    "BATHROOM": "Ванная комната",
    "SALON": "Салон",
    "OUTSIDE": "На открытом воздухе",
    "COMFORT": "Комфорт",
    "WIFINAME": "Название wifi",
    "WIFICODE": "Код Wifi",
    "EXTRAINFO": "Дополнительная информация",
    "FORMS": "Формы",
    "CHECKINFORM": "Форма заявки",
    "COMPLAINFORM": "Бланк о происшествии",
    "CHECKOUTFORM": "Форма выхода",
    "INCIDENT": "Поддержка",
    "ACCESS_OPEN_KEYPAD": "Чтобы открыть с помощью клавиатуры\nВведите только эти 6 цифр",
    "ACCESS_CLOSE_KEYPAD": "Чтобы закрыть с помощью клавиатуры",
    "ACCESS_CLOSE_KEY": "нажмите",
    "ACCESS_MANAGE_APP": "Или через приложение",
    "PLACEHOLDER_INPUT": "Задайте свой вопрос в службу поддержки Oiqia",
    "INITIAL_BOT_MESSAGE": "Привет, я Qboy. Чем я могу вам помочь?",
    "QBOY_PERTINENCE": "Привет еще раз, актуальны ли ответы Qboy?",
    "QBOY_PERTINENCE_SUCCESS": "Идеально!",
    "QBOY_PERTINENCE_FAIL": "Хорошо, мы свяжемся со службой поддержки, и они вам перезвонят.",
    "SETTINGS": "Параметры",
    "INFO_SETTING": "Настройте свои параметры",
    "CHANGE_RESERVATION": "Изменить бронирование",
    "LANGUAGE": "Язык",
    "HOW_TO_CONTACT": "Как бы вы хотели, чтобы с вами связались?",
    "CHOICE_LANG": "Выберите свой язык",
    "EMAIL": "Электронная почта",
    "NOTIF": "Push-уведомления",
    "SMS": "Sms",
    "RECO": "Получать рекомендации от Qboy",
    "LOGOUT": "Выход из системы",
    "WARNING": "Внимание",
    "HELP_TITLE": "Нужна помощь",
    "HELP_TEXT": "Показать",
    "ERROR_NETWORK": "Пожалуйста, проверьте подключение к Интернету.",
    "ACCESS_CODE_NOT_AVAILABLE_BEFORE_BOOKING_ARRIVAL": "Код доступа может быть выдан только после бронирования.",
    "ERROR_BOOKING_NOT_FOUND_TITLE": "Резервирование не найдено",
    "ERROR_BOOKING_NOT_FOUND_DESCRIPTION": "Предоставленные вами данные бронирования не совпадают ни с одним бронированием в нашей системе",
    "ERROR_CHAT_BOT_TITLE": "Техническая ошибка",
    "ERROR_CHAT_BOT_DESCRIPTION": "Возникла проблема. Пожалуйста, повторите попытку позже.",
    "ERROR_INCOMPLETE_FORM_TITLE": "Незаполненная форма",
    "ERROR_INCOMPLETE_FORM_DESCRIPTION": "Пожалуйста, заполните форму правильно.",
    "ERROR_ACCESS_CODE_TITLE": "Возникла проблема",
    "ERROR_ACCESS_CODE_DESCRIPTION": "Невозможно получить код доступа к дому.",
    "UNKOWN_ERROR_TITLE": "Возникла неожиданная проблема",
    "UNKOWN_ERROR_DESCRIPTION": "Возникла непредвиденная проблема. Пожалуйста, повторите попытку позже.",
    "CHECKIN_FORM_FIRSTNAME_LABEL": "Имя",
    "CHECKIN_FORM_LASTNAME_LABEL": "Имя",
    "CHECKIN_FORM_EMAIL_LABEL": "Электронная почта",
    "CHECKIN_FORM_RESERVATION_ID_LABEL": "Номер резервирования",
    "CHECKIN_FORM_PHONE_LABEL": "Телефон",
    "CHECKIN_FORM_ARRIVAL_LABEL": "Прибытие",
    "CHECKIN_FORM_DEPARTURE_LABEL": "Отправление",
    "CHECKIN_FORM_ADULTS_LABEL": "Взрослые",
    "CHECKIN_FORM_CHILDREN_LABEL": "Дети",
    "CHECKIN_FORM_CHECKBOX_TITLE_LABEL": "Соответствует ли жилье вашим ожиданиям?",
    "CHECKIN_FORM_CHECKBOX_YES_LABEL": "Да",
    "CHECKIN_FORM_CHECKOBOX_NO_LABEL": "Нет",
    "CHECKIN_FORM_SUBMIT_BUTTON": "Отправить",
    "CHECKIN_FORM": "Форма заявки",
    "CHECKIN_FORM_HEADER": "форма заявки",
    "CHECKIN_TITLE": "Регистрация\nформа заявки",
    "CHECKIN_DESC_TITLE": "Спасибо, что заглянули:",
    "CHECKIN_DESC": "Проверьте правильность приведенной ниже информации и подтвердите статус проживания.\n\nЭта регистрация будет служить справочным документом, пожалуйста, заполняйте ее внимательно.",
    "CHECKIN_DESC_PB_TITLE": "Пожалуйста, укажите возникшую проблему:",
    "CHECKIN_DESC_PB": "Опишите проблему, можно приложить одну или несколько фотографий.",
    "CHECKIN_POPUP_TITLE": "Вы должны подтвердить свое присутствие в жилом помещении, чтобы подтвердить регистрацию заезда",
    "CHECKIN_POPUP_SUBTITLE": "PS: За любой ущерб, обнаруженный в жилье после заселения, ответственность несете вы.",
    "CHECKIN_POPUP_EARLY": "Вы можете подтвердить регистрацию только после начала бронирования",
    "CHECKOUT_FORM": "Форма выхода",
    "CHECKOUT_TITLE": "Выезд\nформа выхода",
    "CHECKOUT_SUBTITLE": "СПАСИБО, ЧТО ПРОВЕРИЛИ",
    "CHECKOUT_DESC": "Пришло время выписаться, поставив галочку ниже.\n\nНекоторые из ваших ответов - это ваша ответственность, мы заранее благодарим вас за то, что вы делаете это серьезно.\n\nНаконец, не забудьте оставить\nпомещение таким, каким бы вы хотели его найти.",
    "CHECKOUT_FORM_VERY_UNSATISFACTORY": "Очень неудовлетворительно",
    "CHECKOUT_FORM_UNSATISFACTORY": "неудовлетворительно",
    "CHECKOUT_FORM_NEUTRE": "Ни удовлетворительно, ни неудовлетворительно",
    "CHECKOUT_FORM_SATISFACTORY": "Удовлетворительно",
    "CHECKOUT_FORM_VERY_SATISFACTORY": "Очень удовлетворительно",
    "CHECKOUT_FORM_RATING_APPART": "Какова ваша общая оценка арендуемого жилья?",
    "CHECKOUT_FORM_RATING_PROPERTY": "Как бы вы оценили чистоту арендованного жилья?",
    "CHECKOUT_FORM_RATING_OIQIA": "Как вы оцениваете автономное прибытие через подключенный замок?",
    "CHECKOUT_DESCRIPTION_TITLE": "Есть ли у вас какие-либо комментарии по поводу вашего пребывания?",
    "CHECKOUT_DESCRIPTION": "Описание",
    "CHECKOUT_FORM_ASK_LEAVE": "Вы собираетесь покинуть арендованное жилье.\nНаходится ли он в том же состоянии, что и в момент вашего приезда?",
    "CHECKOUT_COMENTARY_TITLE": "Пожалуйста, опишите свой ответ в приведенном ниже поле для комментариев.",
    "CHECKOUT_COMENTARY": "Пожалуйста, сообщайте о любых проблемах или повреждениях в квартире. Вы также можете оставить нам комментарий о своем пребывании!",
    "CHECKOUT_PICTURE": "Вы можете сфотографировать возникшую проблему (проблемы) ниже.",
    "CHECKOUT_QCM_ASK": "Наконец, мы рассчитываем, что вы будете уважать помещения и участвовать в том, чтобы сделать сообщество краткосрочной аренды более приятным и надежным.",
    "CHECKOUT_QCM_HEADER": "Отмечая галочкой ниже, вы подтверждаете:",
    "CHECKOUT_QCM_EMPTY": "Нечего делать",
    "CHECKOUT_ENDING": "Прежде чем подтвердить выезд, вся команда Oiqia\nкоманда желает вам счастливого пути домой.\nВаше удовлетворение является нашим приоритетом, пожалуйста, не стесняйтесь возвращаться к нам с\nлюбыми\nкомментариями о вашем пребывании.",
    "CHECKOUT_THANKS": "Мы будем рады приветствовать вас снова в Oiqia!",
    "CHECKOUT_PROBLEM": "Были ли у вас какие-либо проблемы во время вашего пребывания?",
    "CHECKOUT_TODOLIST": "Контрольный список",
    "CHECKOUT_POPUP_TITLE": "Вы собираетесь подтвердить выезд, когда дата вашего отъезда назначена на",
    "CHECKOUT_POPUP_TIME": "à",
    "CHECKOUT_POPUP_SUBTITLE": "PS: Вы будете нести ответственность за любой ущерб, обнаруженный в жилье после подтверждения выезда.",
    "COMPLAINT_FORM_HEADER": "Форма жалобы",
    "COMPLAINT_FORM_PROBLEM": "Введите проблему",
    "COMPLAINT_FORM_UPLOAD_BUTTON": "Добавить фотографию",
    "COMPLAINT_FORM_IMG_BUTTON": "Галерея изображений",
    "COMPLAINT_FORM_CAMERA_BUTTON": "Сфотографировать",
    "COMPLAINT_FORM_CANCEL_BUTTON": "Отмена",
    "COMPLAINT_FORM_SUBMIT_BUTTON": "Сохранить",
    "EDIT_COMPLAINT_LABEL": "Описание проблемы",
    "EDIT_COMPLAINT_BUTTON": "Редактировать",
    "DECLARE_PROBLEM": "Сообщить о проблеме",
    "DECLARERED_PROBLEM": "Сообщается о проблеме",
    "EDIT_COMPLAINT_IMAGE": "Изображение инцидента(ов)",
    "EDIT_DESCRIPTION": "Есть ли у вас отзывы об автономном прибытии?",
    "OWNER_TITLE": "В случае возникновения проблем\nСвязаться с владельцем",
    "OWNER_FIRSTNAME": "Имя",
    "OWNER_LASTNAME": "Имя",
    "OWNER_EMAIL": "Почта",
    "OWNER_PHONE": "Тел",
    "OWNER_CALL": "Звоните на",
    "OWNER_MSG": "Сообщение",
    "OWNER_MAIL": "Электронная почта",
    "MSG_PERMISSION_LIBRARY": "Извините, но нам нужны разрешения для доступа к вашему каталогу.",
    "MSG_PERMISSION_CAMERA": "Извините, но нам нужны разрешения для доступа к вашей камере.",
    "UPDATE_COMPLAINT_FORM_HEADER": "Обновление формы жалобы",
    "UPDATE_COMPLAINT_FORM_PROBLEM": "Введите проблему",
    "UPDATE_COMPLAINT_FORM_UPLOAD_BUTTON": "Добавить фотографию",
    "UPDATE_COMPLAINT_FORM_IMG_BUTTON": "Галерея изображений",
    "UPDATE_COMPLAINT_FORM_CAMERA_BUTTON": "Сфотографировать",
    "UPDATE_COMPLAINT_FORM_CANCEL_BUTTON": "Отмена",
    "UPDATE_COMPLAINT_FORM_SUBMIT_BUTTON": "Сохранить",
    "APARTMENT_DOOR_DESCRIPTION": "Описание",
    "APARTMENT_DOOR_DOOR_TYPE": "Тип двери",
    "APARTMENT_DOOR_DEVICE_TYPE": "Тип устройства",
    "APARTMENT_DOOR_DIGIT_CODE": "Digicode",
    "APARTMENT_DOOR_MANUAL_CODE": "Код доступа",
    "APARTMENT_DOOR_DOOR_NAME": "Имя двери Oiqia",
    "APARTMENT_DOOR_BUTTON_UNLOCK": "Разблокировать",
    "APARTMENT_DOOR_BUTTON_LOCK": "Замок",
    "APARTMENT_DOOR_TITLE": "Инструкции по доступу к вашему дому",
    "APPARTMENT_DOOR_STATE": "Cтатус",
    "BUTTON_POPUP_TITLE_DOOR": "Что вы хотите делать?",
    "BUTTON_POPUP_TITLE": "Вы хотите разблокировать?",
    "BUTTON_POPUP_TITLE_LOCK": "Вы хотите заблокировать?",
    "BUTTON_POPUP_YES": "Да",
    "BUTTON_POPUP_YESS": "Да",
    "BUTTON_POPUP_NO": "Нет",
    "CHECKIN_DONE_TITLE": "Дата заезда",
    "CHECKOUT_DONE_TITLE": "Дата выезда",
    "CHECKIN_HISTORY_BUTTON": "Регистрация",
    "CHECKOUT_HISTORY_BUTTON": "Проверьте",
    "CHECKOUT_ADD_COMPLAINT": "Добавить жалобу",
    "COMPLAINTS_HISTORY_TITLE": "Описание проблемы",
    "COMPLAINTS_HISTORY_LABEL_PROBLEM": "Описание",
    "COMPLAINTS_HISTORY_LABEL_IMAGE": "Фотографии",
    "ALERT_CHECKIN_DONE": "Регистрация успешно завершена",
    "ALERT_PHONE_MANDATORY": "Неверный номер телефона, например, +33 06 01 02 03 04",
    "ALERT_PROBLEM": "Введите проблему",
    "TOAST_SUCCESS_": "Жалоба успешно добавлена",
    "HEADER_RESERVATION": "Главная",
    "HEADER_HOME": "Доступ",
    "HEADER_SETTINGS": "Параметры",
    "HEADER_CHECKINFORM": "Форма управления",
    "HEADER_COMPLAINTFORM": "Постановка проблемы",
    "HEADER_UPDATE_COMP_FORM": "Редактирование проблемы",
    "HEADER_HISTORY_CHECKIN": "История регистрации",
    "HEADER_HISTORY_CHECKOUT": "История выезда",
    "BUTTON_YES": "Да",
    "BUTTON_NO": "не",
    "MODAL_TEXT": "Вы хотите удалить...",
    "ALERT_FIRSTNAME_MANDATORY": "Имя и фамилия обязательны",
    "ALERT_LASTNAME_MANDATORY": "Фамилия обязательна",
    "ALERT_EMAIL_MANDATORY": "Ваш e-mail недействителен",
    "ALERT_ADULT_MANDATORY": "Взрослые обязательны",
    "SET_PHONE_MANDATORY": "Телефонный номер с кодом города +33 ...",
    "ATTACHMENT_PICTURES": "Фотографии",
    "ATTACHMENT_RULES": "Правила",
    "ATTACHMENT_MANUALS": "Руководства",
    "ATTACHMENT_WELCOME_NOTES": "Приветственные записки",
    "ERROR_NETWORK_TITLE": "Ошибка сети!",
    "HELP": "Помогите мне",
    "VALIDATE": "Подтвердить",
    "CANCEL": "Отмена",
    "MODAL_TITLE_PICTURE": "Фотографирование",
    "MODAL_BODY_PICTURE": "Фотографию можно взять из галереи или из камеры.",
    "ERROR": "Ошибка ...",
    "MISSING_DATA_ERROR": "Пожалуйста, предоставьте описание проблемы и хотя бы одну фотографию.",
    "MSG_PERMISSION_CAMERA_TITLE": "Отказ в разрешении",
    "SELECT_LANGAGE": "Ваш язык :",
    "CLOSE": "Закрыть",
    "ERROR_NETWORK_HELPER": "Закройте приложение, отключите устройство от сети, снова подключите его и повторите попытку.",
};

export default ru;
