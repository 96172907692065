/*eslint max-len: ["error", { "code": 300 }]*/
import GQLClient from "../../config/graphqlClient";
import {
  Booking, CheckInData, BookingOiqiaQl, PropertyLock, AppartDoors,
  QueryGetBookingByIdAndEmailArgs, QueryGetBookingByIdArgs,
  QueryGetCheckInArgs, QueryGetOiqiaSmartLockInfoArgs,
  QueryGetBookingByExtIdArgs, QueryGetNukiSmartLockInfoArgs, QueryGetAppartementDoorsArgs,
  CheckOutData, QueryGetCheckOutArgs, BookingStepResult, QueryGetBookingStatusArgs,
  OwnerData,
  QueryGetOwnerDataArgs,
  AppartmentCaractInfo,
  QueryGetAppartmentCaractArgs
} from "../../types/types";

import {
  GET_BOOKING_BY_ID_AND_EMAIL,
  GET_BOOKING_BY_ID, GET_BOOKING_BY_EXT_ID,
  GET_CHECKINDATA,
  GET_PREVIOUS_COMPLAINTS,
  GET_NUKI_INFO, GET_APARTMENT_DOOR_LOCKS,
  GET_OIQIA_SMARTLOCKINFO,
  GET_CHECKOUTDATA,
  GET_BOOKING_STEP,
  GET_OWNER_DATA,
  GET_APPART_CARACT
} from "./index.definitions";

export default class Queries {

  private client = GQLClient.getInstance()?.getApolloClientInstance();

  getBookingByIdAndEmail(email: string, bookingId: string) {
    return this.client
      .query<
        { getBookingByIdAndEmail: Booking },
        QueryGetBookingByIdAndEmailArgs
      >(
        {
          query: GET_BOOKING_BY_ID_AND_EMAIL,
          variables: {
            email,
            bookingId
          }
        }
      );
  }

  getNukiSmartLockInfo(smartLockId: number) {
    // console.log("*****************before gaphal ql request test2");
    return this.client
      .query<
        { getNukiSmartLockInfo: PropertyLock },
        QueryGetNukiSmartLockInfoArgs
      >(
        {
          query: GET_NUKI_INFO, variables: {
            smartLockId
          },
          fetchPolicy: "no-cache"
        }
      );
  }

  getBookingStatus(bookingId: string) {
    return this.client
      .query<
        { getBookingStatus: BookingStepResult },
        QueryGetBookingStatusArgs
      >(
        {
          query: GET_BOOKING_STEP,
          variables: {
            bookingId
          },
          fetchPolicy: "no-cache"
        }
      )
  }

  getBookingByExtId(bookingExtId: string) {
    return this.client
      .query<
        { getBookingByExtId: BookingOiqiaQl },
        QueryGetBookingByExtIdArgs
      >(
        {
          query: GET_BOOKING_BY_EXT_ID,
          variables: {
            bookingExtId
          },
          fetchPolicy: "no-cache"
        }
      );
  }

  getOwnerData(bookingId: string) {
    return this.client
      .query<
        { getOwnerData: OwnerData },
        QueryGetOwnerDataArgs
      >(
        {
          query: GET_OWNER_DATA,
          variables: {
            bookingId
          }
        }
      );
  }

  getBookingById(bookingId: string) {
    return this.client
      .query<
        { getBookingById: Booking },
        QueryGetBookingByIdArgs
      >(
        {
          query: GET_BOOKING_BY_ID, variables: {
            bookingId
          }
        }
      );
  }

  //get checkinData
  getCheckIn(bookingId: string) {

    return this.client
      .query<
        { getCheckIn: CheckInData },
        QueryGetCheckInArgs
      >(
        {
          query: GET_CHECKINDATA, variables: {
            bookingId
          },
          fetchPolicy: "no-cache"
        }

      );
  }

  //get Previous complaints
  getPreviousComplaints(bookingId: string) {
    return this.client
      .query<
        { getPreviousComplaints: Booking },
        QueryGetBookingByIdArgs
      >(
        {
          query: GET_PREVIOUS_COMPLAINTS, variables: {
            bookingId
          }
        }
      );
  }

  //get apartment door locks
  getAppartementDoors(bookingId: string) {
    return this.client
      .query<
        { getAppartementDoors: AppartDoors },
        QueryGetAppartementDoorsArgs
      >(
        {
          query: GET_APARTMENT_DOOR_LOCKS, variables: {
            bookingId
          },
          fetchPolicy: "no-cache"
        }

      );
  }

  //getOiqiaSmartlockInfo

  getOiqiaSmartLockInfo(bookingId: string, doorDeviceId: string) {
    // console.log(bookingId,doorDeviceId)
    return this.client
      .query<
        { getOiqiaSmartLockInfo: PropertyLock },
        QueryGetOiqiaSmartLockInfoArgs
      >(
        {
          query: GET_OIQIA_SMARTLOCKINFO, variables: {
            bookingId, doorDeviceId
          },
          fetchPolicy: "no-cache"
        });
  }

  // get checkout info
  getCheckOut(bookingId: string, language: string) {
    return this.client
      .query<{ getCheckOut: CheckOutData }, QueryGetCheckOutArgs>({
        query: GET_CHECKOUTDATA, variables: {
          bookingId,
          language,
        },
        fetchPolicy: "no-cache"
      });
  }

  getAppartmentCaract(bookingId: string, language: string) {
    return this.client
    .query<{ getAppartmentCaract: AppartmentCaractInfo }, QueryGetAppartmentCaractArgs>({
      query: GET_APPART_CARACT, variables: {
        bookingId, language
      },
      fetchPolicy: "no-cache"
    })
  }

}


