import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  Fragment,
  useRef,
} from "react";

import { TouchableOpacity } from "react-native-gesture-handler";

import { Entypo } from "@expo/vector-icons";

import {
  StyleSheet,
  SafeAreaView,
  View,
  ScrollView,
  Text,
  Image,
  Modal,
} from "react-native";

import { TextInput, Button, Card } from "react-native-paper";
import { useTranslation } from "react-i18next";
import ImageViewer from "react-native-image-zoom-viewer";

import typo from "./../../Theme/Typography";


export default function DisplayExistingComplain({ navigation, checkInData, readOnly, onRefresh }) {
  const [modalConfig, setModalConfig] = useState({
    isVisible: false,
    currentIndex: 0,
  });

  const { t, i18n } = useTranslation();

  const openModal = (index: number) => {
    setModalConfig({
      isVisible: true,
      currentIndex: index,
    });
  };

  // close modal to display image  edit complain
  const closeModal = () => {
    setModalConfig({
      isVisible: false,
      currentIndex: 0,
    });
  };


  return (
    <View style={typo.catCard}>

      <View style={typo.underlineCat}>
        <Text style={typo.category}>{t("DECLARERED_PROBLEM")}</Text>
      </View>

      <View style={styles.col}>
        <TextInput
          style={{ width: "auto" }}
          multiline
          disabled
          numberOfLines={5}
          value={checkInData?.problem?.description}
          label={t("EDIT_COMPLAINT_LABEL")}
          mode="outlined"
        />
      </View>

      <SafeAreaView>
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          {checkInData?.problem?.image.map((ele: any, i: number) => (
            <Card
              style={styles.imageContainer}
              key={ele}
              onPress={() => openModal(i)}
            >
              <Image style={styles.image} source={{ uri: ele }} />
            </Card>
          ))}
        </ScrollView>
        <SafeAreaView style={styles.modal}>
        <Modal visible={modalConfig.isVisible} transparent={true}>
          <ImageViewer
            enableSwipeDown={true}
            index={modalConfig.currentIndex}
            imageUrls={checkInData?.problem?.image.map(
              (img: string, index: number) => {
                return {
                  url: img,
                  props: { source: { uri: img } }
                };
              },
            )}
            renderHeader={() => (
              <SafeAreaView>
                <TouchableOpacity onPress={closeModal}>
                  <Entypo
                    //style={{ top: 7 }}
                    name="cross"
                    onPress={closeModal}
                    size={60}
                    color="white"
                  />
                </TouchableOpacity>
              </SafeAreaView>
            )}
          ></ImageViewer>
        </Modal>
        </SafeAreaView>

        {(!readOnly) &&

          <Button
            style={typo.oiqiaButton}
            mode="contained"
            onPress={() => {
              navigation.navigate("UpdateComplaintForm", {
                problem: checkInData?.problem,
                onRefresh: onRefresh
              });
            }}>{t("EDIT_COMPLAINT_BUTTON")}</Button>
        }


      </SafeAreaView>



    </View>
  )

}


const styles = StyleSheet.create({

  problemWrapper: {
    flex: 1,
    justifyContent: "center",

    borderColor: "#D71D9A",
    borderWidth: 2,
    borderRadius: 20,
    marginRight: 10,
  },
  modal: {
    flex: 1,
  },
  plainte: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",

    alignItems: "flex-start",
  },
  buttonEdit: {},

  titlePrb: {
    marginTop: 25,
    paddingLeft: 15,
  },
  container: {
    flex: 1,
    paddingTop: 0,
  },
  formWrappe: {
    flex: 1,
    padding: 10,
  },
  imageContainer: {
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 5,
    marginRight: 10,
    marginBottom: 15,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  image: {
    margin: 5,
    width: 200,
    height: 150,
    borderWidth: 1,
    borderRadius: 1,
    borderColor: "white",
  },
  pageTitle: {
    textAlign: "center",
    marginTop: 5,
    width: "100%",
  },
  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    height: "auto",
    alignItems: "flex-start",
  },
  col: {
    flex: 1,
    padding: 10,
    marginBottom: 6,
  },
  selectionTouch: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    height: 52,
    alignItems: "center",
    textAlign: "center",
    borderRadius: 10,
    borderColor: "black",
    borderWidth: 0.5,
    padding: 10,
    margin: 10,
  },

  selection: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",

  },

  card: {
    width: "100%"
    //marginBottom: 35,
  },
  cards: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",

    alignItems: "flex-start",
  },
  adressCard: {
    padding: 15,
    width: "100%",
    borderColor: "#eae8e1",
    borderWidth: 4,
    borderRadius: 20,
    marginBottom: 30,
  },
  desctitle: {
    fontSize: 17,
    fontWeight: "bold",
  },
  descbody: {
    fontSize: 15,
    color: "#808080",
    margin: 4,
  },
  title: {
    textAlign: "center",
  },

  phoneInput: {
    borderColor: "#7A7A7A",
    borderWidth: 1,
    borderRadius: 10,
    padding: 16,
    backgroundColor: "#F7F7F7",
  },
});