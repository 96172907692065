import React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import Login from "../screens/Auth/Login";

import { AuthStackParamList } from "./ParamList/AuthStackParamList";

const Stack = createStackNavigator<AuthStackParamList>();

function AuthStack() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Login" component={Login} />
    </Stack.Navigator>
  );
}

export default AuthStack;
