/*eslint max-len: ["error", { "code": 300 }]*/
import i18next from "i18next";

import GQLClient from "../../config/graphqlClient";
import { Payload, MutationIssueAccessCodeArgs, Booking, Result, ChatIncident, CheckOutData, Problem, CheckoutQuestion, MutationCheckOutArgs, ProblemInput, CheckoutQuestionInput, CheckOutInput, MutationPushTokenNotifArgs } from "../../types/types";

import { ALERT_CUSTOMER_SERVICE, ASK_CHAT_BOT, ISSUE_ACCESS_CODE, OPEN_INTRATONE_DOOR, LOCK_DOOR, UNLOCK_DOOR, CHECKIN_NOW, OPEN_DOOR, CLOSE_DOOR, UPLOAD_IMAGE, SET_INCIDENT_MSG, ADD_CHECKOUT, PUSH_TOKEN_NOTIF } from "./index.definitions";

export type ChatResponse = { askChatBot: string | null };
export type ChatVars = { askChatBotInput: { text: string, bookingId: string, languageCode: string } };

export default class Mutations {
  private client = GQLClient.getInstance()?.getApolloClientInstance();


  askChatBot(text: string, bookingId: string) {

    return this.client
      .mutate<ChatResponse, ChatVars>({
        mutation: ASK_CHAT_BOT,
        variables: {
          askChatBotInput: {
            text,
            bookingId,
            languageCode: i18next.language,
          }
        },
      });
  }

  issueAccessCode(bookingId: string) {
    return this.client
      .mutate<{ issueAccessCode: Payload }, MutationIssueAccessCodeArgs>({
        mutation: ISSUE_ACCESS_CODE, variables: {
          bookingId
        }
      });
  }

  openIntratoneDoor(bookingId: string, appartementDoorId: string) {
    return this.client
      .mutate<{ openIntratone: Payload }, { appartementDoorId: string, bookingId: string }>({
        mutation: OPEN_INTRATONE_DOOR, variables: {
          bookingId, appartementDoorId
        }
      });
  }

  alertCustomerService(bookingId: string) {
    return this.client
      .mutate<{ alertCustomerService: boolean }, MutationIssueAccessCodeArgs>({
        mutation: ALERT_CUSTOMER_SERVICE, variables: {
          bookingId
        }
      });
  }


  lockDoor(smartLockId: number) {
    return this.client
      .mutate<{ lockDoor: Payload }, { smartLockId: string }>({
        mutation: LOCK_DOOR, variables: {
          smartLockId: `${smartLockId}`
        }
      });
  }

  unlockDoor(smartLockId: number) {
    return this.client
      .mutate<{ unlockDoor: Payload }, { smartLockId: string }>({
        mutation: UNLOCK_DOOR, variables: {
          smartLockId: `${smartLockId}`
        }
      });
  }

  //update checkin data

  checkInNow(bookingId: string, firstName: string, lastName: string, email: string, phone: number, adults: number, children: number, isSatisfied: boolean) {
    const data = {
      bookingId: bookingId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      adults: adults,
      children: children,
      isSatisfied: isSatisfied
    }
    //console.log("+,,,++++++++++inside mutation++++++",data);
    return this.client
      .mutate<{ checkInNow: Result }>({
        mutation: CHECKIN_NOW,
        variables: data
      });
  }

  addCheckOut(formValue: CheckOutInput) {
    return this.client
      .mutate<{ addCheckOut: Result }>({
        mutation: ADD_CHECKOUT,
        variables: formValue
      })
  }


  //open door
  openDoor(bookingId: string, doorDeviceId: string) {
    //  console.log("open door......",bookingId,doorDeviceId)
    return this.client
      .mutate<{ openDoor: Payload }, { doorDeviceId: string, bookingId: string }>({
        mutation: OPEN_DOOR, variables: {
          bookingId, doorDeviceId
        }
      });
  }

  closeDoor(bookingId: string, doorDeviceId: string) {
    //  console.log("close door....",bookingId,doorDeviceId)
    return this.client
      .mutate<{ openDoor: Payload }, { doorDeviceId: string, bookingId: string }>({
        mutation: CLOSE_DOOR, variables: {
          bookingId, doorDeviceId
        }
      });
  }

  //image
  uploadComplaintImage(bookingId: string, image: string) {
    return this.client
      .mutate<{ uploadComplaintImage: string }>({
        mutation: UPLOAD_IMAGE, variables: {
          bookingId, image
        }
      });
  }

  // Chat meassage incident

  setIncidentMsg(ChatIncident: ChatIncident) {

    return this.client
      .mutate<{ setIncidentMsg: Result }>({
        mutation: SET_INCIDENT_MSG,
        variables: { ChatIncident }
      })
  }

  pushTokenNotif(token: string, bookingId: string, lang: string) {
    return this.client
      .mutate<{ pushTokenNotif: Result }>({
        mutation: PUSH_TOKEN_NOTIF,
        variables: {
          token,
          bookingId,
          lang
        }
      })
  }

}
