import React from "react";
import { Modal, View, Text, Pressable, StyleSheet } from "react-native";
import { Ionicons } from '@expo/vector-icons';

interface ButtonData {
    text: any;
    callback: () => any;
    fontColor?: string;
    backgroundColor?: string;
}

export interface Props {
    titleText: any
    bodyText?: any
    isOpen: boolean
    onClose: () => void
    firstButtonData: ButtonData;
    secondButtonData?: ButtonData;
}

export const OiqiaModal = ({ titleText, bodyText = "", firstButtonData, secondButtonData, isOpen, onClose }: Props) => {
    return (
        <View style={modalStyle.centeredView}>
            <Modal
                animationType="slide"
                transparent={true}
                visible={isOpen}
                onRequestClose={onClose}>
                <View style={modalStyle.centeredView}>
                    <View style={modalStyle.modalView}>
                        <View style={modalStyle.buttonCloseView}>
                            <Pressable
                                style={[
                                    modalStyle.buttonCloseModal,
                                ]}
                                onPress={onClose}
                            >
                                <Text style={{ color: 'white' }}>
                                    <Ionicons name="close-circle" size={30} color='#D71D9A' />
                                </Text>
                            </Pressable>
                        </View>
                        <Text style={modalStyle.modalTitle}>{titleText}</Text>
                        <View style={modalStyle.modalText}>{bodyText}</View>
                        <View style={modalStyle.buttonView}>
                            <Pressable
                                style={[
                                    modalStyle.button,
                                    modalStyle.pressable,
                                    modalStyle.buttonClose,
                                    (firstButtonData.backgroundColor) ?
                                        {
                                            backgroundColor: firstButtonData.backgroundColor
                                        }
                                        :
                                        {
                                            // apply style only if setted by props
                                        }
                                ]}
                                onPress={firstButtonData.callback}>
                                <Text style={[
                                    modalStyle.textStyle,
                                    firstButtonData.fontColor ?
                                        {
                                            color: firstButtonData.fontColor
                                        }
                                        :
                                        {

                                        }
                                ]}>{firstButtonData.text}</Text>
                            </Pressable>
                            {secondButtonData &&
                                <Pressable
                                    style={[
                                        modalStyle.button,
                                        modalStyle.pressable,
                                        modalStyle.buttonClose,
                                        (secondButtonData.backgroundColor) ?
                                            {
                                                backgroundColor: secondButtonData.backgroundColor
                                            }
                                            :
                                            {
                                                // apply style only if setted by props
                                            }
                                    ]}
                                    onPress={secondButtonData.callback}>
                                    <Text style={[
                                        modalStyle.textStyle,
                                        secondButtonData.fontColor ?
                                            {
                                                color: secondButtonData.fontColor
                                            }
                                            :
                                            {

                                            }
                                    ]}>{secondButtonData.text}</Text>
                                </Pressable>
                            }
                        </View>
                    </View>
                </View>
            </Modal>
        </View>
    )
}

const modalStyle = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        /* marginTop: 22, */
    },
    modalView: {
        position: 'relative',
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        paddingHorizontal: 35,
        paddingBottom: 20,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    pressable: {
        flex: 1
    },
    buttonClose: {
        backgroundColor: '#D71D9A',
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
        color: 'grey'
    },
    modalTitle: {
        fontSize: 24,
        marginBottom: 15,
        color: 'black'
    },
    buttonView: {
        justifyContent: "center",
        flexDirection: "row",
        gap: 3,
        alignItems: "center"
    },
    buttonCloseView: {
        alignSelf: "flex-end",
        marginVertical: 5
    },
    buttonCloseModal: {
        borderRadius: 50,
        marginTop: 10,
        elevation: 2,
    }
});

export default OiqiaModal;