import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import Booking from "./Booking";

import { useTranslation } from "react-i18next";
const Stack = createStackNavigator();

export interface BookingStackProps {}

const BookingStack: React.FC<BookingStackProps> = () => {
  const { t, i18n } = useTranslation();
  return (
    <Stack.Navigator>
       <Stack.Screen
        name="Reservations"
        component={Booking}
        options={{
          title: t("HEADER_RESERVATION"),
          headerStyle: {
            backgroundColor: "#D71D9A",
          },
          headerTintColor: "#fff",
          headerTitleStyle: {
            fontWeight: "bold",
          },
        }}
      /> 
    </Stack.Navigator>
  );
};

export default BookingStack;
