
export const Colors=  {
  black: "#000000",
  white: "#ffffff",
  lightGrey: "#DBDBDB",
  grey: "#9FA8B2",
  darkGrey: "#969696",
  accent: "#22E39D",
  purple: "#63D5FF",
  darkBlue: "#002649",
  orange: "#FFAB63",
  red: "#E02163",
  background: "#F7F7F7",
  bgCard: "#FFFFFFE6",
  bgCatCard:  "#F7F7F7F6",
  oiqia:"#D71D9A"
};

export default Colors;