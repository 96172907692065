/* eslint-disable @typescript-eslint/no-var-requires */
import { StatusBar } from "expo-status-bar";
import { observer } from "mobx-react-lite";
import React, { useContext, useState, useEffect } from "react";

import { StyleSheet, SafeAreaView, Platform, ImageBackground, KeyboardAvoidingView, View, Text, ScrollView } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useTranslation } from 'react-i18next';

import AsyncStorage from '@react-native-async-storage/async-storage';

import Button from "../../../components/Button";
import { AuthStackParamList } from "../../../navigation/ParamList/AuthStackParamList";
import Input from "../../../components/Input/Input";
import StoreContext from "../../../store/Context";

import styles from "./styles";

import typo from "../../../Theme/Typography";
import SwitchSelector from "react-native-switch-selector";
import { countrySelections } from "../../../Constants";
import CarouselTuto from "../../../components/CarouselTuto";
import OiqiaErrorModal from "../../../components/OiqiaModal/OiqiaErrorModal";

const langs = ["en", "fr"]
type LoginScreenNavigationProp = StackNavigationProp<AuthStackParamList, "Login">;

interface LoginScreenProps {
  navigation: LoginScreenNavigationProp;
}

const BG_PATH = require("../../../assets/newDesign/background-portrait-qboy.png");

function Login({ }: LoginScreenProps) {


  const [bookingId, setBookingId] = useState("");
  const [lang, setLang] = useState("fr");

  const context = useContext(StoreContext);
  const authContext = context?.auth;
  const { t, i18n } = useTranslation();

  const { carouselStatus } = authContext.getUserStore();

  const [qlError, setQlError] = useState<OiqiaErrorModalProps['qlError']>({
    networkError: "",
    message: "",
    visible: false,
  });

  const onCloseQlError = (): void => {
    setQlError({
      networkError: "",
      message: "",
      visible: false,
    });
  }

  const handleLogin = async () => {
    try {
      await context.auth.login(bookingId, i18n.language);

    } catch (err) {
      const { networkError = false, message = "" } = err;
      setQlError({
        visible: true,
        networkError,
        message
      });
      console.log(err);
    }
  }

  const fetchAppLang = async () => {
    try {
      const lang = await AsyncStorage.getItem('appLanguage');
      if (lang && langs.includes(lang)) {
        setLang(lang)
      } else // case of the first App launch async storage is not exit
        console.log("lang +++", lang);
      await setAppLang("fr");
    }
    catch (err) {
      console.log("getItem('appLanguage')", err)
    }
  }

  const setAppLang = async (value: string) => {
    try {
      console.log(value)
      setLang(value)
      i18n.changeLanguage(value);
      await AsyncStorage.setItem('appLanguage', value);
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    console.log("lang =>", i18n.language, t("POPUP_CHECKIN_TITLE"))
    fetchAppLang()
  }, [])

  return (
    <SafeAreaView style={typo.mainBackground}>
      <View style={styles.container}>
        <StatusBar style="dark" backgroundColor="#ED910C" />
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          behavior={"padding"}
          enabled={Platform.OS === "ios" ? true : false}
          keyboardVerticalOffset={Platform.OS === "ios" ? 40 : 0}
        >
          <OiqiaErrorModal onClose={onCloseQlError} qlError={qlError} />

          <ImageBackground source={BG_PATH} style={typo.background}>
            {carouselStatus &&
              <CarouselTuto />
            }
            <View style={styles.center}>


              {/* <CustomPicker /> */}
              <View style={[typo.resCard]}>
                <Text style={[styles.titleWelcome, { marginBottom: 10 }]}>{t("SELECT_LANGAGE")} </Text>
                <SwitchSelector
                  style={{ marginBottom: 10 }}
                  options={countrySelections}
                  initial={0}
                  onPress={(value) => setAppLang(value.internationalCode)}
                  returnObject={true}
                  buttonColor={"#d71d9a"}
                  hasPadding
                  valuePadding={0}
                  textStyle={{
                    fontSize: 30
                  }}
                  selectedTextStyle={{
                    fontSize: 30
                  }}
                />
                <Text style={[styles.titleWelcome, { marginBottom: 10 }]}>{t("LOGIN_WELCOME")} </Text>
                <Input
                  type="INPUT"
                  placeHolder={t("BOOKING_ID")}
                  onChangeText={(text: string) => setBookingId(text)}
                  keyboardType="number-pad"
                />

              </View>
              <View style={styles.butonWrapper}>
                <Button text={t("LOGIN")} onPress={handleLogin} style={styles.button} />
              </View>
              <View style={styles.butonWrapper}>
                <Button text={t("HELP_TITLE")} onPress={async () => await authContext.setCarouselStatus(true)} style={{ ...styles.button, backgroundColor: "#9C9B9Bae" }} />
              </View>
            </View>
          </ImageBackground>
        </KeyboardAvoidingView>
      </View>
    </SafeAreaView>
  );
}


const pickerSelectStyles = StyleSheet.create({
  iconContainer: {

  },
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 10,
    color: 'black',
    marginTop: 15,
    marginBottom: 20,
    //marginLeft: 31,

    //paddingRight:30,
    //paddingLeft: 10, // to ensure the text is never behind the icon
  },
  inputAndroid: {
    marginTop: 15,
    marginBottom: 20,
    paddingHorizontal: 10,
    paddingVertical: 12,
    fontSize: 16,
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 10,
    color: 'black',

    // paddingRight: 30, // to ensure the text is never behind the icon
  },
});

export default observer(Login);
