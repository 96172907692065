import { FontAwesome } from "@expo/vector-icons";
import React from "react";
import { View } from "react-native";
import { Send } from "react-native-gifted-chat";

const SendButton: React.FC = (props) => {
  return (
    <Send {...props}   >
      <View style={{ marginRight: 10, marginBottom: 15, marginTop: 5 }}>
        <FontAwesome name="send" size={24} color="black" />
      </View>
    </Send>
  );
};

export const SendImage: React.FC = (props) => {
  return (
    <Send {...props}   >
      <View style={{ marginRight: 10, marginBottom: 15, marginTop: 5 }}>
        <FontAwesome name="image" size={24} color="black" />
      </View>
    </Send>
  );
};

export default SendButton;
