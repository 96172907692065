import React, {
  useState,
  useContext,
  useEffect,
  Fragment,
  useRef,
} from "react";
import {
  Alert,
  StyleSheet,
  SafeAreaView,
  View,
  ScrollView,
  Text,
  ImageBackground,
  TouchableHighlight
} from "react-native";


import { TextInput, RadioButton, Title, Button } from "react-native-paper";

import moment from "moment";
import { useTranslation } from "react-i18next";

import Queries from "../../api/queries";
import Mutations from "../../api/mutations";
import StoreContext from "../../store/Context";
import DisplayExistingComplain from "./DisplayExistingComplain"

//import PhoneInput from "react-native-phone-number-input";
import PhoneInput from "react-native-phone-input";

import typo from "./../../Theme/Typography";
import CheckOut from "../CheckOutForm/CheckOut";
import OiqiaModal from "../OiqiaModal/OiqiaModal";
import OiqiaLoadingModal from "../OiqiaModal/OiqiaLoadingModal";
import OiqiaErrorModal, { OiqiaErrorModalProps } from "../OiqiaModal/OiqiaErrorModal";

export interface CheckInProps {
  navigation: any;
}

const BG_PATH = require("../../assets/newDesign/background-portrait.png");


// expression of valid email
const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const CheckInNew: React.FC<CheckInProps> = ({ navigation }) => {
  // State definition
  const [formData, setFormData] = useState<any>({
    isSatisfied: false,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    arrival: "",
    departure: "",
    adults: "",
    children: "",
    isCheckinDone: false,
  });

  const [loading, setIsloading] = useState(false);
  const [isAllowed, setAllowed] = useState(false)
  const [isVisible, setVisible] = useState(false)
  const [qlError, setQlError] = useState<OiqiaErrorModalProps['qlError']>({
    networkError: "",
    message: "",
    visible: false,
  });

  const onCloseQlError = (): void => {
    setQlError({
      networkError: "",
      message: "",
      visible: false,
    });
  }


  // Global defintion variable

  const { t, i18n } = useTranslation();
  let quries = new Queries();
  let mutations = new Mutations();
  const context = useContext(StoreContext);
  const authContext = context?.auth;
  const phoneRef = useRef(undefined);

  const { bookingIdOiqia, booking } = authContext.getBookingStore();
  const { bookingId = "" } = authContext.getUserStore();

  // Effect definition
  // initialisation of the data
  useEffect(() => {

    fetchGetCheckinData();

    if (new Date(`${booking?.arrival}T00:00`).getTime() < new Date().getTime()) {
      setAllowed(true)
    } else {
      setAllowed(false)
    }
  }, []);

  useEffect(() => {
    navigation.addListener('focus', () => {
      context.popUp.setCheckInStatePop(false)
    });
    navigation.addListener('blur', () => {
      context.popUp.setCheckInStatePop(true)
    })
    navigation.addListener('state', (e) => {
      context.popUp.setCheckInStatePop(false)
    })
  }, [navigation]);

  const getStatusBooking = async () => {
    try {
      const response = await quries.getBookingStatus(bookingIdOiqia)
      const bookingStatus = response.data.getBookingStatus.bookingStep;
      const isEntry = bookingStatus.filter(status => status['status'] == 10).length > 0 ? true : false
      return (isEntry)
    } catch (err) {
      const { networkError = false, message = "" } = err;
      setQlError({
        visible: true,
        networkError,
        message
      });
      console.log(err);
    }
  }

  // to refresh page on declaration probleme go back
  /*   useFocusEffect(
      useCallback(() => {
        // console.log("backend call.......");
        fetchGetCheckinDesc();
      }, [bookingIdOiqia]),
    ); */

  // load date from back end
  let fetchGetCheckinDesc = async () => {
    try {
      setIsloading(true);
      const response = await quries.getCheckIn(bookingIdOiqia);
      // console.log("QL getCheckIn", response);
      let newcheckinData = response?.data?.getCheckIn;
      //  console.log("QL getCheckIn", newcheckinData);
      setFormData({ ...formData, problem: newcheckinData.problem });
      // phoneInput.current?.setValue(dat.phone);
    } catch (err) {
      setIsloading(false);
      const { networkError = false, message = "" } = err;
      setQlError({
        visible: true,
        networkError,
        message
      });
      console.log(err);
    }
    setIsloading(false);
  };


  // load date from back end
  let fetchGetCheckinData = async () => {
    try {
      setIsloading(true);
      const response = await quries.getCheckIn(bookingIdOiqia);
      // console.log("QL getCheckIn FETCH", response);
      // setCheckInData(response?.data?.getCheckIn);
      let dat = response?.data?.getCheckIn;
      if (dat?.problem?.description) {
        dat.isSatisfied = false;
      }
      setFormData(dat);
      // phoneInput.current?.setValue(dat.phone);
    } catch (err) {
      setIsloading(false);
      const { networkError = false, message = "" } = err;
      setQlError({
        visible: true,
        networkError,
        message
      });
      console.log(err);
    }
    setIsloading(false);
  };

  // check data before submit
  const submitCheckinForm = async () => {
    // controle mandatory data

    //console.log("checkin data", formData)
    if (formData.firstName === "") {
      Alert.alert(t("ALERT_FIRSTNAME_MANDATORY"));
      return;
    }
    if (formData.lastName === "") {
      Alert.alert(t("ALERT_LASTNAME_MANDATORY"));
      return;
    }

    if (reg.test(formData.email) === false) {
      Alert.alert(t("ALERT_EMAIL_MANDATORY"));
      return;
    }

    if (formData.adults <= 0) {
      Alert.alert(t("ALERT_ADULT_MANDATORY"));
      return;
    }

    const correctValue = phoneRef.current?.isValidNumber(formData.phone);

    if (correctValue === false) {
      Alert.alert(t("ALERT_PHONE_MANDATORY"));
      return;
    }
    if (await getStatusBooking() && isAllowed) {
      await checkinNow();
      fetchGetCheckinData();
    } else {
      setVisible(true)
    }
  };


  //Update checkin data
  const updatedCheckin = async () => {
    const bookingId = bookingIdOiqia;
    const data = {
      bookingId: bookingIdOiqia,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      adults: Number(formData.adults),
      children: Number(formData.children),
      isSatisfied: formData.isSatisfied,
    };
    try {
      setIsloading(true);
      const updatedChekindata = await mutations.checkInNow(
        bookingId,
        data.firstName,
        data.lastName,
        data.email,
        data.phone,
        data.adults,
        data.children,
        data.isSatisfied,
      );

      const result = updatedChekindata?.data?.checkInNow;
      return result;

      // setIsloading(false);
    } catch (err) {
      setIsloading(false);
      const { networkError = false, message = "" } = err;
      setQlError({
        visible: true,
        networkError,
        message
      });
      console.log(err);
    }
    setIsloading(false);
  };
  //   console.log("checkinNow",fData);

  //update checkindata on submit form
  const checkinNow = async () => {
    //call updatedCheckin of the backend mutation

    try {
      const result = await updatedCheckin();
      //console.log("result", result);
      Alert.alert(t("ALERT_CHECKIN_DONE"));
      if (!formData.isSatisfied) {
        navigation.navigate('ChatIncident', { screen: "ChatIncidentScreen" });
      }
    } catch (err) {
      Alert.alert("Error", err);
    }
  };

  return (
    <SafeAreaView style={typo.mainBackground}>
      <ImageBackground source={BG_PATH} style={typo.background}>
        <OiqiaErrorModal onClose={onCloseQlError} qlError={qlError} />
        <OiqiaLoadingModal loading={loading} />
        <ScrollView>
          {!loading && !formData.isCheckinDone ?
            (
              <Fragment key={1}>
                {isAllowed ?
                  <OiqiaModal
                    titleText={t("WARNING")}
                    bodyText={
                      <View>
                        <Text>
                          {t("CHECKIN_POPUP_TITLE")}{"\n"}
                        </Text>
                        <Text style={{ fontStyle: 'italic', marginTop: 10 }}>
                          {t("CHECKIN_POPUP_SUBTITLE")}
                        </Text>
                      </View>
                    }
                    isOpen={isVisible}
                    onClose={() => setVisible(false)}
                    firstButtonData={{
                      text: t("CANCEL"),
                      callback: () => setVisible(false),
                    }}
                    secondButtonData={{
                      text: t("VALIDATE"),
                      callback: async () => {
                        await checkinNow();
                        fetchGetCheckinData();
                        setVisible(false)
                      },
                    }}
                  />
                  :
                  <OiqiaModal
                    titleText={t("CHECKIN_POPUP_EARLY")}
                    isOpen={isVisible}
                    onClose={() => setVisible(false)}
                    firstButtonData={{
                      text: t("CANCEL"),
                      callback: () => setVisible(false),
                    }}
                  />
                }
                <View style={typo.container}>
                  <Text style={typo.titleInfo}>{t("CHECKIN_TITLE")}</Text>

                  <View style={typo.resCard}>
                    <Text style={[typo.h3, { marginBottom: 10 }]}>{t("CHECKIN_DESC_TITLE")} </Text>
                    <Text style={typo.body}>{t("CHECKIN_DESC")} </Text>
                  </View>
                </View>

                <View style={typo.catCard}>
                  <View style={typo.underlineCat}>
                    {/* <IconForm width={25} height={25} style={typo.icons}></IconForm> */}
                    <Title style={typo.category}>{t("CHECKIN_FORM")}</Title>
                  </View>

                  <View style={styles.row}>
                    <View style={styles.col}>
                      <TextInput
                        value={bookingId}
                        disabled
                        label={t("CHECKIN_FORM_RESERVATION_ID_LABEL")}
                        mode="outlined"
                      />
                    </View>
                  </View>

                  <View style={styles.row}>
                    <View style={styles.col}>
                      <TextInput
                        value={formData?.firstName}
                        label={t("CHECKIN_FORM_FIRSTNAME_LABEL")}
                        mode="outlined"
                        onChangeText={e =>
                          setFormData((p: any) => {
                            return {
                              ...p,
                              firstName: e,
                            };
                          })
                        }
                      />
                    </View>
                    <View style={styles.col}>
                      <TextInput
                        value={formData.lastName}
                        label={t("CHECKIN_FORM_LASTNAME_LABEL")}
                        onChangeText={e =>
                          setFormData((p: any) => {
                            return {
                              ...p,
                              lastName: e,
                            };
                          })
                        }
                        mode="outlined"
                      />
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={styles.col}>
                      <TextInput
                        value={formData.email}
                        onChangeText={e =>
                          setFormData((p: any) => {
                            return {
                              ...p,
                              email: e.trim(),// to prenvent blank space at the end
                            };
                          })
                        }
                        label={t("CHECKIN_FORM_EMAIL_LABEL")}
                        mode="outlined"
                      />
                    </View>
                  </View>

                  <View style={styles.row}>
                    <View style={styles.col}>
                      <PhoneInput
                        ref={phoneRef}
                        defaultCountry="FR"
                        autoFormat={true}
                        style={styles.phoneInput}
                        initialValue={formData.phone}
                        textProps={{
                          placeholder: t("SET_PHONE_MANDATORY"),
                        }}
                        onChangePhoneNumber={(data: string) =>
                          setFormData({ ...formData, phone: data })
                        }
                      />
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={styles.col}>
                      <TextInput
                        value={formData.arrival}
                        disabled
                        label={t("CHECKIN_FORM_ARRIVAL_LABEL")}
                        mode="outlined"
                      />
                    </View>

                    <View style={styles.col}>
                      <TextInput
                        value={formData.departure}
                        disabled
                        label={t("CHECKIN_FORM_DEPARTURE_LABEL")}
                        mode="outlined"
                      />
                    </View>
                  </View>

                  <View style={styles.row}>
                    <View style={styles.col}>
                      <TextInput
                        value={String(formData.adults)}
                        onChangeText={e =>
                          setFormData((p: any) => {
                            return {
                              ...p,
                              adults: e ? Number(e) : 0,
                            };
                          })
                        }
                        label={t("CHECKIN_FORM_ADULTS_LABEL")}
                        mode="outlined"
                      />
                    </View>

                    <View style={styles.col}>
                      <TextInput
                        value={String(formData.children)}
                        onChangeText={e =>
                          setFormData((p: any) => {
                            return {
                              ...p,
                              children: e ? Number(e) : 0,
                            };
                          })
                        }
                        label={t("CHECKIN_FORM_CHILDREN_LABEL")}
                        mode="outlined"
                      />
                    </View>
                  </View>

                  <View style={typo.container}>
                    <Text style={typo.body}> {t("CHECKIN_FORM_CHECKBOX_TITLE_LABEL")}</Text>

                    <View style={styles.row}>
                      <TouchableHighlight style={styles.selectionTouch} onPress={() => setFormData({ ...formData, isSatisfied: true })}
                        underlayColor="#03dac433">
                        <View style={styles.selection}>
                          <Text style={typo.h3}>{t("CHECKIN_FORM_CHECKBOX_YES_LABEL")}</Text>
                          <RadioButton
                            value="yes"
                            status={formData.isSatisfied ? "checked" : "unchecked"}
                            onPress={() => setFormData({ ...formData, isSatisfied: true })}
                          />
                        </View>
                      </TouchableHighlight>

                      <TouchableHighlight style={styles.selectionTouch} onPress={() => setFormData({ ...formData, isSatisfied: false })}
                        underlayColor="#03dac433">
                        <View style={styles.selection}>
                          <Text style={typo.h3}>{t("CHECKIN_FORM_CHECKOBOX_NO_LABEL")}</Text>
                          <RadioButton
                            value="no"
                            status={formData.isSatisfied ? "unchecked" : "checked"}
                            onPress={() => setFormData({ ...formData, isSatisfied: false })}
                          />
                        </View>
                      </TouchableHighlight>
                    </View>


                    {/*       logic To declare a Problem or modify it      */}
                    {formData.isSatisfied === false &&
                      [ // If there no problem yet disclarer
                        !(formData?.problem?.description || formData?.problem?.image?.length > 0) ?
                          <View key={3}>
                            <Button
                              style={typo.oiqiaButton}
                              mode="contained"
                              onPress={() => {
                                navigation.navigate("UpdateComplaintForm",
                                  { problem: formData?.problem, onRefresh: fetchGetCheckinDesc });
                              }
                              }>
                              {t("DECLARE_PROBLEM")}</Button>
                          </View>
                          :

                          <DisplayExistingComplain
                            navigation={navigation}
                            checkInData={formData}
                            readOnly={false}
                            onRefresh={fetchGetCheckinDesc}
                          />

                      ]


                    }
                    <Button
                      style={typo.oiqiaButton}
                      mode="contained"
                      onPress={submitCheckinForm}
                    >
                      {t("CHECKIN_FORM_SUBMIT_BUTTON")}
                    </Button>
                  </View>
                </View>
              </Fragment>
            ) : (
              <Fragment key={2}>

                {formData.isCheckinDone && (
                  <View>
                    <View style={typo.container}>
                      <View style={typo.resCard}>
                        <Title style={typo.h3}>
                          {t("CHECKIN_DONE_TITLE")}{" "}
                          {moment(formData.arrival).format("DD-MM-YYYY")}
                        </Title>
                        <View style={styles.row}>
                          <View style={styles.col}>
                            <Button
                              style={{ marginBottom: 20 }}
                              mode="contained"
                              onPress={() => {
                                navigation.navigate("HistoryCheckinData", {
                                  formData: formData,
                                });
                              }}
                              color="#D71D9A"
                            >
                              {t("CHECKIN_HISTORY_BUTTON")}
                            </Button>
                          </View>
                        </View>
                      </View>
                    </View>
                    <CheckOut navigation={navigation} setIsloading={setIsloading} />
                  </View>
                )}
              </Fragment>
            )}

        </ScrollView>
      </ImageBackground>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  problemWrapper: {
    flex: 1,
    justifyContent: "center",

    borderColor: "#D71D9A",
    borderWidth: 2,
    borderRadius: 20,
    marginRight: 10,
  },
  plainte: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",

    alignItems: "flex-start",
  },
  buttonEdit: {},

  titlePrb: {
    marginTop: 25,
    paddingLeft: 15,
  },
  container: {
    flex: 1,
    paddingTop: 0,
  },
  formWrappe: {
    flex: 1,
    padding: 10,
  },
  imageContainer: {
    borderColor: "white",
    borderWidth: 1,
    borderRadius: 20,
    marginLeft: 10,
    marginTop: 10,
  },
  image: {
    left: 5,

    width: 200,
    height: 140,
    borderWidth: 1,
    borderRadius: 20,
    marginRight: 10,
    borderColor: "#ebeae4",
    marginBottom: 5,
  },
  pageTitle: {
    textAlign: "center",
    marginTop: 5,
    width: "100%",
  },
  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    height: "auto",
    alignItems: "flex-start",
  },
  col: {
    flex: 1,
    padding: 10,
    marginBottom: 6,
  },
  selectionTouch: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    height: 52,
    alignItems: "center",
    textAlign: "center",
    borderRadius: 10,
    borderColor: "black",
    borderWidth: 0.5,
    padding: 10,
    margin: 10,
  },

  selection: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",

  },

  card: {
    width: "100%"
    //marginBottom: 35,
  },
  cards: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",

    alignItems: "flex-start",
  },
  adressCard: {
    padding: 15,
    width: "100%",
    borderColor: "#eae8e1",
    borderWidth: 4,
    borderRadius: 20,
    marginBottom: 30,
  },
  desctitle: {
    fontSize: 17,
    fontWeight: "bold",
  },
  descbody: {
    fontSize: 15,
    color: "#808080",
    margin: 4,
  },
  title: {
    textAlign: "center",
  },

  phoneInput: {
    borderColor: "#7A7A7A",
    borderWidth: 1,
    borderRadius: 10,
    padding: 16,
    backgroundColor: "#F7F7F7",
  },
  text: {
    marginTop: -10,
    marginBottom: 15,
    width: '98%',
    alignItems: "center"
  },
  flexBoxButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  }
});

export default CheckInNew;
