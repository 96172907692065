import { StatusBar } from "expo-status-bar";
import { observer } from "mobx-react-lite";
import React, { useContext, useState, useEffect } from "react";
import { SafeAreaView, Text, View, StyleSheet, ScrollView, ImageBackground } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Button from "../../../components/Button";
import { TabsParamList } from "../../../navigation/ParamList/TabsParamList";
import { AppStackParamList } from "../../../navigation/ParamList/AppStackParamList";
import Header from "../../../components/Header";
import SwitchIndicator from "../../../components/SwitchIndicator";
import StoreContext from "../../../store/Context";
import { useTranslation } from 'react-i18next';

import styles from "./styles";
import typo from "../../../Theme/Typography";

import SwitchSelector from "react-native-switch-selector";

import { countrySelections } from "../../../Constants";
import CarouselTuto from "../../../components/CarouselTuto";

const langs = ["en", "fr"]

const BG_PATH = require("../../../assets/newDesign/background-portrait.png");

type SettingsScreenNavigationProp = StackNavigationProp<
  TabsParamList & AppStackParamList,
  "Settings"
>;

interface SettingsScreenProps {
  navigation: SettingsScreenNavigationProp;
}

const OIQIA_COLOR = "#D71D9A";


function Settings() {
  const [emailEnabled, setEmailEnabled] = useState(false);
  const [notifEnabled, setNotifEnabled] = useState(false);
  const [smsEnabled, setSmsEnabled] = useState(false);
  const [recommendationsEnabled, setrecommendationsEnabled] = useState(false);
  const [lang, setLang] = useState("");
  // const [language, setLanguage] = useState("");
  const context = useContext(StoreContext);
  const authContext = context?.auth;

  const { carouselStatus } = authContext.getUserStore();

  const logOut = () => authContext?.logout();
  const { t, i18n } = useTranslation();

  const fetchAppLang = async () => {
    try {
      const lang = await AsyncStorage.getItem('appLanguage');
      if (lang && langs.includes(lang)) {
        setLang(lang)
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const setAppLang = async (value: string) => {
    try {
      setLang(value)
      await AsyncStorage.setItem('appLanguage', value);
      i18n.changeLanguage(value)
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchAppLang()
  }, [])

  return (
    <SafeAreaView style={typo.mainBackground}>
      <ImageBackground source={BG_PATH} style={typo.background}>
        <ScrollView>
          {carouselStatus &&
            <View>
              <CarouselTuto />
            </View>
          }


          <View style={typo.container}>
            <Text style={typo.titleInfo}>{t("INFO_SETTING")}</Text>
            <View style={typo.resCard}>
              <Text style={[typo.category, { marginBottom: 10 }]}>{t("CHOICE_LANG")}</Text>
              <SwitchSelector
                style={{ marginTop: 10 }}
                options={countrySelections}
                initial={0}
                onPress={(value) => setAppLang(value.internationalCode)}
                returnObject={true}
                buttonColor={"#d71d9a"}
                hasPadding
                valuePadding={0}
                textStyle={{
                  fontSize: 30
                }}
                selectedTextStyle={{
                  fontSize: 30
                }}
              />

            </View>
            <View style={typo.resCard}>

              <Text style={[typo.category]}>{t("HELP_TITLE")}</Text>

              <View style={styles.buttonWrapper}>
                <Button
                  style={{ alignSelf: "center", backgroundColor: OIQIA_COLOR }}
                  text={t("HELP_TEXT")}
                  onPress={() => authContext.setCarouselStatus(true)}
                />
              </View>
            </View>
            <View style={typo.resCard}>

              <Text style={[typo.category]}>{t("CHANGE_RESERVATION")}</Text>

              <View style={styles.buttonWrapper}>
                <Button
                  style={{ alignSelf: "center", backgroundColor: OIQIA_COLOR }}
                  text={t("LOGOUT")}
                  onPress={logOut}
                />
              </View>
            </View>
          </View>
        </ScrollView>
      </ImageBackground>
    </SafeAreaView>
  );
}






const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 4,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: 'purple',
    borderRadius: 8,
    color: 'black',
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  iconContainer: {
    top: 20,
    right: 10,
  },
  placeholder: {
    color: 'purple',
    fontSize: 12,
    fontWeight: 'bold',
  }
});


export default observer(Settings);
