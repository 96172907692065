/* eslint-disable react-native/split-platform-components */
import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { StyleSheet, View, Text, Platform } from "react-native";
import { Title, Button } from "react-native-paper";
import { Audio } from 'expo-av';

import { AntDesign } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';

import Queries from "../../api/queries";
import Mutations from "../../api/mutations";

import typo from "./../../Theme/Typography";

import IconSas from "../../assets/newDesign/icons/icon_acces_sas.svg";
import IconPorte from "../../assets/newDesign/icons/icon_acces_porte.svg";
import IconCheck from "../../assets/newDesign/icons/icon_key.svg";
import IconUnlock from "../../assets/newDesign/icons/icon_unlock.svg";
import Colors from "../../Theme/Colors";
import OiqiaModal from "../OiqiaModal/OiqiaModal";

import { useIsFocused } from "@react-navigation/native";
import OiqiaErrorModal, { OiqiaErrorModalProps } from "../OiqiaModal/OiqiaErrorModal";

const DoorInfo = (props: any) => {

  const isFocused = useIsFocused();

  const { t, i18n } = useTranslation();
  let quries = new Queries();
  let mutations = new Mutations();

  const [status, setStatus] = useState<any>();
  const [isModalVisble, setIsModalVisible] = useState<any>(false);

  const [loading, setLoading] = useState(false);

  const [qlError, setQlError] = useState<OiqiaErrorModalProps['qlError']>({
    networkError: "",
    message: "",
    visible: false,
  });

  const onCloseQlError = (): void => {
    setQlError({
      networkError: "",
      message: "",
      visible: false,
    });
  }

  const getDoorStatus = async () => {
    if (props.door?.doorType === "INTRATONE-CALL") {
      setStatus({ "state": "Locked" });
      return;
    }
    // check if there is physical device OR
    if (props.door?.doorDeviceId === "") {

      return; // don't make the call to back end
    } else if (props.door?.deviceType === "Opener") {

      // in case of opener consider the state as Locked
      setStatus({ "state": "Locked" });

      return; // don't make the call to back end
    } else if (props.door?.doorType === "AUTRE") {
      // nothing its igloo door
      return;
    }

    try {
      if (props.bookingId && props.door.doorDeviceId) {
        const response = await quries.getOiqiaSmartLockInfo(
          props.bookingId,
          props.door?.doorDeviceId,
        );
        //console.log("apartment door..", response);
        setStatus(response?.data?.getOiqiaSmartLockInfo);
      }
    } catch (err) {
      const { networkError = false, message = "" } = err;
      setQlError({
        visible: true,
        networkError,
        message
      });
      console.log(err);
    }
  };

  const lockDoor = async () => {
    setIsModalVisible(false);
    try {
      setLoading(true);

      const response = await mutations.closeDoor(
        props.bookingId,
        props.door?.doorDeviceId,
      ).then(() =>
        setTimeout(async () => {
          playSound();
          setLoading(false);
        }, 4000)
      );
      //console.log("apartment door..", response);
      getDoorStatus();
    } catch (err) {
      setLoading(false);
      const { networkError = false, message = "" } = err;
      setQlError({
        visible: true,
        networkError,
        message
      });
    }
  };

  const openIntratoneDoor = async () => {
    setIsModalVisible(false);
    try {
      setLoading(true);

      const response = await mutations.openIntratoneDoor(
        props.bookingId,
        props.door?._id,
      ).then(() =>
        setTimeout(async () => {
          playSound();
          setLoading(false);
        }, 4000)
      );
    } catch (err) {
      setLoading(false);
      const { networkError = false, message = "" } = err;
      setQlError({
        visible: true,
        networkError,
        message
      });
    }
  };

  const unLockDoor = async () => {
    setIsModalVisible(false);
    setLoading(true);

    try {
      const response = await mutations.openDoor(
        props.bookingId,
        props.door?.doorDeviceId,
      ).then(() =>
        setTimeout(async () => {
          playSound();
          getDoorStatus();
          setLoading(false);
        }, 4000)
      );
      // console.log("apartment door..", response);

    } catch (err) {
      setLoading(false);
      const { networkError = false, message = "" } = err;
      setQlError({
        visible: true,
        networkError,
        message
      });
    }
  };

  useEffect(() => {
    if (isFocused) {
      getDoorStatus();
    }
  }, [isFocused]);

  async function playSound() {

    const { sound } = await Audio.Sound.createAsync(
      require('../../assets/audio/slow_spring_board_longer_tail.mp3')
    );

    await sound.playAsync();

  }

  const { description = "", oiqiaDoorName = "", digitCode = "", manualCode = "", doorType = "", textMultiDesc = {}, textMultiName = {} } = props.door;

  return (
    <React.Fragment>
      <OiqiaErrorModal onClose={onCloseQlError} qlError={qlError} />

      <View style={styles.col}>

        <View style={typo.resCard}>
          <View style={styles.rowTitle}>
            <View>
              <Title style={typo.h2}>
                {textMultiName[i18n.language]}
              </Title>
            </View>
            <View style={styles.iconWrapper}>
              {doorType == "SAS" && (
                Platform.OS == "web" ?
                  <FontAwesome5 name="building" size={24} color={Colors.oiqia} />
                  :
                  <IconSas width={25} height={25} ></IconSas>
              )}
              {doorType == "PORTE" && (
                status?.state != "" ? (
                  status?.state === "Locked" ?
                    <AntDesign name="lock" size={25} color={Colors.oiqia} />
                    :
                    <AntDesign name="unlock" size={25} color={Colors.oiqia} />
                ) : (
                  Platform.OS == "web" ?
                    <FontAwesome5 name="door-open" size={24} color={Colors.oiqia} />
                    :
                    <IconPorte width={25} height={25} ></IconPorte>
                )
              )}
              {doorType === "INTRATONE-CALL" && (
                Platform.OS === "web" ?
                  <FontAwesome5 name="phone" size={24} color={Colors.oiqia} />
                  :
                  <AntDesign name="unlock" size={25} color={Colors.oiqia} />
              )}
              {doorType == "AUTRE" && (
                Platform.OS == "web" ?
                  <FontAwesome5 name="key" size={24} color={Colors.oiqia} />
                  :
                  <IconCheck width={25} height={25} ></IconCheck>
              )}
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col}>
              {description != "" &&
                <View>
                  <Text style={typo.category}>{t("APARTMENT_DOOR_DESCRIPTION")} :</Text>
                  <Text style={[typo.body, { marginTop: 5, marginBottom: 5 }]}>{textMultiDesc[i18n.language]}</Text>
                </View>
              }

              {manualCode != "" &&
                <View>
                  <Text style={typo.category}>{t("APARTMENT_DOOR_MANUAL_CODE")} :</Text>
                  <Text style={styles.code}>{manualCode}</Text>
                </View>
              }

              {digitCode &&
                <View>
                  <Text style={typo.category}>{t("ACCESS_OPEN_KEYPAD")}: <Text style={styles.code}>{`${digitCode.toString()}`}</Text></Text>
                  <Text style={[typo.category, { marginTop: 10 }]}>{t("ACCESS_CLOSE_KEYPAD")}: <Text style={styles.code}>{t("ACCESS_CLOSE_KEY")} ◂</Text></Text>
                  <Text style={[typo.category, { marginTop: 10 }]}>{t("ACCESS_MANAGE_APP")}: </Text>
                </View>
              }
            </View>
          </View>


          {/*   DOOR BUTTON MANAGEMENT PART */}
          <View style={Platform.OS == "web" ? { ...styles.doorButton, marginTop: 30, marginBottom: -10 } : styles.doorButton}>
            {doorType != "AUTRE" &&
              <Button
                icon={() => {
                  if (Platform.OS != "web") {
                    return <IconUnlock width={20} height={20} ></IconUnlock>
                  } else {
                    return <AntDesign name="unlock" size={24} color="white" />
                  }
                }
                }
                mode="contained"
                style={typo.oiqiaButton}
                onPress={() => {
                  setIsModalVisible(true);
                }}
              >
                {doorType == "PORTE" ? `${t("APARTMENT_DOOR_BUTTON_UNLOCK")}/${t("APARTMENT_DOOR_BUTTON_LOCK")}` : (status?.state === "Locked" ? t("APARTMENT_DOOR_BUTTON_UNLOCK") : t("APARTMENT_DOOR_BUTTON_LOCK"))}
              </Button>}
          </View>

          {/* MODAL CONFIRMATION PART */}
          <View style={styles.row}>
            <View style={styles.col}>
              {doorType === "PORTE" ?
                <OiqiaModal
                  isOpen={isModalVisble}
                  onClose={() => setIsModalVisible(false)}
                  titleText={(t("BUTTON_POPUP_TITLE_DOOR"))}

                  firstButtonData={{
                    text: t("APARTMENT_DOOR_BUTTON_UNLOCK"),
                    callback: unLockDoor
                  }}
                  secondButtonData={{
                    text: t("APARTMENT_DOOR_BUTTON_LOCK"),
                    callback: lockDoor
                  }}
                />
                :
                <OiqiaModal
                  isOpen={isModalVisble}
                  onClose={() => setIsModalVisible(false)}
                  titleText={
                    (status?.state === "Locked"
                      ? t("BUTTON_POPUP_TITLE")
                      : t("BUTTON_POPUP_TITLE_LOCK"))
                  }

                  firstButtonData={
                    status?.state === "Locked" ?
                      {
                        callback: () => {
                          if (doorType === "INTRATONE-CALL") {
                            openIntratoneDoor();
                          } else {
                            unLockDoor()
                          }
                        },
                        text: t("BUTTON_POPUP_YES")
                      }
                      :
                      {
                        text: t("BUTTON_POPUP_YES"),
                        callback: lockDoor
                      }
                  }
                  secondButtonData={{
                    text: t("BUTTON_POPUP_NO"),
                    callback: () => setIsModalVisible(false)
                  }}
                />
              }
            </View>
          </View>
        </View>
      </View>
    </React.Fragment >
  );
};
const OIQIA_COLOR = "#D71D9A";

const styles = StyleSheet.create({
  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    height: "auto",
    alignItems: "flex-start",
  },
  doorButton: {
    marginTop: 14,
    marginBottom: 10,
    justifyContent: "center",
  },
  iconWrapper: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    padding: 5
  },
  titleWrapper: {
    width: "90%"
  },
  rowTitle: {
    /*    flex: 1, */
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    borderColor: "#0A0A0AE7",
    borderBottomWidth: 0.5,
    paddingBottom: 5,
    marginBottom: 10,
  },
  code: {
    fontSize: 18,
    color: OIQIA_COLOR,
    letterSpacing: 2,
    margin: 5,
    fontFamily: "RobotoBold"
  },
  tinyLogo: {
    margin: 20,
    width: 100,
    height: 100,
  },
  col: {
    /*     flex: 1, */
    /*     padding: 10, */
    // marginBottom: 5,
  },
  input: {
    marginBottom: 10,
  },
  title:
  {
    margin: 10,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "40%"
  },
  card: {

    padding: 10,
    width: "100%",
    borderColor: "#eae8e1",
    borderWidth: 4,
    borderRadius: 20,
    marginBottom: 10,

    flex: 1,
    // margin: 5,
    /*     borderColor: "#FAFAFA",
        borderWidth: 2,
        borderRadius: 10, */
  },
});

export default DoorInfo;
