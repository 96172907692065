/* eslint-disable max-len */
import { base } from "./base";

const en: base = {
  "QBOY": "CHAT OIQIA",
  "OWNER": "OWNER",
  'TITLE_POP': "Welcome to Oiqia traveller",
  "BUTTON_PASS": "Pass",
  "BUTTON_LEAVE": "Leave",
  "WELCOME": "Welcome to the Oiqia Traveler application!\n\nPlease read the following instructions carefully to make the use of the application easy and efficient.",
  "LOGIN_WELCOME_POP": "To use Oiqia Traveler, please insert the booking number sent by the Oiqia team in the relevant field.\n\nChoose your language of communication.",
  "HOME_WELCOME": "With Oiqia Traveler, you have access to all useful information about your accommodation (address, access codes, manuals, welcome booklet, house rules, wifi code, etc.).",
  "ACCESS_WELCOME": "With Oiqia Traveler, you can click on the « unlock/lock » button to open/close the building and/or apartment door.\n\nYou can also type the entry code on the numeric keypad located near the door of the apartment.",
  "FORM_WELCOME": " When you arrive in the accommodation, you must check in.\n\nIf all is well, confirm the general good condition of the accommodation.\nIf there are any problems, please let us know. This step is essential in case of disagreement.\n\nWhen you leave, don't forget to check out!",
  "CHAT_WELCOME": "With Oiqia Traveler, you can communicate directly with us via chat.\n\nYou will also find the contact details of our Oiqia customer manager in case of emergency.",
  "SETTING_WELCOME": "Oiqia Traveler communicates in several languages: choose your preferred language to use your application.\n\nYou can also log out of the application.\n\nOr change your reservation if you have several reservations in progress with Oiqia.",
  "BOOKING_ID": "Identifier",
  "BOOKING_EMAIL": "Email",
  "LOGIN": "Log in",
  "LOGIN_WELCOME": "Please enter the code sent for your reservation:",
  "POPUP_CHECKIN_TITLE": "Welcome to your rental!",
  "POPUP_CHECKIN_DESCRIPTION": "In order to enjoy your rental, thank you for completing the inventory of fixtures in the « forms » tab.",
  "POPUP_CHECKIN_BUTTON": "Do the checkin",
  "HOME": "Home",
  "EVENTS": "Events next door",
  "RESTAURANTS": "Restaurants nearby",
  "ACCESS": "Access",
  "BOOKING": "Reservation",
  "ACCESS_CODE": "Access code",
  "UNLOCK_DOOR": "Open the door",
  "LOCK_DOOR": "Close the door",
  "INFO_TITLE": "Find your\nrental information",
  "RESERVATION_REF": "Your reservation code :",
  "ADDRESS": "Rental address",
  "ARRIVAL": "Arrival",
  "DEPARTURE": "Departure",
  "DIRECTIONS": "Calculate the route",
  "FROM": "from",
  "TO": "to",
  "COPIED": "Wifi code copied",
  "TEXT_COPIED": "The wifi code has been copied and you can connect to the wifi by pasting the code",
  "EQUIPMENT": "Equipment",
  "BEDROOM": "Room",
  "KITCHEN": "Kitchen",
  "BATHROOM": "Bathroom",
  "SALON": "Salon",
  "OUTSIDE": "Exterior",
  "COMFORT": "Comfort",
  "WIFINAME": "Name of the wifi",
  "WIFICODE": "Wifi code",
  "EXTRAINFO": "Additional information",
  "FORMS": "Forms",
  "CHECKINFORM": "Entry form",
  "COMPLAINFORM": "Incident form",
  "CHECKOUTFORM": "Exit form",
  "INCIDENT": "Support",
  "ACCESS_OPEN_KEYPAD": "To open with the keypad\nEnter only these 6 numbers",
  "ACCESS_CLOSE_KEYPAD": "To close with the keypad",
  "ACCESS_CLOSE_KEY": "press",
  "ACCESS_MANAGE_APP": "Or via the application",
  "PLACEHOLDER_INPUT": "Ask your question to Oiqia support",
  "INITIAL_BOT_MESSAGE": "Hi, I'm Qboy. How can I help you?",
  "QBOY_PERTINENCE": "Hi again, are Qboy's answers relevant?",
  "QBOY_PERTINENCE_SUCCESS": "Perfect!",
  "QBOY_PERTINENCE_FAIL": "Okay, we'll contact customer service and they'll get back to you.",
  "SETTINGS": "Parameters",
  "INFO_SETTING": "Adjust your settings",
  "CHANGE_RESERVATION": "Change reservation",
  "LANGUAGE": "Language",
  "HOW_TO_CONTACT": "How would you like to be contacted?",
  "CHOICE_LANG": "Choose your language",
  "EMAIL": "Email",
  "NOTIF": "Push notifications",
  "SMS": "Sms",
  "RECO": "Receive recommendations from Qboy",
  "LOGOUT": "Log out",
  "WARNING": "Attention",
  "HELP_TITLE": "Need help",
  "HELP_TEXT": "Show me",
  "ERROR_NETWORK": "Please check your internet connection.",
  "ACCESS_CODE_NOT_AVAILABLE_BEFORE_BOOKING_ARRIVAL": "The access code can only be issued once the reservation has been made.",
  "ERROR_BOOKING_NOT_FOUND_TITLE": "Reservation not found",
  "ERROR_BOOKING_NOT_FOUND_DESCRIPTION": "The booking details you have provided do not match any booking in our system",
  "ERROR_CHAT_BOT_TITLE": "Technical error",
  "ERROR_CHAT_BOT_DESCRIPTION": "A problem has occurred. Please try again later.",
  "ERROR_INCOMPLETE_FORM_TITLE": "Incomplete form",
  "ERROR_INCOMPLETE_FORM_DESCRIPTION": "Please fill in the form correctly.",
  "ERROR_ACCESS_CODE_TITLE": "A problem has arisen",
  "ERROR_ACCESS_CODE_DESCRIPTION": "Unable to retrieve the access code to the house.",
  "UNKOWN_ERROR_TITLE": "An unexpected problem has arisen",
  "UNKOWN_ERROR_DESCRIPTION": "An unexpected problem has occurred. Please try again later.",
  "CHECKIN_FORM_FIRSTNAME_LABEL": "First name",
  "CHECKIN_FORM_LASTNAME_LABEL": "Name",
  "CHECKIN_FORM_EMAIL_LABEL": "Email",
  "CHECKIN_FORM_RESERVATION_ID_LABEL": "Reservation number",
  "CHECKIN_FORM_PHONE_LABEL": "Phone",
  "CHECKIN_FORM_ARRIVAL_LABEL": "Arrival",
  "CHECKIN_FORM_DEPARTURE_LABEL": "Departure",
  "CHECKIN_FORM_ADULTS_LABEL": "Adults",
  "CHECKIN_FORM_CHILDREN_LABEL": "Children",
  "CHECKIN_FORM_CHECKBOX_TITLE_LABEL": "Does the accommodation meet your expectations?",
  "CHECKIN_FORM_CHECKBOX_YES_LABEL": "Yes",
  "CHECKIN_FORM_CHECKOBOX_NO_LABEL": "No",
  "CHECKIN_FORM_SUBMIT_BUTTON": "Submit",
  "CHECKIN_FORM": "Entry form",
  "CHECKIN_FORM_HEADER": "entry form",
  "CHECKIN_TITLE": "Check-In\nentry form",
  "CHECKIN_DESC_TITLE": "Thank you for checking in:",
  "CHECKIN_DESC": "Check that the information below is correct and confirm the status of the accommodation.\n\nThis check-in will serve as a reference document, please fill it in carefully.",
  "CHECKIN_DESC_PB_TITLE": "Please indicate the problem encountered:",
  "CHECKIN_DESC_PB": "Describe the problem, you can add one or more pictures.",
  "CHECKIN_POPUP_TITLE": "You must confirm that you are present in the accommodation in order to validate the check-in",
  "CHECKIN_POPUP_SUBTITLE": "PS: Any damage found in the accommodation after check-in will engage your responsibility",
  "CHECKIN_POPUP_EARLY": "You can validate the check-in only when your reservation has started",
  "CHECKOUT_FORM": "Exit form",
  "CHECKOUT_TITLE": "Check-Out\ncheck-out form",
  "CHECKOUT_SUBTITLE": "THANK YOU FOR CHECKING OUT",
  "CHECKOUT_DESC": "It's time to check out by ticking the boxes below.\n\nSome of your answers engage your responsibility, we thank you in advance to do it seriously.\n\nFinally, don't forget to leave the premises as you would like to find them.",
  "CHECKOUT_FORM_VERY_UNSATISFACTORY": "Very unsatisfactory",
  "CHECKOUT_FORM_UNSATISFACTORY": "unsatisfactory",
  "CHECKOUT_FORM_NEUTRE": "Neither satisfactory nor unsatisfactory",
  "CHECKOUT_FORM_SATISFACTORY": "Satisfactory",
  "CHECKOUT_FORM_VERY_SATISFACTORY": "Very Satisfactory",
  "CHECKOUT_FORM_RATING_APPART": "What is your general appreciation of the rented accommodation?",
  "CHECKOUT_FORM_RATING_PROPERTY": "How would you rate the cleanliness of the rented accommodation?",
  "CHECKOUT_FORM_RATING_OIQIA": "What is your assessment of the autonomous arrival via the connected lock?",
  "CHECKOUT_DESCRIPTION_TITLE": "Do you have any comments about your stay?",
  "CHECKOUT_DESCRIPTION": "Description",
  "CHECKOUT_FORM_ASK_LEAVE": "You are about to leave the rented accommodation.\nIs it in the same condition as when you arrived?",
  "CHECKOUT_COMENTARY_TITLE": "Please provide details of your response in the « comments » space below.",
  "CHECKOUT_COMENTARY": "Thank you for reporting any problems or damages in the apartment. You are also free to leave us a comment about your stay!",
  "CHECKOUT_PICTURE": "You can take pictures of the problem(s) encountered below.",
  "CHECKOUT_QCM_ASK": "Finally, we count on you to respect the premises and participate in making the short-term rental community more pleasant and reliable.",
  "CHECKOUT_QCM_HEADER": "By checking the boxes below, you are affirming :",
  "CHECKOUT_QCM_EMPTY": "Nothing to do",
  "CHECKOUT_ENDING": "Before validating the check-out, the whole Oiqia team\nteam wishes you a safe journey home.\nYour satisfaction is our priority, do not hesitate to come back to you with any comments you may have about your stay.",
  "CHECKOUT_THANKS": "We look forward to welcoming you back to Oiqia's rentals!",
  "CHECKOUT_PROBLEM": "Did you have any problems during your stay?",
  "CHECKOUT_TODOLIST": "Checklist",
  "CHECKOUT_POPUP_TITLE": "You are about to validate the check out while your departure date is scheduled for",
  "CHECKOUT_POPUP_TIME": "à",
  "CHECKOUT_POPUP_SUBTITLE": "PS: Any damage found in the accommodation after the confirmation of the check out will engage your responsibility",
  "COMPLAINT_FORM_HEADER": "Complaint Form",
  "COMPLAINT_FORM_PROBLEM": "Enter the problem",
  "COMPLAINT_FORM_UPLOAD_BUTTON": "Add a photo",
  "COMPLAINT_FORM_IMG_BUTTON": "Gallery image",
  "COMPLAINT_FORM_CAMERA_BUTTON": "Take a picture",
  "COMPLAINT_FORM_CANCEL_BUTTON": "Cancel",
  "COMPLAINT_FORM_SUBMIT_BUTTON": "Save",
  "EDIT_COMPLAINT_LABEL": "Description of the problem",
  "EDIT_COMPLAINT_BUTTON": "Edit",
  "DECLARE_PROBLEM": "Report a problem",
  "DECLARERED_PROBLEM": "Problem reported",
  "EDIT_COMPLAINT_IMAGE": "Image of incident(s)",
  "EDIT_DESCRIPTION": "Do you have any feedback on the autonomous arrival?",
  "OWNER_TITLE": "In case of problems\nContact the owner",
  "OWNER_FIRSTNAME": "First name",
  "OWNER_LASTNAME": "Name",
  "OWNER_EMAIL": "Mail",
  "OWNER_PHONE": "Tel",
  "OWNER_CALL": "Call",
  "OWNER_MSG": "Message",
  "OWNER_MAIL": "Email",
  "MSG_PERMISSION_LIBRARY": "Sorry, but we need permissions to access your directory.",
  "MSG_PERMISSION_CAMERA": "Sorry, but we need permissions to access your camera.",
  "UPDATE_COMPLAINT_FORM_HEADER": "Update Complaint Form",
  "UPDATE_COMPLAINT_FORM_PROBLEM": "Enter the problem",
  "UPDATE_COMPLAINT_FORM_UPLOAD_BUTTON": "Add a photo",
  "UPDATE_COMPLAINT_FORM_IMG_BUTTON": "Gallery image",
  "UPDATE_COMPLAINT_FORM_CAMERA_BUTTON": "Take a picture",
  "UPDATE_COMPLAINT_FORM_CANCEL_BUTTON": "Cancel",
  "UPDATE_COMPLAINT_FORM_SUBMIT_BUTTON": "Save",
  "APARTMENT_DOOR_DESCRIPTION": "Description",
  "APARTMENT_DOOR_DOOR_TYPE": "Door type",
  "APARTMENT_DOOR_DEVICE_TYPE": "Type of device",
  "APARTMENT_DOOR_DIGIT_CODE": "Digicode",
  "APARTMENT_DOOR_MANUAL_CODE": "Access code",
  "APARTMENT_DOOR_DOOR_NAME": "Door name Oiqia",
  "APARTMENT_DOOR_BUTTON_UNLOCK": "Unlock",
  "APARTMENT_DOOR_BUTTON_LOCK": "Lock",
  "APARTMENT_DOOR_TITLE": "Instructions for accessing your home",
  "APPARTMENT_DOOR_STATE": "Status",
  "BUTTON_POPUP_TITLE_DOOR": "What do you want to do ?",
  "BUTTON_POPUP_TITLE": "Do you want to Unlock ?",
  "BUTTON_POPUP_TITLE_LOCK": "Do you want to lock ?",
  "BUTTON_POPUP_YES": "Yes",
  "BUTTON_POPUP_YESS": "Yes",
  "BUTTON_POPUP_NO": "No",
  "CHECKIN_DONE_TITLE": "Check-in date",
  "CHECKOUT_DONE_TITLE": "Check-out date",
  "CHECKIN_HISTORY_BUTTON": "Check in",
  "CHECKOUT_HISTORY_BUTTON": "Check out",
  "CHECKOUT_ADD_COMPLAINT": "Add a complaint",
  "COMPLAINTS_HISTORY_TITLE": "Description of the problem",
  "COMPLAINTS_HISTORY_LABEL_PROBLEM": "Description",
  "COMPLAINTS_HISTORY_LABEL_IMAGE": "Photos",
  "ALERT_CHECKIN_DONE": "Registration successfully completed",
  "ALERT_PHONE_MANDATORY": "Invalid phone number, e.g. +33 06 01 02 03 04",
  "ALERT_PROBLEM": "Enter a problem",
  "TOAST_SUCCESS_": "Complaint successfully added",
  "HEADER_RESERVATION": "Home",
  "HEADER_HOME": "Access",
  "HEADER_SETTINGS": "Parameters",
  "HEADER_CHECKINFORM": "Control form",
  "HEADER_COMPLAINTFORM": "Problem Statement",
  "HEADER_UPDATE_COMP_FORM": "Editing the problem",
  "HEADER_HISTORY_CHECKIN": "Check-in history",
  "HEADER_HISTORY_CHECKOUT": "Check-out history",
  "BUTTON_YES": "Yes",
  "BUTTON_NO": "not",
  "MODAL_TEXT": "Do you want to delete...",
  "ALERT_FIRSTNAME_MANDATORY": "First name is mandatory",
  "ALERT_LASTNAME_MANDATORY": "Last name is mandatory",
  "ALERT_EMAIL_MANDATORY": "Your email is not valid",
  "ALERT_ADULT_MANDATORY": "Adults are mandatory",
  "SET_PHONE_MANDATORY": "Phone number with area code +33 ...",
  "ATTACHMENT_PICTURES": "Photos",
  "ATTACHMENT_RULES": "Rules",
  "ATTACHMENT_MANUALS": "Manuals",
  "ATTACHMENT_WELCOME_NOTES": "Welcome notes",
  "ERROR_NETWORK_TITLE": "Network error!",
  "HELP": "Help me",
  "VALIDATE": "Confirm",
  "CANCEL": "Cancel",
  "MODAL_TITLE_PICTURE": "Take a photo",
  "MODAL_BODY_PICTURE": "You can take the photo from your gallery or from your camera.",
  "ERROR": "Error ...",
  "MISSING_DATA_ERROR": "Please provide a description of the problem and at least one photo.",
  "MSG_PERMISSION_CAMERA_TITLE": "Permission refused",
  "SELECT_LANGAGE": "Your language :",
  "CLOSE": "Close",
  "ERROR_NETWORK_HELPER": "Close the application, disconnect your device from the network, reconnect it and try again.",
};

export default en;
