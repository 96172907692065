import { Message } from "../controllers/Chat"

import App from "./App";

export default class Notifications extends App {

  startMsgSub() {

    const bookingIdOiqia = this.rootStore.bookingStore.bookingIdOiqia;
    //console.log("startMsgSub",bookingIdOiqia)
    return this.rootStore?.notificationsStore?.setChatSub(bookingIdOiqia);
  }

  getChatMsgSub() {
    return this.rootStore?.notificationsStore?.chatData;
  }

  async sendChatIncidentMsg(newMessages: Message[]) {

    const bookingIdOiqia = this.rootStore.bookingStore.bookingIdOiqia;

    const { text, user, image } = newMessages[0];

    const NewMessage = {
      bookingIdOiqia,
      text,
      image,
      user
    };


    try {
     await this.mutations.setIncidentMsg(NewMessage);

    } catch (err) {
      console.log("ERROR setIncidentMsg", err);
      console.log("ERROR setIncidentMsg", err.networkError.result.errors);
    }

    //console.log("newMessages 0", newMessages[0]);
    //console.log("newMessages NewMessage", NewMessage);
    // return this.rootStore?.notificationsStore?.chatData;
  }
}
