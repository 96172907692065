import { createContext } from "react";

import Auth from "../../controllers/Auth";
import Booking from "../../controllers/Booking";
import Chat from "../../controllers/Chat";
import Notifications from "../../controllers/Notifications";
import PopUp from "../../controllers/PopUp";


export const context = {
  auth: new Auth(),
  booking: new Booking(),
  chat: new Chat(),
  notifications: new Notifications(),
  popUp: new PopUp()
};

const StoreContext = createContext(context);

export default StoreContext;
