/* eslint-disable max-len */
import { base } from "./base";

const fr: base = {
  "QBOY": "CHAT OIQIA",
  "OWNER": "PROPRIÉTAIRE",
  "TITLE_POP": "Bienvenue sur Oiqia traveller",
  "BUTTON_PASS": "Passer",
  "BUTTON_LEAVE": "Quitter",
  "WELCOME": "Bienvenue dans l’application Oiqia Traveler !\n\nLisez bien les prochaines instructions pour une utilisation simple et efficace de l’application.",
  "LOGIN_WELCOME_POP": "Pour utiliser Oiqia Traveler, veuillez insérer le numéro de réservation envoyé par l’équipe Oiqia dans le champs concerné.\n\nChoisissez votre langue de communication.",
  "HOME_WELCOME": "Avec Oiqia Traveler, vous accédez à toute l’information utile concernant votre logement (adresse, codes d’accès, manuels, livret d’accueil, règles de la maison, code wifi, etc.).",
  "ACCESS_WELCOME": "Avec Oiqia Traveler, vous pouvez cliquer sur le bouton « déverrouiller/verrouiller » pour ouvrir/fermer la porte d’immeuble et/ou de l’appartement.\n\nVous pouvez aussi taper le code d’entrée sur le clavier numérique situé près de la porte du logement.",
  "FORM_WELCOME": " A votre arrivée dans le logement, vous devez faire votre check-in.\n\nSi tout va bien, confirmez le bon état général du logement.\nSi problème, signalez-le nous. Cette étape est indispensable en cas de désaccord.\n\nA votre départ, n’oubliez pas de faire votre check-out !",
  "CHAT_WELCOME": "Avec Oiqia Traveler, vous pouvez communiquer directement avec nous, via chat.\n\nVous y trouverez également les coordonnées de notre responsable client Oiqia en cas d’urgence.",
  "SETTING_WELCOME": "Oiqia Traveler communique dans plusieurs langues : choisissez votre langue préférée pour utiliser votre application.\n\nVous pouvez aussi vous déconnecter de l’application.\n\nOu changer votre réservation si vous avez plusieurs réservations en cours avec Oiqia.",
  "BOOKING_ID": "Identifiant",
  "BOOKING_EMAIL": "Email",
  "LOGIN": "Se connecter",
  "LOGIN_WELCOME": "Veuillez saisir le code envoyé pour votre réservation :",
  "POPUP_CHECKIN_TITLE": "Bienvenue dans votre location !",
  "POPUP_CHECKIN_DESCRIPTION": "Afin de profiter de votre location merci de réaliser l'état des lieux dans l’onglet “formulaires”.",
  "POPUP_CHECKIN_BUTTON": "Faire le checkin",
  "HOME": "Accueil",
  "EVENTS": "Événements à côté",
  "RESTAURANTS": "Restaurants à côté",
  "ACCESS": "Accès",
  "BOOKING": "Réservation",
  "ACCESS_CODE": "Code d’accès",
  "UNLOCK_DOOR": "Ouvrir la porte",
  "LOCK_DOOR": "Fermer la porte",
  "INFO_TITLE": "Retrouvez vos \ninformations de location",
  "RESERVATION_REF": "Votre code de réservation :",
  "ADDRESS": "Adresse de la location",
  "ARRIVAL": "Arrivée",
  "DEPARTURE": "Départ",
  "DIRECTIONS": "Calculer l'itinéraire",
  "FROM": "à partir de",
  "TO": "jusqu'à",
  "COPIED": "Code Wifi copié",
  "TEXT_COPIED": "Le code du wifi a été copié vous pouvez vous connecter au wifi en collant le code",
  "EQUIPMENT": "Équipement",
  "BEDROOM": "Chambre",
  "KITCHEN": "Cuisine",
  "BATHROOM": "Salle de bain",
  "SALON": "Salon",
  "OUTSIDE": "Extérieur",
  "COMFORT": "Confort",
  "WIFINAME": "Nom du wifi",
  "WIFICODE": "Code du wifi",
  "EXTRAINFO": "Information supplémentaire",
  "FORMS": "Formulaires",
  "CHECKINFORM": "Formulaire d'entrée",
  "COMPLAINFORM": "Formulaire d'incident",
  "CHECKOUTFORM": "Formulaire de sortie",
  "INCIDENT": "Assistance",
  "ACCESS_OPEN_KEYPAD": "Pour ouvrir avec le digicode\nSaisir seulement ces 6 chiffres",
  "ACCESS_CLOSE_KEYPAD": "Pour fermer avec le digicode",
  "ACCESS_CLOSE_KEY": "appuyer sur",
  "ACCESS_MANAGE_APP": "Ou via l'application",
  "PLACEHOLDER_INPUT": "Posez votre question au support Oiqia",
  "INITIAL_BOT_MESSAGE": "Bonjour, je suis Qboy. Comment puis-je vous aider?",
  "QBOY_PERTINENCE": "Salut encore, les réponses de Qboy sont-elles pertinentes?",
  "QBOY_PERTINENCE_SUCCESS": "Parfait!",
  "QBOY_PERTINENCE_FAIL": "D'accord, nous contacterons le service client et ils vous répondront.",
  "SETTINGS": "Paramètres",
  "INFO_SETTING": "Réglez vos paramètres",
  "CHANGE_RESERVATION": "Changer de réservation",
  "LANGUAGE": "Langue",
  "HOW_TO_CONTACT": "Par quel moyen souhaitez-vous être contacté ?",
  "CHOICE_LANG": "Choisissez votre langue",
  "EMAIL": "Email",
  "NOTIF": "Notifications push ",
  "SMS": "Sms",
  "RECO": "Recevoir des recommandations de Qboy",
  "LOGOUT": "Me déconnecter",
  "WARNING": "Attention",
  "HELP_TITLE": "Besoin d'aide",
  "HELP_TEXT": "Montrez-moi",
  "ERROR_NETWORK": "Merci de vérifier votre connexion internet.",
  "ERROR_NETWORK_TITLE": "Erreur réseau !",
  "HELP": "Aidez-moi",
  "ACCESS_CODE_NOT_AVAILABLE_BEFORE_BOOKING_ARRIVAL": "Le code d'accès peut seulement être délivré lorsque la réservation a commencé.",
  "ERROR_BOOKING_NOT_FOUND_TITLE": "Réservation non trouvée",
  "ERROR_BOOKING_NOT_FOUND_DESCRIPTION": "Les détails de la réservation que vous avez fournis ne correspondent à aucune réservation dans notre système",
  "ERROR_CHAT_BOT_TITLE": "Erreur technique",
  "ERROR_CHAT_BOT_DESCRIPTION": "Un problème est survenu. Veuillez réessayer ultérieurement.",
  "ERROR_INCOMPLETE_FORM_TITLE": "Formulaire incomplet",
  "ERROR_INCOMPLETE_FORM_DESCRIPTION": "Veuillez complétez le formulaire correctement.",
  "ERROR_ACCESS_CODE_TITLE": "Un problème est survenu",
  "ERROR_ACCESS_CODE_DESCRIPTION": "Impossible de récupérer le code d'accès de la maison.",
  "UNKOWN_ERROR_TITLE": "Une erreur innatendue est survenue !",
  "UNKOWN_ERROR_DESCRIPTION": "Un problème inattendu est survenu. Veuillez réessayer plus tard.",
  "CHECKIN_FORM_FIRSTNAME_LABEL": "Prénom",
  "CHECKIN_FORM_LASTNAME_LABEL": "Nom",
  "CHECKIN_FORM_EMAIL_LABEL": "Email",
  "CHECKIN_FORM_RESERVATION_ID_LABEL": "Numéro de réservation",
  "CHECKIN_FORM_PHONE_LABEL": "Téléphone",
  "CHECKIN_FORM_ARRIVAL_LABEL": "Arrivée",
  "CHECKIN_FORM_DEPARTURE_LABEL": "Départ",
  "CHECKIN_FORM_ADULTS_LABEL": "Adultes",
  "CHECKIN_FORM_CHILDREN_LABEL": "Enfants",
  "CHECKIN_FORM_CHECKBOX_TITLE_LABEL": "Le logement correspond-il à vos attentes ?",
  "CHECKIN_FORM_CHECKBOX_YES_LABEL": "Oui",
  "CHECKIN_FORM_CHECKOBOX_NO_LABEL": "Non",
  "CHECKIN_FORM_SUBMIT_BUTTON": "Soumettre",
  "CHECKIN_FORM": "Formulaire d'entrée",
  "CHECKIN_FORM_HEADER": "formulaire d'entrée",
  "CHECKIN_TITLE": "Check-In\nformulaire d'entrée",
  "CHECKIN_DESC_TITLE": "Merci de bien vouloir réaliser votre check-in :",
  "CHECKIN_DESC": "Vérifier que les informations ci-dessous sont  correctes et confirmer l'état de l'hébergement.\n \nCe check-in servira de document de référence, merci de le remplir avec soin.",
  "CHECKIN_DESC_PB_TITLE": "Merci d'indiquer le problème rencontré :",
  "CHECKIN_DESC_PB": "Décrivez le problème, vous pouvez l'accompagner d'une ou plusieurs photos.",
  "CHECKIN_POPUP_TITLE": "Vous devez confirmer que vous êtes présent dans le logement pour pouvoir valider le check-in",
  "CHECKIN_POPUP_SUBTITLE": "PS: Tout dommage constaté dans le logement après la validation du check-in engagera votre responsabilité",
  "CHECKIN_POPUP_EARLY": "Vous pouvez valider le check-in que quand votre réservation a commencé",
  "CHECKOUT_FORM": "Formulaire de sortie",
  "CHECKOUT_TITLE": "Check-Out\nformulaire de sortie",
  "CHECKOUT_SUBTITLE": "MERCI DE BIEN VOULOIR REALISER VOTRE CHECK-OUT",
  "CHECKOUT_DESC": "Il est temps de réaliser votre check-out en cochant les cases ci-dessous.\n \nCertaines de vos réponses engagent votre responsabilité, nous vous remercions par avance de le faire sérieusement.\n \nEnfin, n’oubliez pas de quitter les lieux comme vous aimeriez les trouver.",
  "CHECKOUT_FORM_VERY_UNSATISFACTORY": "Très insatisfaisant",
  "CHECKOUT_FORM_UNSATISFACTORY": "insatisfaisant",
  "CHECKOUT_FORM_NEUTRE": "Ni satisfaisant ni insatisfaisant",
  "CHECKOUT_FORM_SATISFACTORY": "Satisfaisant",
  "CHECKOUT_FORM_VERY_SATISFACTORY": "Très Satisfaisant",
  "CHECKOUT_FORM_RATING_APPART": "Quelle est votre appréciation générale sur le logement loué ?",
  "CHECKOUT_FORM_RATING_PROPERTY": "Quelle est votre appréciation sur la propreté du logement loué ?",
  "CHECKOUT_FORM_RATING_OIQIA": "Quelle est votre appréciation sur l’arrivée autonome via la serrure connectée ?",
  "CHECKOUT_DESCRIPTION_TITLE": "Avez-vous des remarques sur votre séjour ?",
  "CHECKOUT_DESCRIPTION": "Description",
  "CHECKOUT_FORM_ASK_LEAVE": "Vous êtes sur le point de quitter le logement loué.\nEst-il dans le même état que lors de votre arrivée ?",
  "CHECKOUT_COMENTARY_TITLE": "merci de détailler votre réponse dans l’espace « commentaires » ci-dessous.",
  "CHECKOUT_COMENTARY": "Merci de nous déclarer tout problème, dommage constaté dans l'appartement. Vous êtes également libre de nous laisser un commentaire concernant votre séjour !",
  "CHECKOUT_PICTURE": "Vous pouvez prendre des photos du ou des problèmes rencontrés ci-dessous.",
  "CHECKOUT_QCM_ASK": "Enfin, nous comptons sur vous pour respecter les lieux et participer à rendre la communauté de la location courte durée toujours plus plaisante et fiable.",
  "CHECKOUT_QCM_HEADER": "En cochant les cases ci-dessous, vous affirmez :",
  "CHECKOUT_QCM_EMPTY": "Aucune chose à faire",
  "CHECKOUT_ENDING": "Avant de valider le check-out, toute l’équipe Oiqia\nvous souhaite un bon retour dans votre foyer.\nVotre satisfaction est notre priorité, n’hésitez pas à revenir vers vous pour nous faire part de tout commentaire concernant votre séjour.",
  "CHECKOUT_THANKS": "Au plaisir de vous recevoir à nouveau au sein des locations Oiqia !",
  "CHECKOUT_PROBLEM": "Avez-vous rencontré des problèmes durant votre séjour",
  "CHECKOUT_TODOLIST": "Liste des vérifications",
  "CHECKOUT_POPUP_TITLE": "Vous êtes sur le point de valider le check out alors que la date de votre départ est prévu le ",
  "CHECKOUT_POPUP_TIME": "à",
  "CHECKOUT_POPUP_SUBTITLE": "PS: Tout dommage constaté dans le logement après la confirmation du check out engagera votre responsabilité",
  "COMPLAINT_FORM_HEADER": "Formulaire de plainte",
  "COMPLAINT_FORM_PROBLEM": "Entrez le problème",
  "COMPLAINT_FORM_UPLOAD_BUTTON": "Ajouter une photo",
  "COMPLAINT_FORM_IMG_BUTTON": "Image de la galerie",
  "COMPLAINT_FORM_CAMERA_BUTTON": "Prenez une photo",
  "COMPLAINT_FORM_CANCEL_BUTTON": "Annuler",
  "COMPLAINT_FORM_SUBMIT_BUTTON": "Sauvegarder",
  "EDIT_COMPLAINT_LABEL": "Description du problème",
  "EDIT_COMPLAINT_BUTTON": "Modifier",
  "DECLARE_PROBLEM": "Signaler un problème",
  "DECLARERED_PROBLEM": "Problème signalé",
  "EDIT_COMPLAINT_IMAGE": "Image de(s) incident(s)",
  "EDIT_DESCRIPTION": "Souhaitez-vous nous faire part de commentaires particuliers ?",
  "OWNER_TITLE": "En cas de problème\nContacter le propriétaire",
  "OWNER_FIRSTNAME": "Prénom",
  "OWNER_LASTNAME": "Nom",
  "OWNER_EMAIL": "Mail",
  "OWNER_PHONE": "Tel",
  "OWNER_CALL": "Appeler",
  "OWNER_MSG": "Message",
  "OWNER_MAIL": "Email",
  "MSG_PERMISSION_LIBRARY": "Désolé, mais nous avons besoin des permissions pour accéder à votre répertoire.",
  "MSG_PERMISSION_CAMERA": "Désolé, mais nous avons besoin des permissions pour accéder à votre appareil photo. Veuillez les activer dans les paramètres de votre appareil",
  "UPDATE_COMPLAINT_FORM_HEADER": "Mise à jour Formulaire de plainte",
  "UPDATE_COMPLAINT_FORM_PROBLEM": "Entrez le problème",
  "UPDATE_COMPLAINT_FORM_UPLOAD_BUTTON": "Ajouter une photo",
  "UPDATE_COMPLAINT_FORM_IMG_BUTTON": "Image de la galerie",
  "UPDATE_COMPLAINT_FORM_CAMERA_BUTTON": "Prenez une photo",
  "UPDATE_COMPLAINT_FORM_CANCEL_BUTTON": "Annuler",
  "UPDATE_COMPLAINT_FORM_SUBMIT_BUTTON": "Sauvegarder",
  "APARTMENT_DOOR_DESCRIPTION": "Description",
  "APARTMENT_DOOR_DOOR_TYPE": "Type de Porte",
  "APARTMENT_DOOR_DEVICE_TYPE": "Type de Dispositif",
  "APARTMENT_DOOR_DIGIT_CODE": "Digicode",
  "APARTMENT_DOOR_MANUAL_CODE": "Code d'accès",
  "APARTMENT_DOOR_DOOR_NAME": "Nom de porte Oiqia",
  "APARTMENT_DOOR_BUTTON_UNLOCK": "Déverrouiller",
  "APARTMENT_DOOR_BUTTON_LOCK": "Verrouiller",
  "APARTMENT_DOOR_TITLE": "Instructions pour accéder à votre logement",
  "APPARTMENT_DOOR_STATE": "Statut",
  "BUTTON_POPUP_TITLE": "Voulez-vous déverrouiller ?",
  "BUTTON_POPUP_TITLE_LOCK": "Voulez-vous verrouiller ?",
  "BUTTON_POPUP_TITLE_DOOR": "Que voulez-vous faire ?",
  "BUTTON_POPUP_YES": "Oui",
  "BUTTON_POPUP_YESS": "Ouii",
  "BUTTON_POPUP_NO": "Non",
  "CHECKIN_DONE_TITLE": "Check-in effectué le",
  "CHECKOUT_DONE_TITLE": "Check-out effectué le",
  "CHECKIN_HISTORY_BUTTON": "Consulter le check-in",
  "CHECKOUT_HISTORY_BUTTON": "Consulter le check-out",
  "CHECKOUT_ADD_COMPLAINT": "Ajouter une plainte",
  "COMPLAINTS_HISTORY_TITLE": "Description du problème",
  "COMPLAINTS_HISTORY_LABEL_PROBLEM": "Description",
  "COMPLAINTS_HISTORY_LABEL_IMAGE": "Photos",
  "ALERT_CHECKIN_DONE": "Enregistrement effectué avec succès",
  "ALERT_PHONE_MANDATORY": "N° de tél. non valide, ex: +33 06 01 02 03 04",
  "ALERT_PROBLEM": "Entrez un problème",
  "TOAST_SUCCESS_": "Plainte ajoutée avec succès",
  "HEADER_RESERVATION": "Accueil",
  "HEADER_HOME": "Accès",
  "HEADER_SETTINGS": "Paramètres",
  "HEADER_CHECKINFORM": "Formulaire de contrôle",
  "HEADER_COMPLAINTFORM": "Déclaration de problème",
  "HEADER_UPDATE_COMP_FORM": "Edition du problème",
  "HEADER_HISTORY_CHECKIN": "Historique du check-in",
  "HEADER_HISTORY_CHECKOUT": "Historique du check-out",
  "BUTTON_YES": "Oui",
  "BUTTON_NO": "Non",
  "MODAL_TEXT": "Voulez-vous supprimer...",
  "ALERT_FIRSTNAME_MANDATORY": "Le Prénom est obligatoire",
  "ALERT_LASTNAME_MANDATORY": "Nom de famille est obligatoire",
  "ALERT_EMAIL_MANDATORY": "Votre courriel n'est pas valide",
  "ALERT_ADULT_MANDATORY": "Les adultes sont obligatoires",
  "SET_PHONE_MANDATORY": "N° de tél. avec indicatif +33 ...",
  "ATTACHMENT_PICTURES": "Photos",
  "ATTACHMENT_RULES": "Règles",
  "ATTACHMENT_MANUALS": "Manuels",
  "ATTACHMENT_WELCOME_NOTES": "Notes d'accueil",
  "VALIDATE": "Valider",
  "CANCEL": "Annuler",
  "MODAL_TITLE_PICTURE": "Prendre une photo",
  "MODAL_BODY_PICTURE": "Vous pouvez prendre la photo depuis votre gallerie ou bien grâce à la caméra de votre appareil.",
  "ERROR": "Erreur ...",
  "MISSING_DATA_ERROR": "Merci de renseigner une description du problème rencontré et de fournir au minimum une photo",
  "MSG_PERMISSION_CAMERA_TITLE": "Permission refusée",
  "SELECT_LANGAGE": "Votre langue :",
  "CLOSE": "Fermer",
  "ERROR_NETWORK_HELPER": "Fermez l'application, déconnectez votre appareil du réseau, reconnectez le puis ré-essayez.",
};

export default fr;
