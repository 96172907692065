/* eslint-disable max-len */
import { base } from "./base";

const it: base = {
    "QBOY": "OIQIA CAT",
    "OWNER": "PROPRIETARIO",
    "TITLE_POP": "Benvenuti su Oiqia traveller",
    "BUTTON_PASS": "Vai a",
    "BUTTON_LEAVE": "lasciare",
    "WELCOME": "Benvenuti nell'applicazione Oiqia Traveler!\n\nSi prega di leggere attentamente le seguenti istruzioni per assicurarsi di poter utilizzare l'applicazione in modo facile ed efficiente.",
    "LOGIN_WELCOME_POP": "Per utilizzare Oiqia Traveler, inserire il numero di prenotazione inviato dal team di Oiqia nel campo corrispondente.\n\nScegliete la vostra lingua di comunicazione.",
    "HOME_WELCOME": "Con Oiqia Traveler, hai accesso a tutte le informazioni utili sul tuo alloggio (indirizzo, codici di accesso, manuali, libretto di benvenuto, regole della casa, codice wifi, ecc.)",
    "ACCESS_WELCOME": "Con Oiqia Traveler, puoi cliccare sul pulsante \"sblocca/sblocca\" per aprire/chiudere la porta dell'edificio e/o dell'appartamento.\n\nPotete anche digitare il codice d'ingresso sul tastierino numerico\nsituato vicino alla porta dell'appartamento.",
    "FORM_WELCOME": " Quando arriva all'alloggio, deve fare il check-in.\n\nSe tutto va bene, confermate che l'alloggio è in buone condizioni.\nSe ci sono problemi, fatecelo sapere. Questo passo è essenziale in caso di disaccordo.\n\nQuando te ne vai, non dimenticare di fare il check-out!",
    "CHAT_WELCOME": "Con Oiqia Traveler, puoi comunicare direttamente con noi via chat.\n\nTroverete anche i dati di contatto del nostro customer manager di Oiqia in caso di emergenza.",
    "SETTING_WELCOME": "Oiqia Traveler comunica in diverse lingue: scegli la tua lingua preferita per utilizzare l'applicazione.\n\nPuoi anche uscire dall'applicazione.\n\nOppure cambia la tua prenotazione se hai diverse prenotazioni in corso con Oiqia.",
    "BOOKING_ID": "Identificatore",
    "BOOKING_EMAIL": "Email",
    "LOGIN": "Entrare",
    "LOGIN_WELCOME": "Inserisci il codice inviato per la tua prenotazione:",
    "POPUP_CHECKIN_TITLE": "Benvenuti nella vostra posizione!",
    "POPUP_CHECKIN_DESCRIPTION": "Per poter usufruire del vostro affitto, vi preghiamo di compilare l'inventario delle attrezzature nella scheda \"moduli\".",
    "POPUP_CHECKIN_BUTTON": "Fare il check-in",
    "HOME": "Casa",
    "EVENTS": "Eventi nella porta accanto",
    "RESTAURANTS": "Ristoranti nelle vicinanze",
    "ACCESS": "Accesso",
    "BOOKING": "Prenotazione",
    "ACCESS_CODE": "Codice di accesso",
    "UNLOCK_DOOR": "Aprire la porta",
    "LOCK_DOOR": "Chiudere la porta",
    "INFO_TITLE": "Trova il tuo\ninformazioni sul noleggio",
    "RESERVATION_REF": "Il tuo codice di prenotazione :",
    "ADDRESS": "Indirizzo del luogo",
    "ARRIVAL": "Arrivo",
    "DEPARTURE": "Partenza",
    "DIRECTIONS": "Calcola il percorso",
    "FROM": "da",
    "TO": "a",
    "COPIED": "Codice Wifi copiato",
    "TEXT_COPIED": "Il codice wifi è stato copiato e puoi connetterti al wifi incollando il codice",
    "EQUIPMENT": "Attrezzatura",
    "BEDROOM": "Camera",
    "KITCHEN": "Cucina",
    "BATHROOM": "Bagno",
    "SALON": "Salone",
    "OUTSIDE": "All'aperto",
    "COMFORT": "Comfort",
    "WIFINAME": "Nome del wifi",
    "WIFICODE": "Codice Wifi",
    "EXTRAINFO": "Informazioni aggiuntive",
    "FORMS": "Moduli",
    "CHECKINFORM": "Modulo di iscrizione",
    "COMPLAINFORM": "Modulo d'incidente",
    "CHECKOUTFORM": "Modulo di uscita",
    "INCIDENT": "Supporto",
    "ACCESS_OPEN_KEYPAD": "Per aprire con la tastiera\nInserisci solo questi 6 numeri",
    "ACCESS_CLOSE_KEYPAD": "Per chiudere con la tastiera",
    "ACCESS_CLOSE_KEY": "premere",
    "ACCESS_MANAGE_APP": "O tramite l'applicazione",
    "PLACEHOLDER_INPUT": "Fai la tua domanda al supporto di Oiqia",
    "INITIAL_BOT_MESSAGE": "Ciao, sono Qboy. Come posso aiutarla?",
    "QBOY_PERTINENCE": "Ciao di nuovo, le risposte di Qboy sono rilevanti?",
    "QBOY_PERTINENCE_SUCCESS": "Perfetto!",
    "QBOY_PERTINENCE_FAIL": "Ok, contatteremo il servizio clienti e la richiameranno.",
    "SETTINGS": "Parametri",
    "INFO_SETTING": "Regolare le impostazioni",
    "CHANGE_RESERVATION": "Cambiare la prenotazione",
    "LANGUAGE": "Lingua",
    "HOW_TO_CONTACT": "Come vuoi essere contattato?",
    "CHOICE_LANG": "Scegli la tua lingua",
    "EMAIL": "Email",
    "NOTIF": "Notifiche push",
    "SMS": "Sms",
    "RECO": "Ricevi raccomandazioni da Qboy",
    "LOGOUT": "Disconnessione",
    "WARNING": "Attenzione",
    "HELP_TITLE": "Bisogno di aiuto",
    "HELP_TEXT": "Mostrami",
    "ERROR_NETWORK": "Per favore, controllate la vostra connessione internet.",
    "ACCESS_CODE_NOT_AVAILABLE_BEFORE_BOOKING_ARRIVAL": "Il codice di accesso può essere rilasciato solo dopo che la prenotazione è stata effettuata.",
    "ERROR_BOOKING_NOT_FOUND_TITLE": "Prenotazione non trovata",
    "ERROR_BOOKING_NOT_FOUND_DESCRIPTION": "I dettagli della prenotazione che hai fornito non corrispondono a nessuna prenotazione nel nostro sistema",
    "ERROR_CHAT_BOT_TITLE": "Errore tecnico",
    "ERROR_CHAT_BOT_DESCRIPTION": "Si è verificato un problema. Si prega di riprovare più tardi.",
    "ERROR_INCOMPLETE_FORM_TITLE": "Modulo incompleto",
    "ERROR_INCOMPLETE_FORM_DESCRIPTION": "Si prega di compilare correttamente il modulo.",
    "ERROR_ACCESS_CODE_TITLE": "È sorto un problema",
    "ERROR_ACCESS_CODE_DESCRIPTION": "Impossibile recuperare il codice di accesso alla casa.",
    "UNKOWN_ERROR_TITLE": "È sorto un problema inaspettato",
    "UNKOWN_ERROR_DESCRIPTION": "Si è verificato un problema inaspettato. Si prega di riprovare più tardi.",
    "CHECKIN_FORM_FIRSTNAME_LABEL": "Nome",
    "CHECKIN_FORM_LASTNAME_LABEL": "Nome",
    "CHECKIN_FORM_EMAIL_LABEL": "Email",
    "CHECKIN_FORM_RESERVATION_ID_LABEL": "Numero di prenotazione",
    "CHECKIN_FORM_PHONE_LABEL": "Telefono",
    "CHECKIN_FORM_ARRIVAL_LABEL": "Arrivo",
    "CHECKIN_FORM_DEPARTURE_LABEL": "Partenza",
    "CHECKIN_FORM_ADULTS_LABEL": "Adulti",
    "CHECKIN_FORM_CHILDREN_LABEL": "Bambini",
    "CHECKIN_FORM_CHECKBOX_TITLE_LABEL": "L'alloggio soddisfa le vostre aspettative?",
    "CHECKIN_FORM_CHECKBOX_YES_LABEL": "Sì",
    "CHECKIN_FORM_CHECKOBOX_NO_LABEL": "No",
    "CHECKIN_FORM_SUBMIT_BUTTON": "Invia",
    "CHECKIN_FORM": "Modulo di iscrizione",
    "CHECKIN_FORM_HEADER": "modulo d'iscrizione",
    "CHECKIN_TITLE": "Check-in\nmodulo d'iscrizione",
    "CHECKIN_DESC_TITLE": "Grazie per aver controllato:",
    "CHECKIN_DESC": "Controlla che le informazioni sottostanti siano corrette e conferma lo stato dell'alloggio.\n\nQuesto check-in servirà come documento di riferimento, si prega di compilarlo attentamente.",
    "CHECKIN_DESC_PB_TITLE": "Si prega di indicare il problema riscontrato:",
    "CHECKIN_DESC_PB": "Descrivi il problema, puoi includere una o più foto.",
    "CHECKIN_POPUP_TITLE": "Deve confermare la sua presenza nell'alloggio per convalidare il check-in",
    "CHECKIN_POPUP_SUBTITLE": "PS: Qualsiasi danno trovato nell'alloggio dopo il check-in sarà di vostra responsabilità",
    "CHECKIN_POPUP_EARLY": "È possibile convalidare il check-in solo quando la prenotazione è iniziata",
    "CHECKOUT_FORM": "Modulo di uscita",
    "CHECKOUT_TITLE": "Check-Out\nmodulo di uscita",
    "CHECKOUT_SUBTITLE": "GRAZIE PER AVER CONTROLLATO",
    "CHECKOUT_DESC": "È il momento di fare il check out spuntando le caselle qui sotto.\n\nAlcune delle vostre risposte sono di vostra responsabilità, vi ringraziamo in anticipo per farlo seriamente.\n\nInfine, non dimenticate di lasciare i locali come vorreste trovarli.",
    "CHECKOUT_FORM_VERY_UNSATISFACTORY": "Molto insoddisfacente",
    "CHECKOUT_FORM_UNSATISFACTORY": "insoddisfacente",
    "CHECKOUT_FORM_NEUTRE": "Né soddisfacente né insoddisfacente",
    "CHECKOUT_FORM_SATISFACTORY": "Soddisfacente",
    "CHECKOUT_FORM_VERY_SATISFACTORY": "Molto soddisfacente",
    "CHECKOUT_FORM_RATING_APPART": "Qual è la sua valutazione generale dell'alloggio in affitto?",
    "CHECKOUT_FORM_RATING_PROPERTY": "Come giudica la pulizia dell'alloggio in affitto?",
    "CHECKOUT_FORM_RATING_OIQIA": "Qual è la sua valutazione dell'arrivo autonomo tramite la serratura collegata?",
    "CHECKOUT_DESCRIPTION_TITLE": "Ha qualche commento sul suo soggiorno?",
    "CHECKOUT_DESCRIPTION": "Descrizione",
    "CHECKOUT_FORM_ASK_LEAVE": "State per lasciare l'alloggio in affitto.\nÈ nelle stesse condizioni di quando siete arrivati?",
    "CHECKOUT_COMENTARY_TITLE": "Si prega di fornire i dettagli della vostra risposta nello spazio per i commenti qui sotto.",
    "CHECKOUT_COMENTARY": "Si prega di segnalare qualsiasi problema o danno nell'appartamento. Siete anche liberi di lasciarci un commento sul vostro soggiorno!",
    "CHECKOUT_PICTURE": "Potete fotografare il problema o i problemi riscontrati qui sotto.",
    "CHECKOUT_QCM_ASK": "Infine, contiamo su di voi per rispettare i locali e per partecipare a rendere la comunità di affitto a breve termine più piacevole e affidabile.",
    "CHECKOUT_QCM_HEADER": "Spuntando le caselle qui sotto, stai affermando:",
    "CHECKOUT_QCM_EMPTY": "Niente da fare",
    "CHECKOUT_ENDING": "Prima di convalidare il check-out, l'intero team di Oiqia\nLa squadra vi augura un buon viaggio di ritorno a casa.\nLa vostra soddisfazione è la nostra priorità, non esitate a tornare da noi con qualsiasi commento sul vostro soggiorno.",
    "CHECKOUT_THANKS": "Non vediamo l'ora di accogliervi di nuovo su Oiqia!",
    "CHECKOUT_PROBLEM": "Ha avuto qualche problema durante il suo soggiorno?",
    "CHECKOUT_TODOLIST": "Lista di controllo",
    "CHECKOUT_POPUP_TITLE": "State per convalidare il check out quando la vostra data di partenza è prevista per",
    "CHECKOUT_POPUP_TIME": "à",
    "CHECKOUT_POPUP_SUBTITLE": "PS: Sarete ritenuti responsabili di qualsiasi danno trovato nell'alloggio dopo che il check out è stato confermato",
    "COMPLAINT_FORM_HEADER": "Modulo di reclamo",
    "COMPLAINT_FORM_PROBLEM": "Inserisci il problema",
    "COMPLAINT_FORM_UPLOAD_BUTTON": "Aggiungi una foto",
    "COMPLAINT_FORM_IMG_BUTTON": "Immagine della galleria",
    "COMPLAINT_FORM_CAMERA_BUTTON": "Scattare una foto",
    "COMPLAINT_FORM_CANCEL_BUTTON": "Cancella",
    "COMPLAINT_FORM_SUBMIT_BUTTON": "Salva",
    "EDIT_COMPLAINT_LABEL": "Descrizione del problema",
    "EDIT_COMPLAINT_BUTTON": "Modifica",
    "DECLARE_PROBLEM": "Segnala un problema",
    "DECLARERED_PROBLEM": "Problema segnalato",
    "EDIT_COMPLAINT_IMAGE": "Immagine dell'incidente(i)",
    "EDIT_DESCRIPTION": "Hai qualche feedback sull'arrivo autonomo?",
    "OWNER_TITLE": "In caso di problemi\nContatta il proprietario",
    "OWNER_FIRSTNAME": "Nome",
    "OWNER_LASTNAME": "Nome",
    "OWNER_EMAIL": "Posta",
    "OWNER_PHONE": "Tel",
    "OWNER_CALL": "Chiama",
    "OWNER_MSG": "Messaggio",
    "OWNER_MAIL": "Email",
    "MSG_PERMISSION_LIBRARY": "Spiacenti, ma abbiamo bisogno dei permessi per accedere alla tua directory.",
    "MSG_PERMISSION_CAMERA": "Spiacenti, ma abbiamo bisogno dei permessi per accedere alla tua telecamera.",
    "UPDATE_COMPLAINT_FORM_HEADER": "Aggiornare il modulo di reclamo",
    "UPDATE_COMPLAINT_FORM_PROBLEM": "Inserisci il problema",
    "UPDATE_COMPLAINT_FORM_UPLOAD_BUTTON": "Aggiungi una foto",
    "UPDATE_COMPLAINT_FORM_IMG_BUTTON": "Immagine della galleria",
    "UPDATE_COMPLAINT_FORM_CAMERA_BUTTON": "Scattare una foto",
    "UPDATE_COMPLAINT_FORM_CANCEL_BUTTON": "Cancella",
    "UPDATE_COMPLAINT_FORM_SUBMIT_BUTTON": "Salva",
    "APARTMENT_DOOR_DESCRIPTION": "Descrizione",
    "APARTMENT_DOOR_DOOR_TYPE": "Tipo di porta",
    "APARTMENT_DOOR_DEVICE_TYPE": "Tipo di dispositivo",
    "APARTMENT_DOOR_DIGIT_CODE": "Digicode",
    "APARTMENT_DOOR_MANUAL_CODE": "Codice di accesso",
    "APARTMENT_DOOR_DOOR_NAME": "Nome della porta Oiqia",
    "APARTMENT_DOOR_BUTTON_UNLOCK": "Sblocca",
    "APARTMENT_DOOR_BUTTON_LOCK": "Blocca",
    "APARTMENT_DOOR_TITLE": "Istruzioni per accedere alla sua casa",
    "APPARTMENT_DOOR_STATE": "Stato",
    "BUTTON_POPUP_TITLE_DOOR": "Cosa vuoi fare ?",
    "BUTTON_POPUP_TITLE": "Vuoi sbloccare?",
    "BUTTON_POPUP_TITLE_LOCK": "Vuoi bloccare?",
    "BUTTON_POPUP_YES": "Sì",
    "BUTTON_POPUP_YESS": "Sì",
    "BUTTON_POPUP_NO": "No",
    "CHECKIN_DONE_TITLE": "Data del check-in",
    "CHECKOUT_DONE_TITLE": "Data di partenza",
    "CHECKIN_HISTORY_BUTTON": "Check in",
    "CHECKOUT_HISTORY_BUTTON": "Controlla",
    "CHECKOUT_ADD_COMPLAINT": "Aggiungere un reclamo",
    "COMPLAINTS_HISTORY_TITLE": "Descrizione del problema",
    "COMPLAINTS_HISTORY_LABEL_PROBLEM": "Descrizione",
    "COMPLAINTS_HISTORY_LABEL_IMAGE": "Foto",
    "ALERT_CHECKIN_DONE": "Registrazione completata con successo",
    "ALERT_PHONE_MANDATORY": "Numero di telefono non valido, ad esempio +33 06 01 02 03 04",
    "ALERT_PROBLEM": "Inserisci un problema",
    "TOAST_SUCCESS_": "Denuncia aggiunta con successo",
    "HEADER_RESERVATION": "Casa",
    "HEADER_HOME": "Accesso",
    "HEADER_SETTINGS": "Parametri",
    "HEADER_CHECKINFORM": "Forma di controllo",
    "HEADER_COMPLAINTFORM": "Dichiarazione del problema",
    "HEADER_UPDATE_COMP_FORM": "Modifica del problema",
    "HEADER_HISTORY_CHECKIN": "Storia del check-in",
    "HEADER_HISTORY_CHECKOUT": "Storia del check-out",
    "BUTTON_YES": "Sì",
    "BUTTON_NO": "non",
    "MODAL_TEXT": "Vuoi cancellare...",
    "ALERT_FIRSTNAME_MANDATORY": "Il nome è obbligatorio",
    "ALERT_LASTNAME_MANDATORY": "Il cognome è obbligatorio",
    "ALERT_EMAIL_MANDATORY": "La tua email non è valida",
    "ALERT_ADULT_MANDATORY": "Gli adulti sono obbligatori",
    "SET_PHONE_MANDATORY": "Numero di telefono con prefisso +33 ...",
    "ATTACHMENT_PICTURES": "Foto",
    "ATTACHMENT_RULES": "Regole",
    "ATTACHMENT_MANUALS": "Manuali",
    "ATTACHMENT_WELCOME_NOTES": "Note di benvenuto",
    "ERROR_NETWORK_TITLE": "Errore di rete!",
    "HELP": "Aiutatemi",
    "VALIDATE": "Confermare",
    "CANCEL": "Annullamento",
    "MODAL_TITLE_PICTURE": "Scattare una foto",
    "MODAL_BODY_PICTURE": "È possibile prendere la foto dalla galleria o dalla fotocamera.",
    "ERROR": "Errore ...",
    "MISSING_DATA_ERROR": "Si prega di fornire una descrizione del problema e almeno una foto.",
    "MSG_PERMISSION_CAMERA_TITLE": "Permesso rifiutato",
    "SELECT_LANGAGE": "La tua lingua :",
    "CLOSE": "Chiudere",
    "ERROR_NETWORK_HELPER": "Chiudere l'applicazione, scollegare il dispositivo dalla rete, ricollegarlo e riprovare.",
};

export default it;