import React, { useState, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  StyleSheet,
  SafeAreaView,
  Text,
  View,
  ScrollView,
  ImageBackground,
} from "react-native";
import Queries from "../../api/queries";
import StoreContext from "../../store/Context";
import DoorInfo from "./DoorInfo";
import typo from "./../../Theme/Typography";
import { useFocusEffect } from "@react-navigation/native";
import OiqiaLoadingModal from "../OiqiaModal/OiqiaLoadingModal";
import OiqiaErrorModal, { OiqiaErrorModalProps } from "../OiqiaModal/OiqiaErrorModal";

export interface LockListControllerProps { }

const LockListController: React.FC<LockListControllerProps> = () => {
  const { t } = useTranslation();
  const BG_PATH = require("../../assets/newDesign/background-portrait.png");

  let quries = new Queries();
  const context = useContext(StoreContext);
  const [doors, setDoors] = useState([]);

  const [loading, setLoading] = useState(false);

  const [qlError, setQlError] = useState<OiqiaErrorModalProps['qlError']>({
    networkError: "",
    message: "",
    visible: false,
  });

  const onCloseQlError = (): void => {
    setQlError({
      networkError: "",
      message: "",
      visible: false,
    });
  }

  const authContext = context?.auth;
  const { bookingIdOiqia } = authContext.getBookingStore();

  useFocusEffect(
    useCallback(() => {
      async function getDoors() {
        try {
          const response = await quries.getAppartementDoors(bookingIdOiqia);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          setDoors(response?.data?.getAppartementDoors);

        } catch (err) {
          const { networkError = false, message = "" } = err;
          setQlError({
            visible: true,
            networkError,
            message
          });
          console.log(JSON.stringify(err, null, 2));
        }
      }
      getDoors();
    }, [bookingIdOiqia])
  )

  return (
    <SafeAreaView style={typo.mainBackground}>

      <ImageBackground source={BG_PATH} style={styles.background}>

        <ScrollView>
          <OiqiaErrorModal onClose={onCloseQlError} qlError={qlError} />
          <View style={typo.container}>
            <Text style={typo.titleInfo}>{t("APARTMENT_DOOR_TITLE")}</Text>
            <View>
              {loading &&
                <OiqiaLoadingModal loading={loading} />
              }
              {doors.map((door: any, index: number) => (
                <DoorInfo key={`lock-${index}`} door={door} bookingId={bookingIdOiqia} setLoading={(_: boolean) => setLoading(_)} />
              ))}
            </View>
          </View>
        </ScrollView>
      </ImageBackground>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F7F7F7"
  },
  background: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
  },
  infoContainer: {
    margin: 25
  },
  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    height: "auto",
    alignItems: "flex-start",
  },
  tinyLogo: {
    width: 240,
    height: 146,
  },
  imageContainer: {
    borderColor: "grey",
    borderWidth: 2,
    borderTopRightRadius: 20,

    borderBottomRightRadius: 20,
  },
  col: {
    flex: 1,
    padding: 10,
  },
  pageTitle: {
    textAlign: "center",
    width: "100%",
  },
  doorStatusButtonOpen: {
    marginTop: 50,
    backgroundColor: "red",
  },
  doorStatusButtonClose: {
    marginTop: 50,
    backgroundColor: "green",
  },

  apartDoorStatusButtonOpen: {
    marginTop: 50,
    backgroundColor: "green",
  },
  aprtDoorStatusButtonClose: {
    marginTop: 50,
    backgroundColor: "red",
  },
  button: {
    marginBottom: 8,
  },
});

export default LockListController;
