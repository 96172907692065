import React, { useState, useEffect, useContext } from "react";

import {
  StyleSheet,
  SafeAreaView,
  View,
  ScrollView,
  Text,
  ImageBackground,
} from "react-native";
import StoreContext from "../../store/Context";
import { TextInput, RadioButton, Caption, Title, Button, Card } from "react-native-paper";
import { useTranslation } from "react-i18next";
import DisplayExistingComplain from "./DisplayExistingComplain";

import ImageViewer from "react-native-image-zoom-viewer";
import { Entypo } from "@expo/vector-icons";

import typo from "./../../Theme/Typography";

const BG_PATH = require("../../assets/newDesign/background-portrait.png");

export interface PreviousStateProps { }
const HistoryCheckinState: React.FC<PreviousStateProps> = ({
  route,
  navigation,
  ...props
}: any) => {
  const context = useContext(StoreContext);
  const authContext = context?.auth;

  const { bookingId = "" } = authContext.getUserStore();

  const { t, i18n } = useTranslation();
  const { formData } = route.params;

  const [modalConfig, setModalConfig] = useState({
    isVisible: false,
    currentIndex: 0,
  });

  const openModal = (index: number) => {
    setModalConfig({
      isVisible: true,
      currentIndex: index,
    });
  };

  const closeModal = () => {
    setModalConfig({
      isVisible: false,
      currentIndex: 0,
    });
  };

  useEffect(() => {
    //console.log(formData, "yoo...");
  }, [formData]);

  return (
    <SafeAreaView style={typo.mainBackground}>
      <ImageBackground source={BG_PATH} style={typo.background}>
        <ScrollView style={styles.formWrappe}>
          <View>
            <View style={typo.catCard}>
              <View style={styles.row}>
                <View style={styles.col}>
                  <TextInput
                    disabled
                    value={formData?.firstName}
                    label={t("CHECKIN_FORM_FIRSTNAME_LABEL")}
                    mode="outlined"
                  />
                </View>
                <View style={styles.col}>
                  <TextInput
                    disabled
                    value={formData.lastName}
                    label={t("CHECKIN_FORM_LASTNAME_LABEL")}
                    mode="outlined"
                  />
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.col}>
                  <TextInput
                    disabled
                    value={formData.email}
                    label={t("CHECKIN_FORM_EMAIL_LABEL")}
                    mode="outlined"
                  />
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.col}>
                  <TextInput
                    value={bookingId}
                    disabled
                    label={t("CHECKIN_FORM_RESERVATION_ID_LABEL")}
                    mode="outlined"
                  />
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <TextInput
                    disabled
                    value={formData.phone}
                    label={t("CHECKIN_FORM_PHONE_LABEL")}
                    mode="outlined"
                  />
                </View>
              </View>
              <View style={styles.row}>
                <View style={styles.col}>
                  <TextInput
                    value={formData.arrival}
                    disabled
                    label={t("CHECKIN_FORM_ARRIVAL_LABEL")}
                    mode="outlined"
                  />
                </View>

                <View style={styles.col}>
                  <TextInput
                    value={formData.departure}
                    disabled
                    label={t("CHECKIN_FORM_DEPARTURE_LABEL")}
                    mode="outlined"
                  />
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.col}>
                  <TextInput
                    disabled
                    value={String(formData.adults)}
                    label={t("CHECKIN_FORM_ADULTS_LABEL")}
                    mode="outlined"
                  />
                </View>

                <View style={styles.col}>
                  <TextInput
                    disabled
                    value={String(formData.children)}
                    label={t("CHECKIN_FORM_CHILDREN_LABEL")}
                    mode="outlined"
                  />
                </View>
              </View>

              <View style={styles.pageTitle}>
              <Text style={typo.body}> {t("CHECKIN_FORM_CHECKBOX_TITLE_LABEL")}</Text>
                <Text style={styles.answer}> {formData.isSatisfied ? t("CHECKIN_FORM_CHECKBOX_YES_LABEL") : t("CHECKIN_FORM_CHECKOBOX_NO_LABEL")} </Text>
              </View>


              {formData.isSatisfied === false &&
                [ // If there no problem yet disclarer
                  !(formData?.problem?.description || formData?.problem?.image?.length > 0) ?
                    <Button
                      color="#D71D9A"
                      mode="contained"
                      onPress={() => navigation.navigate("ComplaintForm")}>
                      {t("DECLARE_PROBLEM")}</Button>
                    :

                    <DisplayExistingComplain
                      navigation={navigation}
                      checkInData={formData}
                      readOnly={true} />

                ]
              }

            </View>
          </View>
        </ScrollView>
      </ImageBackground>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 0,
  },
  formWrappe: {
    flex: 1,
    padding: 10,
    paddingBottom: 100,
  },
  pageTitle: {
    textAlign: "center",
    marginTop: 5,
    width: "100%",
  },
  answer: {
    margin: 10,
    padding:10,
    color:"black",
    borderColor: "black",
    borderWidth: 0.5,
    borderRadius: 20,
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",

  },
  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    height: "auto",
    alignItems: "flex-start",
  },
  imageContainer: {
    borderWidth: 2,
    borderRadius: 22,
    borderColor: "white",
    //marginRight: 10,
    margin: 15,
  },
  image: {
    width: 156,
    height: 136,
    borderColor: "white",
    borderWidth: 2,
    borderRadius: 20,
  },
  col: {
    flex: 1,
    padding: 10,
  },
  problemcontainer: {
    margin: 10,
    padding: 10,
    borderColor: "white",
    backgroundColor: "#FAFAFA",
    borderWidth: 2,
    borderRadius: 20,
  },
  problem: {
    flex: 1,
  },
});
export default HistoryCheckinState;
