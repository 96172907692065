import { action, makeObservable, observable } from "mobx";

import RootStore from "..";
import { BookingOiqiaQl } from "../../types/types";

export default class BookingStore {
  booking: BookingOiqiaQl | null = null;
  bookingIdOiqia  = "";
  rootStore;

  constructor(rootStore:RootStore) {
    makeObservable(this, {
      booking: observable,
      updateBooking: action,
    });
    this.rootStore = rootStore;
  }

  updateBooking(booking: BookingOiqiaQl) {
    this.booking = { ...booking };
    this.bookingIdOiqia = booking.bookingIdOiqia;

   // console.log("bookingIdOiqia init",this.bookingIdOiqia);
  }
}

