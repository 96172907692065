import { StyleSheet, Dimensions } from "react-native";

const { width } = Dimensions.get("window");

const OIQIA_COLOR = "#D71D9A";

const styles = StyleSheet.create({

  imageWrapper: {
    justifyContent: "center",
    alignItems: "center",
  },

  codeWrap: {
    flex: 1,
    alignSelf: "flex-start",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  code: {
    fontSize: 22,
    color: OIQIA_COLOR,
    borderColor: "#0A0A0AE7",
    borderWidth: 0.5,
    borderRadius: 5,
    letterSpacing: 2,
    padding: 5,
    margin: 10,
    fontFamily: "RobotoBold"
  },
  column: {
    flex: 1,
    alignSelf: "auto",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  dateInfo: {
    width: "50%",
    padding: 5,
    textAlign: "left",

    justifyContent: "flex-start",
  },
  underline: {
    borderColor: "#0A0A0AE7",
    borderBottomWidth: 0.5,
    paddingBottom: 5,
    marginBottom: 10
  },
  underlineDot: {
    borderColor: "#0A0A0AE7",
    borderStyle: "dashed",
    borderRadius: 1,
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginBottom: 10
  },


  wrap10: {
    margin: 15,
  },
  button: {
    borderRadius: 10,
    marginBottom: 10,
    backgroundColor: OIQIA_COLOR,
  },
  box: {
    marginVertical: 10,
  },
  title: {
    fontSize: 26,
    color: "white",
    textAlign: "center",
    /*     alignItems: "center", */
    /*     justifyContent: "center", */
    marginBottom: 20,
    fontFamily: "OpenSansSemiBold"
  },
  subTitle: {
    fontSize: 15,
    fontWeight: "bold",
    //fontWeight: "700",
    color: "#000030",
    margin: 5,
  },
  subTitleBis: {
    fontSize: 15,
    fontWeight: "bold",
    //fontWeight: "700",
    color: "#808080",
    marginLeft: 5,
    //margin: 5,
  },


  image: {
    width,
    height: 150,
    resizeMode: "contain",
  },
  buttonWrapper: {
    width: "100%",
    alignSelf: "center",
  },
  tinyLogo: {
    width: 166,
    height: 143,
  },

  imageCard: {
    resizeMode: "contain",
    borderColor: "grey",
    borderWidth: 2,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
  },

  accesCodeText: {
    textAlign: "center",
    paddingLeft: 0,

  },

  adressCard: {
    padding: 15,
    width: "100%",
    borderColor: "#eae8e1",
    borderWidth: 4,
    borderRadius: 20,
    marginBottom: 30,
  },
  attachmentTitles: {
    left: 5,
    marginBottom: 5,
    fontSize: 17,
    fontWeight: "bold",
  },
  centeredView: {
    flex: 2,
    justifyContent: "center",
    alignItems: "center",
  }
});

export default styles;
