import React from "react";
import { Text, StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";

interface ButtonProps {
  style?: Record<string, unknown>;
  onPress: () => void;
  text: string;
  textStyles?: Record<string, unknown>;
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: "blue",
    height: 40,
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  text: {
    color: "white",
    fontWeight: "bold",
    fontSize: 17,
  },
});

const Button: React.FC<ButtonProps> = ({ style, onPress, text, textStyles }) => {
  return (
    <TouchableOpacity onPress={onPress} style={[styles.wrapper, style]}>
      <Text style={[styles.text, textStyles]}>{text}</Text>
    </TouchableOpacity>
  );
};

export default Button;
