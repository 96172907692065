import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { AntDesign, Entypo, Feather, FontAwesome5 } from '@expo/vector-icons';

import { TabsParamList } from "./ParamList/TabsParamList";

// import Home from "./Home";
import SettingStack from "../screens/Tabs/Settings/index";
import CheckInFormStack from "../components/CheckInForm/index";
import BookingStack from "../components/Bookings/index";
import DoorControllerStack from "../components/DoorController/index";
import ChatIncidentStack from "../screens/Tabs/ChatIncident/ChatIncident";

import IconHome from "../assets/newDesign/icons/trans_icon_nav_home.svg";
import IconAccess from "../assets/newDesign/icons/trans_icon_nav_acces.svg";
import IconForms from "../assets/newDesign/icons/trans_icon_form.svg";
import IconChat from "../assets/newDesign/icons/trans_icon_chat.svg";
import IconParam from "../assets/newDesign/icons/trans_icon_param.svg";
import { Platform } from "react-native";

const Tab = createBottomTabNavigator<TabsParamList>();

function Tabs() {
  const { t, i18n } = useTranslation();

  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarIcon: ({ color }) => {
          let icon = <></>;
          if (Platform.OS != "web") {
            if (route.name === "Home") {
              icon = <IconHome width={25} height={25} fill={color}></IconHome>
            } else if (route.name === "Settings") {
              icon = <IconParam width={25} height={25} fill={color}></IconParam>
            } else if (route.name === "ChatIncident") {
              icon = <IconChat width={25} height={25} fill={color}></IconChat>
            } else if (route.name === "Door") {
              icon = <IconAccess width={25} height={25} fill={color}></IconAccess>
            } else if (route.name === "Forms") {
              icon = <IconForms width={25} height={25} fill={color}></IconForms>
            } else {
              icon = <IconHome width={25} height={25} fill={color}></IconHome>
            }
          } else {
            if (route.name === "Home") {
              icon = <Entypo name="home" size={25} color={color} />;
            } else if (route.name === "Settings") {
              icon = <Feather name="settings" size={25} color={color} />;
            } else if (route.name === "ChatIncident") {
              icon = <Entypo name="chat" size={25} color={color} />;
            } else if (route.name === "Door") {
              icon = <Entypo name="key" size={25} color={color} />;
            } else if (route.name === "Forms") {
              icon = <FontAwesome5 name="calendar-check" size={24} color={color} />;
            } else {
              icon = <Entypo name="home" size={25} color={color} />;
            }
          }
          return icon;
        }
      })}
      tabBarOptions={{
        inactiveTintColor: "#000",
        activeTintColor: "#D71D9A",
      }}
    >
      <Tab.Screen
        name="Home"
        component={BookingStack}
        options={{
          tabBarLabel: t("HOME"),
        }}
      />

      <Tab.Screen
        name="Door"
        component={DoorControllerStack}
        options={{
          tabBarLabel: t("ACCESS"),
        }}
      />
      <Tab.Screen
        name="Forms"
        component={CheckInFormStack}
        options={{
          tabBarLabel: t("FORMS"),
        }}
      />
      <Tab.Screen
        name="ChatIncident"
        component={ChatIncidentStack}
        options={{
          tabBarLabel: t("INCIDENT"),
        }}
      />
      <Tab.Screen
        name="Settings"
        component={SettingStack}
        options={{
          tabBarLabel: t("SETTINGS"),
        }}
      />
    </Tab.Navigator>
  );
}

export default Tabs;
