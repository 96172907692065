import React, { useState, useEffect, useContext } from "react";

import {
  StyleSheet,
  SafeAreaView,
  View,
  ScrollView,
  Text,
  Image,
  ImageBackground,
  Modal,
  TouchableOpacity,
} from "react-native";
import StoreContext from "../../store/Context";
import { TextInput, Card, Checkbox } from "react-native-paper";
import { useTranslation } from "react-i18next";

import { AirbnbRating } from "react-native-ratings";

import ImageViewer from "react-native-image-zoom-viewer";
import { Entypo } from "@expo/vector-icons";

import typo from "../../Theme/Typography";

const BG_PATH = require("../../assets/newDesign/background-portrait.png");

export interface PreviousStateProps { }
const HistoryCheckoutState: React.FC<PreviousStateProps> = ({
  route,
  navigation,
  ...props
}: any) => {
  const context = useContext(StoreContext);
  const authContext = context?.auth;
  const [modalConfig, setModalConfig] = useState({
    isVisible: false,
    currentIndex: 0,
  });

  const { bookingId = "" } = authContext.getUserStore();

  const { t, i18n } = useTranslation();
  const { Data } = route.params;

  const openModal = (i: number) => {
    setModalConfig({
      isVisible: true,
      currentIndex: i
    })
  }

  const closeModal = () => {
    setModalConfig({
      isVisible: false,
      currentIndex: 0
    })
  }

  const listPicture = () => {
    const { image } = Data?.problem
    return (image.map((ele: Array<String>, i: number) => {
      return (
        <View key={i} style={styles.column}>
          <Card
            style={styles.rowImg}
            onPress={() => openModal(i)}
          >
            <View style={styles.col}>
              <Image
                style={{
                  width: 120,
                  height: 70,
                }}
                source={{ uri: ele }}
              />
            </View>
          </Card>
        </View>
      );
    })
    )
  }

  return (
    <SafeAreaView style={typo.mainBackground}>
      <ImageBackground source={BG_PATH} style={typo.background}>
        <ScrollView style={styles.formWrappe}>
          <View style={typo.catCard}>
            <View style={styles.row}>
              <View style={styles.col}>
                <Text style={typo.body}>{t("CHECKOUT_FORM_RATING_APPART")}</Text>
                <AirbnbRating
                  count={5}
                  reviews={[t("CHECKOUT_FORM_VERY_UNSATISFACTORY"), t("CHECKOUT_FORM_UNSATISFACTORY"), t("CHECKOUT_FORM_NEUTRE"), t("CHECKOUT_FORM_SATISFACTORY"), t("CHECKOUT_FORM_VERY_SATISFACTORY")]}
                  defaultRating={Data.ratingAppart}
                  reviewSize={15}
                  size={30}
                  isDisabled={true}
                />
              </View>
            </View>
            <View style={typo.underlineCat}></View>
            <View style={styles.row}>
              <View style={styles.col}>
                <Text style={typo.body}>{t("CHECKOUT_FORM_RATING_PROPERTY")}</Text>
                <AirbnbRating
                  count={5}
                  reviews={[t("CHECKOUT_FORM_VERY_UNSATISFACTORY"), t("CHECKOUT_FORM_UNSATISFACTORY"), t("CHECKOUT_FORM_NEUTRE"), t("CHECKOUT_FORM_SATISFACTORY"), t("CHECKOUT_FORM_VERY_SATISFACTORY")]}
                  defaultRating={Data.ratingCleanness}
                  reviewSize={15}
                  size={30}
                  isDisabled={true}
                />
              </View>
            </View>
            <View style={typo.underlineCat}></View>
            <View style={styles.row}>
              <View style={styles.col}>
                <Text style={typo.body}>{t("CHECKOUT_FORM_RATING_OIQIA")}</Text>
                <AirbnbRating
                  count={5}
                  reviews={[t("CHECKOUT_FORM_VERY_UNSATISFACTORY"), t("CHECKOUT_FORM_UNSATISFACTORY"), t("CHECKOUT_FORM_NEUTRE"), t("CHECKOUT_FORM_SATISFACTORY"), t("CHECKOUT_FORM_VERY_SATISFACTORY")]}
                  defaultRating={Data.ratingOiqia}
                  reviewSize={15}
                  size={30}
                  isDisabled={true}
                />
              </View>
            </View>
            <View style={typo.underlineCat}></View>
            <View style={styles.row}>
              <View style={styles.col}>
                <Text style={typo.body}>{t("EDIT_DESCRIPTION")}</Text>
                <View>
                  <TextInput
                    value={Data.feedBack}
                    style={{ marginTop: 10, marginBottom: 10 }}
                    label={t("CHECKOUT_DESCRIPTION")}
                    multiline
                    mode="outlined"
                    disabled={true}
                  />
                </View>
              </View>
            </View>
            <View style={typo.underlineCat}></View>
            <View style={styles.row}>
              <View style={styles.col}>
                <View style={styles.pageTitle}>
                  <Text style={typo.body}> {t("CHECKOUT_PROBLEM")}</Text>
                  <Text style={styles.answer}> {!Data.isSatisfied ? t("CHECKIN_FORM_CHECKBOX_YES_LABEL") : t("CHECKIN_FORM_CHECKOBOX_NO_LABEL")} </Text>
                </View>
              </View>
            </View>
            {!Data.isSatisfied &&
              <View>

                <View style={typo.underlineCat}>
                  <Text style={typo.category}>{t("DECLARERED_PROBLEM")}</Text>
                </View>
                <View style={styles.row}>
                  <View style={styles.col}>
                    <View>
                      <TextInput
                        value={Data.problem.description}
                        style={{ marginTop: 10, marginBottom: 10 }}
                        label={t("EDIT_COMPLAINT_LABEL")}
                        multiline
                        mode="outlined"
                        disabled={true}
                      />
                    </View>
                  </View>
                </View>
                {Data.problem.image.length > 0 &&
                  <View>
                    <View style={typo.underlineCat}>
                      <Text style={typo.category}>{t("EDIT_COMPLAINT_IMAGE")}</Text>
                    </View>
                    <View style={styles.row}>
                      <View style={styles.col}>
                        <ScrollView horizontal={true}>
                          <View style={styles.row}>
                            <View style={styles.gridRow}>
                              {listPicture()}
                            </View>
                          </View>
                        </ScrollView>
                        <Modal visible={modalConfig.isVisible} transparent={true}>
                          <ImageViewer
                            enableSwipeDown={true}
                            index={modalConfig.currentIndex}
                            imageUrls={Data?.problem?.image.map(
                              (img: string) => {
                                return {
                                  url: img,
                                  props: { source: { uri: img } }
                                };
                              },
                            )}
                            renderHeader={() => (
                              <SafeAreaView>
                                <TouchableOpacity onPress={closeModal}>
                                  <Entypo
                                    //style={{ top: 7 }}
                                    name="cross"
                                    onPress={closeModal}
                                    size={60}
                                    color="white"
                                  />
                                </TouchableOpacity>
                              </SafeAreaView>
                            )}
                          ></ImageViewer>
                        </Modal>
                      </View>
                    </View>
                  </View>}
              </View>}
            {Data.checkoutQuestion.length > 0 ?
              <View>
                <View style={typo.underlineCat}>
                  <Text style={typo.category}>{t("CHECKOUT_TODOLIST")}</Text>
                </View>
                <View style={styles.row}>
                  <View style={styles.col}>
                    {Data.checkoutQuestion.map((element: any, index: number) => (
                      <View style={styles.item} key={index}>
                        <Checkbox.Android
                          color="#71d200"
                          status={'checked'}
                        />
                        <Text style={styles.textCheckBox}>{element.text}</Text>
                      </View>
                    ))}
                  </View>
                </View>
              </View>
              :
              <View style={styles.row}>
                <View style={styles.col}>
                 {/*  <Text style={styles.center}>{t("CHECKOUT_QCM_EMPTY")}</Text> */}
                </View>
              </View>
            }

          </View>
        </ScrollView>
      </ImageBackground >
    </SafeAreaView >
  );
};

const styles = StyleSheet.create({
  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    height: "auto",
    alignItems: "flex-start",
  },
  rowImg: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    height: "auto",
  },
  col: {
    flex: 1,
    padding: 10,

  },
  colImg: {
    alignItems: "center",
    marginBottom: -10
  },
  selectionTouch: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    height: 52,
    alignItems: "center",
    textAlign: "center",
    borderRadius: 10,
    borderColor: "black",
    borderWidth: 0.5,
    padding: 10,
    margin: 10,
  },
  selection: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  item: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  Button: {
    backgroundColor: "#D71D9A",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    height: 44,
  },
  icons: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  column: {
    width: "auto",
    padding: 5,
  },
  colIcon: {
    flex: 1,
    padding: 5,
    alignItems: "center",
  },
  gridRow: {
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    justifyContent: "center",
    textAlign: "center",
  },
  iconsBox: {
    marginTop: 3,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  textCheckBox: {
    maxWidth: 320
  },
  center: {
    textAlign: "center"
  },
  answer: {
    margin: 10,
    padding: 10,
    color: "black",
    borderColor: "black",
    borderWidth: 0.5,
    borderRadius: 20,
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",

  },
  formWrappe: {
    flex: 1,
    padding: 10,
    paddingBottom: 100,
  },
  pageTitle: {
    textAlign: "center",
    marginTop: 5,
    width: "100%",
  },
});

export default HistoryCheckoutState;
