import { useTranslation } from "react-i18next";
import { QuickReplies, Reply } from "react-native-gifted-chat";
import i18next from "i18next";

import App from "./App";

export type Message = {
  _id: string;
  createdAt: Date;
  text: string;
  image?: string;
  user: {
    _id: string;
    name: string;
  },
  quickReplies?: QuickReplies,
}

const questionPertinenceKey = "user_asked_pertinence_question";
export default class Chat extends App {
    

  private user = { _id: 1, name: "user" };

  private bot = {
    _id: 2,
    name: "Q Boy",
  };

  private initialMessage = {
    _id: 1,
    text: i18next.t("INITIAL_BOT_MESSAGE"),
    createdAt: new Date(),
    user: this.bot
  };

  public getBot() {
    return this.bot;
  }

  public getUser() {
    return this.user;
  }

  getInitialMessage() {
    return this.initialMessage;
  }

  askUserAboutQboyPertinence() {
    const text = i18next.t("QBOY_PERTINENCE");
    const success: Reply = {
      title: "Yes",
      value: "yes"
    };
    const fail: Reply = {
      title: "No",
      value: "no"
    };

    const question = this.createChatQuestion(text, [success, fail]);

    return question;
  }



  private createChatQuestion(text: string, values: Reply[]): Message {
    return {
      text,
      _id: this.generateUniquId(),
      createdAt: new Date(),
      user: this.bot,
      quickReplies: this.createQuickReplies(values),
    };
  }

  private createQuickReplies(values: Reply[]): QuickReplies {
    return {
      values,
      type: "radio",
      keepIt: false
    };
  }

  async getBotResponseFromQuestion(question: string) {
    const booking = this.getBookingStore()?.booking;


    const response = await this.mutations.askChatBot(question, (booking?.bookingIdOiqia) as string);

    if (response) {
      const { data } = response;

      const botResponse = (data?.askChatBot) as string;

      const formedResponse = this.buildQboyMessage(botResponse);

      return formedResponse;
    }

  }


  private generateUniquId() {
    // TODO - Use more standard id generator (uuid is not compatible with RN a the moment)
    return 5 * Math.random();
  }


  async hasQuestionBeenAsked() {
    const value = await this.getItemFromLocalStorage(questionPertinenceKey);
    return value === "true";
  }

  async userHasBeenAskedPertinenceQuestion() {
    await this.setItemToLocalStorage(questionPertinenceKey, "true");
  }

  buildUserMessage(message: string): Message {
    return {
      _id: this.generateUniquId(),
      text: message,
      createdAt: new Date(),
      user: this.user
    };
  }

  buildQboyMessage(message: string): Message {
    return {
      _id: this.generateUniquId(),
      text: message,
      createdAt: new Date(),
      user: this.bot
    };
  }

  async alertCSInvalidQboyResponses() {
    const booking = this.getBookingStore()?.booking;

    if (booking) {
      try {
        const result = await this.mutations.alertCustomerService(booking.id);
        if (result?.data?.alertCustomerService) {
          this.alertMessage(
            i18next.t("UNKOWN_ERROR_TITLE"),
            i18next.t("UNKOWN_ERROR_DESCRIPTION"),
            "danger"
          );
        }
      } catch (err) {
        console.log("ERROR alertCSInvalidQboyResponses", err);
      }
    }
  }

}
