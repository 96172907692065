import { action, makeObservable, observable } from "mobx";

import RootStore from "..";


export default class BookingStore {
  loaderEnabled = 0;
  rootStore;

  constructor(rootStore:RootStore) {
    makeObservable(this, {
      loaderEnabled: observable,
      loading: action,
    });
    this.rootStore = rootStore;
  }

  loading(status:boolean) {
    if(status){
      this.loaderEnabled = this.loaderEnabled+1;
    }
    else{
      this.loaderEnabled = this.loaderEnabled>0?this.loaderEnabled-1:this.loaderEnabled;
    }
  }
}

