import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import CheckInNew from "./CheckInNew";
//import CheckinForm from "./../Forms/CheckinForm"; // to intermeridaite screen for complain and checkout

import HistoryCheckinState from "./HistoryCheckinState";
import Booking from "../Bookings/Booking";

import UpdateComplaintForm from "./UpdateComplaintForm";
import { HeaderBackButton } from '@react-navigation/stack';
import { useTranslation } from "react-i18next";
import HistoryCheckoutState from "../CheckOutForm/HistoryCheckoutState";
const Stack = createStackNavigator();

export interface CheckInFormStackProps {}

const CheckInFormStack: React.FC<CheckInFormStackProps> = () => {
  const { t, i18n } = useTranslation();
  return (
    <Stack.Navigator>

      <Stack.Screen
        name="CheckInForm"
        component={CheckInNew}
        options={{
          title: t("HEADER_CHECKINFORM"),
          headerStyle: {
            backgroundColor: "#D71D9A",
          },
          headerTintColor: "#fff",
          headerTitleStyle: {
            fontWeight: "bold",
          },
        }}
      />
      <Stack.Screen
        name="UpdateComplaintForm"
        component={UpdateComplaintForm}
        options={{
          title: t("HEADER_UPDATE_COMP_FORM"),
          headerStyle: {
            backgroundColor: "#D71D9A",
          },
          headerTintColor: "#fff",
          headerTitleStyle: {
            fontWeight: "bold",
          },
        }}
      />
      <Stack.Screen
        name="HistoryCheckinData"
        component={HistoryCheckinState}
        options={{
          title: t("HEADER_HISTORY_CHECKIN"),
          headerStyle: {
            backgroundColor: "#D71D9A",
          },
          headerTintColor: "#fff",
          headerTitleStyle: {
            fontWeight: "bold",
          },
        }}
      />
      <Stack.Screen
        name="HistoryCheckoutData"
        component={HistoryCheckoutState}
        options={{
          title: t("HEADER_HISTORY_CHECKOUT"),
          headerStyle: {
            backgroundColor: "#D71D9A",
          },
          headerTintColor: "#fff",
          headerTitleStyle: {
            fontWeight: "bold",
          },
        }}
      />
    </Stack.Navigator>
  );
};

export default CheckInFormStack;
