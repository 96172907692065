import React, {
  useState,
  Fragment,
  useEffect,
  useContext,
} from "react";

import {
  StyleSheet,
  SafeAreaView,
  View,
  ScrollView,
  Image,
  Text,
  TouchableHighlight,
  TouchableOpacity,
  Modal,
  Dimensions,
  Alert,
  Pressable,
} from "react-native";

import * as ImagePicker from 'expo-image-picker';

import { Title, RadioButton, TextInput, Button, Card, Checkbox } from "react-native-paper";

import { useTranslation } from "react-i18next";

import typo from "./../../Theme/Typography";

import { AirbnbRating } from 'react-native-ratings';
import ImageViewer from "react-native-image-zoom-viewer";

import { Entypo, FontAwesome } from '@expo/vector-icons';
import Queries from "../../api/queries";
import StoreContext from "../../store/Context";
import Mutations from "../../api/mutations";

import moment from "moment";
import "moment/locale/fr";

// NEW CAMERA SCREEN
import CameraScreen from "../CameraScreen";
import OiqiaModal from "../OiqiaModal/OiqiaModal";
import OiqiaErrorModal, { OiqiaErrorModalProps } from "../OiqiaModal/OiqiaErrorModal";

const BG_PATH = require("../../assets/newDesign/background-portrait.png");

const CheckOut = ({ navigation, setIsloading }) => {
  const { t, i18n } = useTranslation();
  const [isProblem, setIsProblem] = useState(true)
  const [ratingLike, setLike] = useState(5)
  const [ratingClean, setClean] = useState(5)
  const [ratingOiqia, setOiqia] = useState(5)
  const [ableSubmit, setAbleSubmit] = useState(false)
  const [pictures, setPictures] = useState([])
  const [isFormValited, setFormValited] = useState(true)
  const [textProblem, setTextProblem] = useState("")
  const [isLoading, setLoading] = useState(true)
  const [todoList, setTodoList] = useState([])
  const [isDone, setDone] = useState(true)
  const [dataCheckOut, setDataCheckOut] = useState({})
  const [textDescription, setTextDescription] = useState("")
  const [popUpOpen, setPopUpOpen] = useState(false)

  const [qlError, setQlError] = useState<OiqiaErrorModalProps['qlError']>({
    networkError: "",
    message: "",
    visible: false,
  });

  const onCloseQlError = (): void => {
    setQlError({
      networkError: "",
      message: "",
      visible: false,
    });
  }

  const context = useContext(StoreContext);
  const authContext = context?.auth;

  const [CameraOpen, setCameraOpen] = useState(false);

  const [modalConfig, setModalConfig] = useState({
    isVisible: false,
    currentIndex: 0,
  });

  const { bookingIdOiqia, booking } = authContext.getBookingStore();

  let queries = new Queries();
  let mutate = new Mutations();

  useEffect(() => {
    getCheckoutData()
  }, [i18n.language]);

  const openModal = (i: number) => {
    setModalConfig({
      isVisible: true,
      currentIndex: i,
    })
  }

  const closeModal = () => {
    setModalConfig({
      isVisible: false,
      currentIndex: 0,
    })
  }

  const getCheckoutData = async () => {
    setLoading(true)

    queries.getCheckOut(bookingIdOiqia, i18n.language).then((response) => {
      let dataCheckout = response?.data?.getCheckOut;
      setDataCheckOut(dataCheckout)
      setLike(dataCheckout?.ratingAppart)
      setClean(dataCheckout?.ratingCleanness)
      setOiqia(dataCheckout?.ratingOiqia)
      if (dataCheckout.checkoutQuestion?.length > 0) {
        setTodoList(dataCheckout?.checkoutQuestion)

      } else {
        setAbleSubmit(true)
      }

      if (dataCheckout.isSatisfied == false) {
        setIsProblem(false)
        setTextProblem(dataCheckout?.problem?.description)
        managePictureFormat(dataCheckout?.problem?.image)

      } else {
        setIsProblem(true)
      }
      dataCheckout.isCheckoutDone ? setDone(true) : setDone(false)
    }
    ).catch((err) => {
      setIsloading(false);
      const { networkError = false, message = "" } = err;
      setQlError({
        visible: true,
        networkError,
        message
      });
      console.log(err);
    });
    setIsloading(false);
  }

  const managePictureFormat = (image) => {
    const temp = []
    image.map(element => {
      temp.push({ uri: element, base64: "" })
    })
    setPictures(temp)
  }

  const checkAblesubmit = (data) => {
    let temp = 0;
    data.map(element => {
      if (element.checked == true) {
        temp++
      }
    });
    if (temp == todoList.length) {
      setAbleSubmit(true);
    } else {
      setAbleSubmit(false)
    }
  }

  const addPicture = (picture: object) => {
    const temp = [...pictures]
    temp.push({ uri: picture.uri, base64: picture.base64 });
    setPictures(temp)
  }

  const deletePicture = (index) => {
    const temp = [...pictures]
    temp.splice(index, 1);
    setPictures(temp)
  }

  const takePicture = () => {
    setCameraOpen(true);
  }

  const pickImage = async () => {
    const { granted } = await ImagePicker.requestMediaLibraryPermissionsAsync()
    if (!granted) {
      alert(t("MSG_PERMISSION_LIBRARY"));
      return
    }
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      base64: true,
      quality: 0.5,
    });
    if (!result.canceled) {
      addPicture(result)
    }
  }

  const pushCheckout = async (data: any) => {
    setIsloading(true)
    try {
      const response = await mutate.addCheckOut({ formValue: data })
    } catch (err) {
      setIsloading(false);
      const { networkError = false, message = "" } = err;
      setQlError({
        visible: true,
        networkError,
        message
      });
      console.log(err);
    }
    setIsloading(false);
  }

  const pushImage = async () => {
    const getLink = async () => {
      const allImage = await Promise.all(pictures.map(async (element) => {
        try {
          if (element.base64 != "") {
            const link: any = await mutate.uploadComplaintImage(
              bookingIdOiqia,
              `data:image/jpeg;base64,${element.base64}`,
            );
            return (link.data.uploadComplaintImage)
          } else {
            return (element.uri)
          }
        } catch (err) {
          alert(t("UNKOWN_ERROR_TITLE") + t("UNKOWN_ERROR_DESCRIPTION"))
          allImage.pop()
        }
      }
      ))
      return allImage;
    }
    return await getLink();
  }

  const findEarlyDeparture = async () => {
    const startOfDaydeparture = new Date(booking?.departure)
    const departureDate = new Date(`${booking?.departure}T${booking?.checkOut}Z`)
    const currentDate = new Date()
    if (!isProblem && !textProblem) {
      setFormValited(false)
      Alert.alert(t('ERROR'), t('MISSING_DATA_ERROR'));
    } else {
      if (startOfDaydeparture.getTime() <= currentDate.getTime() && currentDate.getTime() <= departureDate.getTime()) {
        submitForm(true)
      } else {
        setFormValited(true)
        setPopUpOpen(true)
      }
    }
  }

  const submitForm = async (done: boolean) => {
    setFormValited(true)
    let allImage = []
    if (!isProblem) {
      allImage = await pushImage()
    }
    setDone(done)
    const data = {
      bookingId: bookingIdOiqia,
      ratingAppart: ratingLike,
      ratingCleanness: ratingClean,
      ratingOiqia: ratingOiqia,
      feedBack: textDescription,
      isCheckoutDone: done,
      isSatisfied: isProblem,
      problem: {
        description: isProblem ? "" : textProblem,
        image: allImage
      },
      checkoutQuestion: todoList
    }
    await pushCheckout(data)
    setDataCheckOut(data)
  }

  const MultiselectCheckbox = ({ options, onChange }) => {

    const toggle = index => {
      const newData = [...options];
      newData.splice(index, 1, {
        text: options[index].text,
        checked: !options[index].checked
      });
      onChange(newData);
      checkAblesubmit(newData)
    };

    return (
      <>
        {options.map((item, index) => (
          <View style={styles.item} key={index}>
            <Checkbox.Android
              color="#71d200"
              uncheckedColor="red"
              status={item.checked ? 'checked' : 'unchecked'}
              onPress={() => toggle(index)}
            />
            <Text style={styles.textCheckBox} key={item.text}>{item.text}</Text>
          </View>
        ))}
      </>
    );
  };

  //state portait / landscape

  const [isPortrait, setIsPortrait] = useState<boolean>(true);

  /**
   * Returns true if the screen is in portrait mode
   */
  const checkIfPortrait = () => {
    const dim = Dimensions.get('screen');
    return dim.height >= dim.width;
  };

  Dimensions.addEventListener('change', () => {
    setIsPortrait(
      checkIfPortrait() ? true : false
    );
  });

  const listPicture = () =>
    pictures.map((ele: object, i: number) => {
      return (
        <View key={i} style={styles.column}>
          <Card
            style={styles.rowImg}
            onPress={() => openModal(i)}
          >
            <View style={styles.col}>
              <Image
                style={{
                  width: 120,
                  height: 70,
                }}
                source={{ uri: ele.uri }}
              />
            </View>

            <View style={styles.colIcon}>
              <FontAwesome name="trash" size={25} color="red" onPress={() => deletePicture(i)} />
            </View>
          </Card>
        </View>
      );
    });


  return (
    <SafeAreaView>
      <ScrollView>
        <Fragment>
          <Modal
            visible={CameraOpen}
            onRequestClose={() => setCameraOpen(false)}
          >
            <CameraScreen closeCamera={() => setCameraOpen(false)} pictures={pictures} setPictures={setPictures} isPortrait={isPortrait} />
          </Modal>

          <OiqiaErrorModal onClose={onCloseQlError} qlError={qlError} />

          <OiqiaModal
            isOpen={popUpOpen}
            onClose={() => setPopUpOpen(false)}
            titleText={t("WARNING")}
            bodyText={
              <>
                <Text>{t("CHECKOUT_POPUP_TITLE")}{moment(booking?.departure).locale("fr").format("LL")} {t("CHECKOUT_POPUP_TIME")} {booking?.checkOut}{"\n"}</Text>
                <Text style={{ fontStyle: 'italic', marginTop: 10 }}>{t("CHECKOUT_POPUP_SUBTITLE")}</Text>
              </>
            }
            firstButtonData={{
              text: t('CANCEL'),
              callback: () => setPopUpOpen(false),
            }}
            secondButtonData={{
              text: t('VALIDATE'),
              callback: () => {
                submitForm(true)
                setPopUpOpen(false)
              },
            }}
          />
          {!isDone ?
            <View>
              <View style={typo.container}>
                <Text style={typo.titleCheckout}>{t("CHECKOUT_TITLE")}</Text>

                <View style={typo.resCard}>
                  <Text style={[typo.h3, { marginBottom: 10 }]}>{t("CHECKOUT_SUBTITLE")} </Text>
                  <Text style={typo.body}>{t("CHECKOUT_DESC")} </Text>
                </View>
              </View>
              <View style={typo.catCard}>
                <View style={typo.underlineCat}>
                  {/* <IconForm width={25} height={25} style={typo.icons}></IconForm> */}
                  <Title style={typo.category}>{t("CHECKOUT_FORM")}</Title>
                </View>
                <View style={styles.row}>
                  <View style={styles.col}>
                    <Text>{t("CHECKOUT_FORM_RATING_APPART")}</Text>
                    <AirbnbRating
                      count={5}
                      reviews={[t("CHECKOUT_FORM_VERY_UNSATISFACTORY"), t("CHECKOUT_FORM_UNSATISFACTORY"), t("CHECKOUT_FORM_NEUTRE"), t("CHECKOUT_FORM_SATISFACTORY"), t("CHECKOUT_FORM_VERY_SATISFACTORY")]}
                      defaultRating={ratingLike}
                      reviewSize={15}
                      onFinishRating={(value) => setLike(value)}
                      size={30}
                    />
                  </View>
                </View>
                <View style={typo.underlineCat}></View>
                <View style={styles.row}>
                  <View style={styles.col}>
                    <Text>{t("CHECKOUT_FORM_RATING_PROPERTY")}</Text>
                    <AirbnbRating
                      count={5}
                      reviews={[t("CHECKOUT_FORM_VERY_UNSATISFACTORY"), t("CHECKOUT_FORM_UNSATISFACTORY"), t("CHECKOUT_FORM_NEUTRE"), t("CHECKOUT_FORM_SATISFACTORY"), t("CHECKOUT_FORM_VERY_SATISFACTORY")]}
                      defaultRating={ratingClean}
                      reviewSize={15}
                      onFinishRating={(value) => setClean(value)}
                      size={30}
                    />
                  </View>
                </View>
                <View style={typo.underlineCat}></View>
                <View style={styles.row}>
                  <View style={styles.col}>
                    <Text>{t("CHECKOUT_FORM_RATING_OIQIA")}</Text>
                    <AirbnbRating
                      count={5}
                      reviews={[t("CHECKOUT_FORM_VERY_UNSATISFACTORY"), t("CHECKOUT_FORM_UNSATISFACTORY"), t("CHECKOUT_FORM_NEUTRE"), t("CHECKOUT_FORM_SATISFACTORY"), t("CHECKOUT_FORM_VERY_SATISFACTORY")]}
                      defaultRating={ratingOiqia}
                      reviewSize={15}
                      onFinishRating={(value) => setOiqia(value)}
                      size={30}
                    />
                  </View>
                </View>
                <View style={typo.underlineCat}></View>
                <View style={styles.row}>
                  <View style={styles.col}>
                    <Text>{t("CHECKOUT_DESCRIPTION_TITLE")}</Text>
                    <TextInput
                      value={textDescription}
                      style={{ marginTop: 10, marginBottom: 10 }}
                      label={t("CHECKOUT_DESCRIPTION")}
                      multiline
                      numberOfLines={2}
                      mode="outlined"
                      onChangeText={setTextDescription}
                    />
                  </View>
                </View>
                <View style={typo.underlineCat}></View>
                <View style={typo.container}>
                  <Text>{t("CHECKOUT_FORM_ASK_LEAVE")}</Text>
                  <View style={styles.row}>
                    <TouchableHighlight style={styles.selectionTouch} onPress={() => setIsProblem(true)}
                      underlayColor="#03dac433">
                      <View style={styles.selection}>
                        <Text style={typo.h3}>{t("CHECKIN_FORM_CHECKBOX_YES_LABEL")}</Text>
                        <RadioButton
                          value="yes"
                          status={isProblem ? "checked" : "unchecked"}
                          onPress={() => setIsProblem(true)}
                        />
                      </View>
                    </TouchableHighlight>

                    <TouchableHighlight style={styles.selectionTouch} onPress={() => setIsProblem(false)}
                      underlayColor="#03dac433">
                      <View style={styles.selection}>
                        <Text style={typo.h3}>{t("CHECKIN_FORM_CHECKOBOX_NO_LABEL")}</Text>
                        <RadioButton
                          value="no"
                          status={isProblem ? "unchecked" : "checked"}
                          onPress={() => setIsProblem(false)}
                        />
                      </View>
                    </TouchableHighlight>
                  </View>
                  {!isProblem &&
                    <View>
                      <Text>{t("CHECKOUT_COMENTARY_TITLE")}</Text>
                      <TextInput
                        value={textProblem}
                        style={{ marginTop: 10, marginBottom: 10 }}
                        label={t("UPDATE_COMPLAINT_FORM_PROBLEM")}
                        multiline
                        numberOfLines={5}
                        mode="outlined"
                        error={!isFormValited}
                        onChangeText={setTextProblem}
                      />
                      <Text>{t("CHECKOUT_COMENTARY") + '\n'}</Text>
                      <Text>{t("CHECKOUT_PICTURE")}</Text>
                      <View style={styles.row}>
                        <View style={styles.col}>
                          <View style={styles.iconsBox}>
                            <Pressable style={styles.colImg} onPress={takePicture} >
                              <FontAwesome name="camera" size={30} color="#D71D9A" />
                              <Text>{t("UPDATE_COMPLAINT_FORM_CAMERA_BUTTON")}</Text>
                            </Pressable>
                            <Pressable style={styles.colImg} onPress={pickImage} >
                              <FontAwesome name="picture-o" size={30} color="#D71D9A" />
                              <Text>{t("UPDATE_COMPLAINT_FORM_IMG_BUTTON")}</Text>
                            </Pressable>
                          </View>
                        </View>
                      </View>
                      <ScrollView horizontal={true}>
                        <View style={styles.row}>
                          <View style={styles.gridRow}>
                            {listPicture()}
                          </View>
                        </View>
                      </ScrollView>
                      <Modal visible={modalConfig.isVisible} transparent={true}>
                        <ImageViewer
                          enableSwipeDown={true}
                          index={modalConfig.currentIndex}
                          imageUrls={pictures.map(
                            (element: object) => {
                              return {
                                url: element.uri,
                                props: { source: { uri: element.uri } }
                              };
                            },
                          )}
                          renderHeader={() => (
                            <SafeAreaView>
                              <TouchableOpacity onPress={closeModal}>
                                <Entypo
                                  name="cross"
                                  onPress={closeModal}
                                  size={60}
                                  color="white"
                                />
                              </TouchableOpacity>
                            </SafeAreaView>
                          )}
                        ></ImageViewer>
                      </Modal>
                    </View>}
                </View>
                <View style={typo.underlineCat}></View>
                {todoList.length > 0 ?
                  <View style={styles.row}>
                    <View style={styles.col}>
                      <Text>{t("CHECKOUT_QCM_ASK") + "\n"}</Text>
                      <Text>{t("CHECKOUT_QCM_HEADER")}</Text>
                      <MultiselectCheckbox
                        options={todoList}
                        onChange={setTodoList}
                      />
                    </View>
                  </View>
                  :
                  <View style={styles.row}>
                    <View style={styles.col}>
                      {/* <Text style={styles.center}>{t("CHECKOUT_QCM_EMPTY")}</Text> */}
                    </View>
                  </View>
                }
                <View style={typo.underlineCat}></View>
                <View style={styles.row}>
                  <View style={styles.col}>
                    <Text>{t("CHECKOUT_ENDING") + "\n"}</Text>
                    <Text>{t("CHECKOUT_THANKS")}</Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.col}>
                    {ableSubmit &&
                      <Button
                        style={typo.oiqiaButton}
                        mode="contained"
                        onPress={() => findEarlyDeparture()}
                      >
                        {t("CHECKIN_FORM_SUBMIT_BUTTON")}
                      </Button>}
                  </View>
                </View>
              </View>
            </View>
            :
            <View style={typo.container}>
              <View style={typo.resCard}>
                <Title style={typo.h3}>
                  {t("CHECKOUT_DONE_TITLE")}{" "}
                  {moment(dataCheckOut.createdAt).format("DD-MM-YYYY")}
                </Title>
                <View style={styles.row}>
                  <View style={styles.col}>
                    <Button
                      style={{ marginBottom: 20 }}
                      mode="contained"
                      onPress={() => {
                        navigation.navigate("HistoryCheckoutData", {
                          Data: dataCheckOut,
                        });
                      }}
                      color="#D71D9A"
                    >
                      {t("CHECKOUT_HISTORY_BUTTON")}
                    </Button>
                  </View>
                </View>
              </View>
            </View>}
        </Fragment>
      </ScrollView>
    </SafeAreaView>
  );
}


const styles = StyleSheet.create({
  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    height: "auto",
    alignItems: "flex-start",
  },
  rowImg: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    height: "auto",
  },
  col: {
    flex: 1,
    padding: 10,

  },
  colImg: {
    alignItems: "center",
    marginBottom: -10
  },
  selectionTouch: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    height: 52,
    alignItems: "center",
    textAlign: "center",
    borderRadius: 10,
    borderColor: "black",
    borderWidth: 0.5,
    padding: 10,
    margin: 10,
  },
  selection: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  item: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  Button: {
    backgroundColor: "#D71D9A",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    height: 44,
  },
  icons: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  column: {
    width: "auto",
    padding: 5,
  },
  colIcon: {
    flex: 1,
    padding: 5,
    alignItems: "center",
  },
  gridRow: {
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    justifyContent: "center",
    textAlign: "center",
  },
  iconsBox: {
    marginTop: 3,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  textCheckBox: {
    maxWidth: 320
  },
  center: {
    textAlign: "center"
  },
  modalWrapper: {
    marginBottom: 10,
  },
  text: {
    marginTop: -10,
    marginBottom: 15,
    width: '98%',
    alignItems: "center"
  },
  flexBoxButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  }
});

export default CheckOut;
