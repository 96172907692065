/* eslint-disable camelcase */
import { Platform } from "react-native";
import openMap from "react-native-open-maps";

import App from "./App";

export default class Booking extends App {
  booking = this.rootStore?.bookingStore?.booking;

  getBooking() {
    return this.rootStore?.bookingStore?.booking;
  }

  async getAccessCode() {
    let pinCode = null;
    const booking = this.getBooking();

    if (booking?.id) {
      const { data } = await this.mutations.issueAccessCode(booking.id);

      if (data?.issueAccessCode?.ressourceId)
        pinCode = data?.issueAccessCode?.ressourceId;
    }

    if (!pinCode) throw new Error("NO_NUKI_ACCESS_CODE");

    return pinCode;
  }

  generateIntenaryToApt() {
    const booking = this.getBooking();

    const latitude = booking?.lat;// booking?.apartment?.location?.latitude;
    const longitude = booking?.lng // booking?.apartment?.location?.longitude;

    openMap({
      provider: Platform.OS === "ios" ? "apple" : "google",
      travelType: "drive",
      navigate_mode: "preview",
      end: `${latitude}, ${longitude}`,
    });
  }

  getOpenerOfApt() {
    const locks = this.getBooking()?.apartment?.locks;

    //TODO: Check for opener type in array instead of taking 2nd element
    const opener = Array.isArray(locks) && locks.length >= 1 ? locks[1] : null;

    if (!opener) throw new Error("NO_OPENER_FOR_APARTMENT");

    return opener;
  }

  getSmartLockOfApt() {
    const locks = this.getBooking()?.apartment?.locks;

    //TODO: Check for smartlock type in array instead of taking 1st element
    const smartLock = Array.isArray(locks) ? locks[0] : null;

    if (!smartLock) throw new Error("NO_SMART_LOCK_FOR_APARTMENT");

    return smartLock;
  }

  isSmartLockOpened() {
    try {
      const smartLock = this.getSmartLockOfApt();

      //  console.log("complete state", smartLock.state);

      const isDoorOpen = smartLock.state.trim().toLowerCase() === "unlocked";

      return isDoorOpen;
    } catch (err) {
      // NO smart lock found
      console.log("error", err);
      return false;
    }
  }

  async refreshBooking() { //obsolete function -> now use refreshBookingInfos
    try {
      const user = await this.getUserBooking();
      if (user) {
        const { bookingId, email } = user;

        const { data } = await this.quries.getBookingByIdAndEmail(email, bookingId);

        const booking = data.getBookingByIdAndEmail;

        // console.log("booking lock state", JSON.stringify(booking.apartment?.locks, null, 2));

        //   await this.rootStore.bookingStore.updateBooking(booking);
      }
    } catch (err) {
      console.log(JSON.stringify(err, null, 2));
    }
  }

  async refreshBookingInfos() {
    try {
      // bookingId is the smoobuBookingId not oiqia bookingId !!! (=> 8 digits)
      const { bookingId } = await this.getUserBooking();
      if (bookingId) {
        const { data } = await this.quries.getBookingByExtId(bookingId);

        this.rootStore.bookingStore.updateBooking(data?.getBookingByExtId);
      }
    } catch (err) {
      console.log(JSON.stringify(err, null, 2));
    }
  }

  async triggerDoorAction() {
    const smartLock = this.getSmartLockOfApt();

    let response: boolean | null | undefined = null;
    const isDoorOpen = this.isSmartLockOpened();

    //console.log("isDoorOpen", isDoorOpen);
    // console.log("info", smartLock);

    if (isDoorOpen && smartLock?.smartLockId) {
      const { data } = await this.mutations.lockDoor(smartLock?.smartLockId);
      response = data?.lockDoor?.success;
    }

    if (!isDoorOpen && smartLock?.smartLockId) {
      const { data } = await this.mutations.unlockDoor(smartLock?.smartLockId);
      response = data?.unlockDoor?.success;
    }

    //console.log("response", response);

    return response;
  }


}
