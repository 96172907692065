import { showMessage, MessageType } from "react-native-flash-message";
import AsyncStorage from "@react-native-async-storage/async-storage";


import Mutations from "../api/mutations";
import Queries from "../api/queries";
import RootStore from "../store";

const userIdentifier = "@oiqia_user_booking";

export default class App {

  protected rootStore = RootStore.getInstance();
  protected mutations = new Mutations();
  protected quries = new Queries();

  public getUserStore() {
    return this.rootStore.userStore;
  }

  public getBookingStore() {
    return this.rootStore.bookingStore;
  }

  public getNotificationStore() {
    return this.rootStore.notificationsStore;
  }


  public alertMessage = (message: string, description: string, type: MessageType = "info") => {
    showMessage({
      message,
      description,
      type,
      hideOnPress: false,
      duration:8000,
      textStyle: {
        fontSize: 17,
        
      },
      titleStyle: {
        fontSize: 20
      }

    });
  };


  setItemToLocalStorage = async (key: string, value: string) => {
    try {
      await AsyncStorage.setItem(key, value);
    } catch (e) {
      console.log(e);
    }
  };

  getItemFromLocalStorage = async (key: string) => {
    try {
      return AsyncStorage.getItem(key);
    } catch (e) {
      console.log(e);
    }
  };


  removeItemFromLocalStorage = async (key: string) => {
    try {
      await AsyncStorage.removeItem(key);
    } catch (e) {
      console.log(e);
    }
  }

  setUserBookingInfo(info: Record<string, unknown>) {
    const value = JSON.stringify(info);
    this.setItemToLocalStorage(userIdentifier, value);
  }

  async getUserBooking(): Promise<Record<string, string> | null> {
    const value = await this.getItemFromLocalStorage(userIdentifier);
    return value != null ? JSON.parse(value) : null;
  }

  async removeUserBooking() {
    await this.removeItemFromLocalStorage(userIdentifier);
  }

}
