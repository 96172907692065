import React, { useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import FlashMessage from "react-native-flash-message";
import StoreContext, { context } from "./src/store/Context";
import GQLClient from "./src/config/graphqlClient";
import Navigation from "./src/navigation/index";
import AsyncStorage from "@react-native-async-storage/async-storage";
const client = GQLClient.getInstance();
const apolloClient = client?.getApolloClientInstance();
import "./translator";
import { useTranslation } from "react-i18next";
const langs = ["en", "fr", "es", "ru", "pt", "it"];


export default function App() {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const setAppLang = async () => {
      try {
        const lang = await AsyncStorage.getItem("appLanguage");
        if (lang && langs.includes(lang)) {
          i18n.changeLanguage(lang);
        } else {
          i18n.changeLanguage("fr");
        }
        //   console.log(i18n.language);
      } catch (err) {
        console.log(err);
      }
    };

    setAppLang();
  }, []);

  return (
    <>
      {/* <CheckIn /> */}

      <StoreContext.Provider value={context}>
        <ApolloProvider client={apolloClient}>
          {/* <ThemeProvider theme={theme}> */}
          <Navigation />
          {/* <CheckIn /> */}
          {/* <ComplaintForm /> */}
          {/* </ThemeProvider> */}
        </ApolloProvider>
        <FlashMessage position="top" />
      </StoreContext.Provider>
    </>

    // <InitProviders>

    //   <Navigation />
    // </InitProviders>
  );
}
