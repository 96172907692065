import { ApolloClient, InMemoryCache, NormalizedCacheObject } from "@apollo/client";

import getEnvironment from "./environment";


export default class GQLClient {
  protected client: ApolloClient<NormalizedCacheObject>;

  private static instance = new GQLClient();

  private constructor() {
    /* const { serverUrl } = getEnvironment(); */

   //! WEB BUILD STAGING
// const serverUrl = "https://staging-admin-oiqia.geek.oiqia.net/graphql"

    //! WEB BUILD PRODUCTION
    const serverUrl = "https://admin-oiqia.production.oiqia.net/graphql"

    this.client = new ApolloClient({
      uri: serverUrl,
      cache: new InMemoryCache({
        /*resultCaching: false,
         addTypename: false */
      }),
      queryDeduplication: true
    });
  }

  static getInstance() {
    return this.instance;
  }

  public getApolloClientInstance() {
    return this.client;
  }
}
