const simpleDDP = require("simpleddp");
import ws from "isomorphic-ws";
import getEnvironment from "./environment";


/* 
let SERVER_URL = getEnvironment().serverUrl; */


//! WEB BUILD STAGING
// let SERVER_URL = "https://staging-admin-oiqia.geek.oiqia.net/graphql"

//! WEB BUILD PRODUCTION
let SERVER_URL = "https://admin-oiqia.production.oiqia.net/graphql"

SERVER_URL = SERVER_URL.replace("graphql","websocket");
SERVER_URL = SERVER_URL.replace("https","wss");
SERVER_URL = SERVER_URL.replace("http","ws"); // case of definition without secure

const opts = {
    endpoint: SERVER_URL,
    SocketConstructor: ws,
    reconnectInterval: 5000,
    maxTimeout:45000
  };


const DDP = new simpleDDP(opts);


//console.log("URL COONEC",SERVER_URL);
DDP.on("connected", () => {

    console.log("CONNECTED TO WEBSOCKET");
  });
  
  DDP.on("disconnected", () => {

    //Alert.alert("DISCONNECTED connexion");
    console.log("DISCONNECTED TO WEBSOCKET");
  });
  
  DDP.on("error", () => {
    console.log("TIME OUT - Connexion ERROR");
  });
  
  DDP.on("login", () => {});
  
  DDP.on("logout", () => {
    DDP.clearData();
  });
  
  DDP.on("loginSessionLost", () => {
    console.log("LOGOUT");
  });
  
  DDP.on("loginResume", () => {});
  
  DDP.on("loginResumeFailed", () => {
    console.log("LOGIN FAIL");
  });


export default DDP;