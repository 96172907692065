import I18n from "i18next";

import App from "./App";

export default class PopUp extends App {

    setCheckInStatePop(value: boolean) {
        this.rootStore.popUp.setPopUPCheckIn(value)
    }

    getCheckInStatePop() {
        return this.rootStore.popUp.getPopUpCheckIn()
    }
}
