import React, { useState } from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  Modal,
  Platform,
  Text,
  SafeAreaView,
} from "react-native";

import { TouchableOpacity } from "react-native-gesture-handler";

import { Card, Button, Title } from "react-native-paper";
import ImageViewer from "react-native-image-zoom-viewer";
import { Entypo } from "@expo/vector-icons";

import typo from "../../Theme/Typography";


export default function Gallery(props: any) {

  const [modalConfig, setModalConfig] = useState({
    isVisible: false,
    currentIndex: 0,
  });

  const openModal = (index: number) => {
    setModalConfig({
      isVisible: true,
      currentIndex: index,
    });
  };

  const closeModal = () => {
    setModalConfig({
      isVisible: false,
      currentIndex: 0,
    });
  };
  return (
    <SafeAreaView style={styles.galleryContainer}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        {props.pictures?.map((ele: any, i: number) => (
          <Card key={i} style={styles.imageContainer} onPress={() => openModal(i)}>
            <Image
              style={styles.image}
              source={{ uri: `${ele.container}/${ele.name}` }}
            />
            <Text style={[typo.body, styles.title, { maxWidth: styles.image.width }]}>{ele.title}</Text>
          </Card>
        ))}
      </ScrollView>

      {Platform.OS != "web" &&
        <SafeAreaView style={styles.modal}>
          <Modal visible={modalConfig.isVisible} transparent={true}>
            <ImageViewer
              enableSwipeDown={true}
              index={modalConfig.currentIndex}
              imageUrls={props.pictures?.map(ele => {
                return {
                  url: `${ele.container}/${ele.name}`,
                  props: { source: `${ele.container}/${ele.name}` },
                };
              })}
              renderHeader={() => (
                <SafeAreaView>
                  <TouchableOpacity onPress={closeModal}>
                    <Entypo
                      name="cross"
                      onPress={closeModal}
                      size={60}
                      color="white"
                    />
                  </TouchableOpacity>
                </SafeAreaView>
              )}
            ></ImageViewer>
          </Modal>
        </SafeAreaView>}
    </SafeAreaView>
  );
}

let styles = StyleSheet.create({
  galleryContainer: {
    flex: 1,
  },
  imageContainer: {
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 5,
    marginRight: 10,
    marginBottom: 15,
  },
  image: {
    //padding: 5,
    left: 5,
    top: 5,
    width: 200,
    height: 150,
    borderWidth: 1,
    borderRadius: 1,
    marginRight: 10,
    borderColor: "white",
    marginBottom: 5,
  },
  modal: {
    flex: 1,
  },
  title: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: 10,

  },
});
